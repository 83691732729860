import { animate, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';

/**
 * @title Basic snack-bar
 */
@Component({
  selector: 'app-snack-bar-component',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  animations: [
    trigger('state', [
      transition(':enter', [
        style({ bottom: '-100px', transform: 'translated(-50%, 0%) scale(0.3)' }),
        animate(
          '150ms cubic-bezier(0,0,0.2,1)',
          style({
            transform: 'translate(-50%, 0%) scale(1)',
            opacity: 1,
            bottom: '20px',
          }),
        ),
      ]),
      transition(':leave', [
        animate(
          '150ms cubic-bezier(0.4,0.0, 1, 1)',
          style({
            transform: 'translate(-50%, 0%) scale(0.3)',
            opacity: 0,
            bottom: '-100px',
          }),
        ),
      ]),
    ]),
  ],
})
export class SnackBarComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;

  public show: boolean = false;
  public message: string = '';
  public type: string = 'success';
  private snackbarSubscription: Subscription;

  constructor(private snackbarService: SnackBarService) {}
  ngOnInit() {
    this.snackbarSubscription = this.snackbarService.snackbarState.subscribe((state) => {
      if (state.type) {
        this.type = state.type;
      } else {
        this.type = 'success';
      }
      this.message = state.message;
      this.show = state.show;
      setTimeout(() => {
        this.show = false;
      }, 10000);
    });
  }

  ngOnDestroy() {
    this.snackbarSubscription.unsubscribe();
  }

  public onCloseSnackBar(): void {
    this.show = false;
  }
}
