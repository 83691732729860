import { Component, OnInit, ViewChild, ElementRef, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/modules/core/services/notification/notification.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import {
  PatientDetail,
  QuestionnaireControllerService,
  QuestionnaireDetailWithRelations,
  QuestionnaireReplySummary,
  QuestionnaireSummary,
} from '@abilycare/dal-client';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-fried-questionnary-course',
  templateUrl: './questionnary-course.component.html',
  styleUrls: ['./questionnary-course.component.scss'],
  providers: [],
})
export class QuestionnaryCourseComponent implements OnInit {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;

  public readonly boardReady: Observable<boolean>;

  public patient: PatientDetail;

  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public testName: string = i18nKey('navbar.questionnary');

  public newPage: string = '';
  public course: any;
  public canDisplayStart: boolean = true;
  public questionnaryDetails: QuestionnaireDetailWithRelations;
  public questionnaryDisplayed: QuestionnaireSummary;
  public startTest: boolean = false;
  public isAloneQuestionnary: boolean = true;
  private subscriptions: Subscription[] = [];
  public idQuestionnary: number;
  public currentPage: String;
  public showHtml: boolean;

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private questionnaryService: QuestionnaireControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private location: Location,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.course = this.location.getState();
    this.activatedRoute.paramMap.subscribe((params) => {
      this.idQuestionnary = parseInt(params.get('questionnaryId').toString(), 10);
      this.currentPage = 'questionnary' + this.idQuestionnary;
      this.showHtml = true;
      this.getQuestionnary(this.idQuestionnary);
    });
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
  }

  public onStartQuestionnary(idQuestionnary: number): void {
    this.isAloneQuestionnary = true;
    this.startTest = true;
  }

  public getQuestionnary(idQuestionnary: number): void {
    const lang = this.translateService.currentLang;
    this.startTest = false;
    this.questionnaryService.questionnaireControllerFindById(idQuestionnary, lang).subscribe(
      (questDetail) => {
        this.questionnaryDetails = questDetail;

        let questSummary: QuestionnaireSummary = {
          id: questDetail.id,
          name: questDetail.name,
          description: questDetail.description,
          family: questDetail.family,
          durationInMinutes: questDetail.durationInMinutes,
        };
        this.questionnaryDisplayed = questSummary;
      },
      (error) => {
        console.log('Error questionnaireControllerFindById', error);
      },
    );
  }

  public onDisplayQuestionnary(questionnary: QuestionnaireSummary): void {
    this.questionnaryDisplayed = questionnary;
  }

  public onValidateQuestionnary(idQuestionnary: number): void {
    this.onStopQuestionnary();
  }

  public onStopQuestionnary(): void {
    this.startTest = false;
    this.questionnaryDisplayed = null;
    this.questionnaryDetails = null;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }
}
