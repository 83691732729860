import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, Output, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { map } from 'rxjs/operators';
import {
  PatientDetail,
  QuestionnaireReplyControllerService,
  BilansControllerService,
  Bilans,
  WeighingControllerService,
  GripControllerService,
  RombergControllerService,
  WalkingControllerService,
  QuestionnaireControllerService,
  CourseControllerService,
  Course,
  DoubleTask,
  DoubleTaskControllerService,
} from '@abilycare/dal-client';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { EventEmitter } from '@angular/core';
import { RemoteInteraction as RemoteInteractionWalk } from 'src/app/modules/walk/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionRomberg } from 'src/app/modules/romberg/services/remote-interaction.service';

import { AnalysisResults as AnalysisResultsWalk } from 'src/app/modules/walk/models/analysis-results.model';
import { AnalysisResults as AnalysisResultsRomberg } from 'src/app/modules/romberg/models/analysis-results.model';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import { Assessment } from 'src/app/modules/walk/models/assessment.model';
import { takeUntil } from 'rxjs/operators';
import { AssessmentRomberg } from 'src/app/modules/romberg/models/assessment.model';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { ForcePlatformOrchestrator as rombergOrchestrator } from 'src/app/modules/romberg/services/orchestrator.service';
import { ForcePlatformOrchestrator as walkOrchestrator } from 'src/app/modules/walk/services/orchestrator.service';
import { FootWearDataService } from 'src/app/modules/abilycare-data-access';

@Component({
  selector: 'app-patient-history-courses',
  templateUrl: './patient-history-courses.component.html',
  styleUrls: ['./patient-history-courses.component.scss'],
  providers: [rombergOrchestrator, walkOrchestrator],
})
export class PatientHistoryCoursesComponent implements OnInit, OnDestroy {
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('masterContent', { static: true }) masterContent: ElementRef<HTMLElement>;
  @ViewChild('widgetsContent', { static: true }) widgetsContent: ElementRef<HTMLElement>;
  @ViewChild('btnCloseFilter', { static: true }) btnCloseFilter: ElementRef;
  @ViewChild('btnCloseDeleteCourse', { static: true }) btnCloseDeleteCourse: ElementRef;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() changePage: EventEmitter<string> = new EventEmitter<string>();

  @Input() currentPage: string;

  public itemsPerPageLabel: string;

  public listCourses: Array<Bilans> = new Array<Bilans>();
  public listCoursesInitial: Array<Bilans> = new Array<Bilans>();

  // Array<QuestionnaireReplySummary> = new Array<QuestionnaireReplySummary>();
  patientDetail: PatientDetail = null;

  public patientId: number;
  public patient: PatientDetail = null;

  public canEdit = false;
  public canDelete: boolean = false;
  public courseCanBeUpdated: boolean = false;
  private unsubscribe = new Subject<void>();
  public returnTitle: string = '';
  public isLoaded = false;
  private subscriptions: Subscription[] = [];

  public allCheckboxChecked: boolean = false;
  public hasFilterApplicated: boolean = false;
  public allCheckboxIndeterminate: boolean = false;
  public isModalOpened: boolean = false;
  public checkboxList = [];

  public walkAnalysisResult: AnalysisResultsWalk = null;
  public rombergAnalysisResult: AnalysisResultsRomberg = null;

  public courseToPrint: Bilans;
  public courseToDelete: Bilans = null;
  public TestParcoursToDelete: Course;
  public isRefrentPractitionner: boolean = false;

  public startDate: Date = null;
  public endDate: Date = null;

  public dataNotAvailable: boolean = false;

  public openedEyesLeftSupport: number = 0;
  public closedEyesLeftSupport: number = 0;

  private errorLogged = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private patientDataService: PatientDataService,
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private translateService: TranslateService,
    private questionnaryReplyService: QuestionnaireReplyControllerService,
    private questionnaryService: QuestionnaireControllerService,
    private remoteInteractionWalkService: RemoteInteractionWalk,
    private remoteInteractionRombergService: RemoteInteractionRomberg,
    private bilansService: BilansControllerService,
    private weightService: WeighingControllerService,
    private gripService: GripControllerService,
    private rombergService: RombergControllerService,
    private walkingService: WalkingControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private rombergOrchestrator: rombergOrchestrator,
    private walkOrchestrator: walkOrchestrator,
    private courseService: CourseControllerService,
    private doubleTaskService: DoubleTaskControllerService,
  ) {
    this.returnTitle = this.translateService.instant('Patient.Profil.back_patient');
    this.itemsPerPageLabel = this.translateService.instant('Patient.Profil.back_patient');
    this.checkboxList = [
      {
        id: 1,
        name: _('Course.filter.questionnary.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 2,
        name: _('Course.filter.romberg.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 3,
        name: _('Course.filter.walking.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 4,
        name: _('Course.filter.grip.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 5,
        name: _('Course.filter.weight.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 5,
        name: _('Course.filter.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
    ];
  }

  ngOnInit() {
    this.eventTrackingService.init();
    this.dataService.storeHeaderShowed(false);
    this.activatedRoute.paramMap.subscribe((params) => {
      this.patientId = parseInt(params.get('patientId').toString(), 10);
    });
    this.getPatient();
    if (!this.allCheckboxIndeterminate)
      this.getAllCoursesList().then(() => (this.listCoursesInitial = this.listCourses));
    else this.getCoursesWithFilter(this.checkboxList);
    /*
    this.getAllCoursesList().then(() =>
      this.listCoursesInitial = this.listCourses
    );
    */
    this.rolesCheck.hasDeleteData.pipe(takeUntil(this.unsubscribe)).subscribe((canDelete) => {
      this.canDelete = canDelete;
    });

    this.rolesCheck.hasDelegationResult.pipe;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public async getAllCoursesList() {
    this.listCourses = [];
    const that = this;
    this.subscriptions.push(
      this.bilansService.bilansControllerFindAllTestByPatient(this.patientId).subscribe((allBilans) => {
        var resultArray = Object.keys(allBilans).map(function(bilanIndex) {
          let bilanObj = allBilans[bilanIndex];
          if (bilanObj.type === 1) that.getQuestionnaryScore(bilanObj.id, bilanObj);
          else if (bilanObj.type === 2) that.getRomberg(bilanObj.id, bilanObj);
          else if (bilanObj.type === 3) {
            that.getWalking(bilanObj.id, bilanObj);
          } else if (bilanObj.type === 4) that.getGrip(bilanObj.id, bilanObj);
          else if (bilanObj.type === 5) that.getWeight(bilanObj.id, bilanObj);
          that.listCourses.push(bilanObj);
        });
      }),
    );
  }

  public getQuestionnaryScore(replyId: number, courseObj: Bilans) {
    this.questionnaryReplyService.questionnaireReplyControllerFindById(replyId).subscribe((reply) => {
      if (reply && reply.scores.length > 0) {
        courseObj.score = reply.scores[0];
        courseObj.scores = reply.scores;
      }
      this.questionnaryService
        .questionnaireControllerFindById(reply.questionnaireId, this.translateService.currentLang)
        .subscribe((quest) => {
          courseObj.name = quest.name;
        });
    });
  }

  public getWeight(objId: number, courseObj: Bilans) {
    courseObj.name = this.translateService.instant('navbar.massBalance');
    this.weightService.weighingControllerFindById(objId).subscribe((obj) => {
      if (obj && obj.value > 0) {
        courseObj.weight = obj.value;
      }
    });
  }

  public getWalking(objId: number, courseObj: Bilans) {
    courseObj.name = this.translateService.instant('navbar.walkTest');
    this.walkingService.walkingControllerFindById(objId).subscribe((obj) => {
      courseObj.shoesType = obj.shoesType;
      courseObj.walkingAid = obj.stabilityAid;
      courseObj.hasVideo = obj.hasVideo;
    });
  }

  public getGrip(objId: number, courseObj: Bilans) {
    courseObj.name = this.translateService.instant('navbar.strengthTest');
    this.gripService.gripControllerFindById(objId).subscribe((obj) => {
      courseObj.leftHandV1 = obj.leftHandV1;
      courseObj.leftHandV2 = obj.leftHandV1;
      courseObj.rightHandV1 = obj.rightHandV1;
      courseObj.rightHandV2 = obj.rightHandV2;
    });
  }

  public getRomberg(objId: number, courseObj: Bilans) {
    courseObj.name = this.translateService.instant('navbar.rombergTest');
    this.rombergService.rombergControllerFindById(objId).subscribe((obj) => {
      courseObj.shoesType = obj.shoesType;
      courseObj.helpStability = obj.stabilityAid;
    });
  }

  public onSubmit() {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) {
        value.isValidate = true;
      } else {
        value.isValidate = false;
      }
    }
    if (!this.allCheckboxIndeterminate) {
      this.getAllCoursesList();
      //this.listCourses = this.listCoursesInitial;
      this.hasFilterApplicated = false;
    } else {
      this.getCoursesWithFilter(this.checkboxList);
      this.hasFilterApplicated = true;
    }
    this.btnCloseFilter.nativeElement.click();
    this.isModalOpened = false;
  }

  public async getCoursesWithFilter(tab: any) {
    const arrayListType = [];
    let courseChecked: boolean = false;
    if (tab[0].checked) arrayListType.push(1);
    if (tab[1].checked) arrayListType.push(2);
    if (tab[2].checked) arrayListType.push(3);
    if (tab[3].checked) arrayListType.push(4);
    if (tab[4].checked) arrayListType.push(5);
    courseChecked = await tab[5].checked;

    if (courseChecked) {
      this.listCourses = await this.listCoursesInitial.filter(function(e) {
        return e.courseId && e.courseId > -1;
      });

      if (arrayListType.length > 0) {
        this.listCourses = await this.listCourses.filter((obj) => {
          return arrayListType.indexOf(obj.type) > -1;
        });
      }
    } else if (arrayListType.length > 0) {
      this.listCourses = await this.listCoursesInitial.filter((obj) => {
        return arrayListType.indexOf(obj.type) > -1;
      });
    } else {
      this.getAllCoursesList();
    }
    this.paginator.pageIndex = await 0;
  }

  public closeModalFilter(): void {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked && !value.isValidate) {
        value.checked = false;
        this.allCheckboxChecked = false;
      } else if (!value.checked && value.isValidate) {
        value.checked = true;
      }
    }
  }
  public listChange(): void {
    let checkedCount = 0;
    // Get total checked items
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) checkedCount++;
    }

    if (checkedCount > 0 && checkedCount < this.checkboxList.length) {
      this.allCheckboxIndeterminate = true;
    } else if (checkedCount === this.checkboxList.length) {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = true;
    } else {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = false;
    }
  }

  public getPatient(): void {
    this.subscriptions.push(
      this.patientDataService.getPatientById(this.patientId).subscribe((patient: PatientDetail) => {
        this.patient = patient;
        this.isLoaded = true;
        if (patient.currentPractionerRoles && patient.currentPractionerRoles.length > 0) {
          patient.currentPractionerRoles.forEach((role) => {
            if (role === 'Owner' || role === 'TestDelegation') {
              this.canEdit = true;
            }
          });
        }
      }),
    );
  }

  public onStartDateChanged(value: any) {
    this.startDate = value;
    this.listCourses = this.listCourses.filter(function(e) {
      return new Date(e.date) >= new Date(value);
    });
    this.listCoursesInitial = this.listCourses;
  }

  public onEndDateChanged(value: any) {
    this.endDate = value;
    this.listCourses = this.listCourses.filter(function(e) {
      return new Date(e.date) <= new Date(value);
    });
    this.listCoursesInitial = this.listCourses;
  }

  public allCheckboxChange(): void {
    for (const value of Object.values(this.checkboxList)) {
      value.checked = this.allCheckboxChecked;
    }
  }

  public viewCourseObject(courseObject: Bilans): void {
    /*  if (courseObject.courseId > 0) {
      this.courseService.courseControllerFindById(courseObject.courseId).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried?fromHistory=1', { state: res });
      });
    } else */
    if (courseObject.type == 3) {
      this.walkingService.walkingControllerFindById(courseObject.id).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/walkView', { state: res });
      });
    } else if (courseObject.type == 2) {
      this.rombergService.rombergControllerFindById(courseObject.id).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/rombergView', { state: res });
      });
    } else if (courseObject.type == 5) {
      this.weightService.weighingControllerFindById(courseObject.id).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/weightView', { state: res });
      });
    } else if (courseObject.type == 4) {
      this.gripService.gripControllerFindById(courseObject.id).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/gripView', { state: res });
      });
    } else if (courseObject.type == 1) {
      this.questionnaryReplyService.questionnaireReplyControllerFindById(courseObject.id).subscribe((res) => {
        this.router.navigateByUrl('/courses/fried/questionnaryView', { state: res });
      });
    }
  }

  public closeModalDeleteCourse(): void {
    this.btnCloseDeleteCourse.nativeElement.click();
  }

  public onClickDeleteCourse(courseObject) {
    this.courseToDelete = courseObject;
    if (this.courseToDelete.courseId) {
      this.getCourseById(this.courseToDelete.courseId).subscribe((course: Course) => {
        this.TestParcoursToDelete = course;
        //console.log("this.parcoursInProgress is ", this.TestParcoursToDelete.status == 1 , " date ", this.TestParcoursToDelete.enddate >= new Date());
        //console.log("this.TestParcoursToDelete.enddate ", this.TestParcoursToDelete.enddate);
        this.courseCanBeUpdated =
          //this.TestParcoursToDelete.status == 1 &&
          new Date(this.TestParcoursToDelete.enddate).getTime() >= new Date().getTime();
        //console.log("this.courseCanBeUpdated is ", this.courseCanBeUpdated);
      });
    } else {
      this.courseCanBeUpdated = false;
    }
  }

  public getCourseById(idCourse: number): Observable<Course> {
    return this.courseService.courseControllerFindById(idCourse).pipe(map((res) => res as Course));
  }

  public deleteCourse(): void {
    const index: number = this.listCoursesInitial.indexOf(this.courseToDelete);
    if (index !== -1) {
      this.listCoursesInitial.splice(index, 1);
      this.listCourses = this.listCoursesInitial;
    }
    if (this.courseToDelete.type == 3) {
      this.subscriptions.push(
        this.walkingService.walkingControllerDelete(this.courseToDelete.id).subscribe(
          (res) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patient.id,
              this.courseToDelete.id,
              'Walking',
              this.courseToDelete,
              {},
            );
            this.walkOrchestrator.discardSessionData(this.courseToDelete.id.toString());
            //if test is related to freagility update status and score.
            if (this.courseToDelete.courseId && this.courseToDelete.courseId != -1) {
              this.courseService
                .courseControllerUpdateScoreAndStatus(this.courseToDelete.patientId, this.courseToDelete.courseId)
                .subscribe((res) => {
                  console.log('course status and score updated');
                });
            }
            this.onSubmit();
            //this.goTo('/PatientHistoryCourses/'+this.patientId);
            this.btnCloseDeleteCourse.nativeElement.click();
            this.snackBarService.show(this.translateService.instant('app.snack.delete.test.success'), 'success');
          },
          (error) => {
            console.error('PatientHistoryCoursesComponent : Err-Course-01 ', error);
            const errMsg = this.translateService.instant('Err-Course-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Course-01', error);
              this.errorLogged = true;
            }
            this.btnCloseDeleteCourse.nativeElement.click();
          },
        ),
      );
    } else if (this.courseToDelete.type == 2) {
      this.subscriptions.push(
        this.rombergService.rombergControllerDelete(this.courseToDelete.id).subscribe(
          (res) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patient.id,
              this.courseToDelete.id,
              'Romberg',
              this.courseToDelete,
              {},
            );
            this.rombergOrchestrator.discardSessionData(this.courseToDelete.id.toString());
            this.onSubmit();
            //this.goTo('/Patient');
            this.btnCloseDeleteCourse.nativeElement.click();
            this.snackBarService.show(this.translateService.instant('app.snack.delete.test.success'), 'success');
          },
          (error) => {
            console.error('PatientHistoryCoursesComponent : Err-Course-01 ', error);
            const errMsg = this.translateService.instant('Err-Course-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Course-01', error);
              this.errorLogged = true;
            }
            this.btnCloseDeleteCourse.nativeElement.click();
          },
        ),
      );
    } else if (this.courseToDelete.type == 5) {
      this.subscriptions.push(
        this.weightService.weighingControllerDelete(this.courseToDelete.id).subscribe(
          (res) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patient.id,
              this.courseToDelete.id,
              'Weight',
              this.courseToDelete,
              {},
            );
            this.onSubmit();
            //this.goTo('/Patient');
            this.btnCloseDeleteCourse.nativeElement.click();
            this.snackBarService.show(this.translateService.instant('app.snack.delete.test.success'), 'success');
          },
          (error) => {
            console.error('PatientHistoryCoursesComponent : Err-Course-01 ', error);
            const errMsg = this.translateService.instant('Err-Course-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Course-01', error);
              this.errorLogged = true;
            }
            this.btnCloseDeleteCourse.nativeElement.click();
          },
        ),
      );
    } else if (this.courseToDelete.type == 4) {
      this.subscriptions.push(
        this.gripService.gripControllerDelete(this.courseToDelete.id).subscribe(
          (res) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patient.id,
              this.courseToDelete.id,
              'Grip',
              this.courseToDelete,
              {},
            );
            this.onSubmit();
            //this.goTo('/PatientHistoryCourses/' + this.patientId);
            this.btnCloseDeleteCourse.nativeElement.click();
            this.snackBarService.show(this.translateService.instant('app.snack.delete.test.success'), 'success');
          },
          (error) => {
            console.error('PatientHistoryCoursesComponent : Err-Course-01 ', error);
            const errMsg = this.translateService.instant('Err-Course-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Course-01', error);
              this.errorLogged = true;
            }
            this.btnCloseDeleteCourse.nativeElement.click();
          },
        ),
      );
    } else if (this.courseToDelete.type == 1) {
      this.subscriptions.push(
        this.questionnaryReplyService.questionnaireReplyControllerDelete(this.courseToDelete.id).subscribe(
          (res) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patient.id,
              this.courseToDelete.id,
              'QuestionnaireReply',
              this.courseToDelete,
              {},
            );
            this.onSubmit();
            //this.goTo('/Patient');
            this.btnCloseDeleteCourse.nativeElement.click();
            this.snackBarService.show(this.translateService.instant('app.snack.delete.test.success'), 'success');
          },
          (error) => {
            console.error('PatientHistoryCoursesComponent : Err-Course-01 ', error);
            const errMsg = this.translateService.instant('Err-Course-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Course-01', error);
              this.errorLogged = true;
            }
            this.btnCloseDeleteCourse.nativeElement.click();
          },
        ),
      );
    }
    //update status and score parcours after delete
    if (this.courseToDelete.courseId)
      this.courseService
        .courseControllerUpdateScoreAndStatus(this.courseToDelete.patientId, this.courseToDelete.courseId)
        .subscribe();
  }

  private errorWithData(): void {
    console.log('ERRROR getting data from remote analysis');
    this.dataNotAvailable = true;
  }

  public async printCourse(courseObject: Bilans) {
    console.log('courseObject in print course is ', courseObject);
    this.dataNotAvailable = false;
    this.courseToPrint = courseObject;
    let visualAppreciationsRombergs: Array<AssessmentRomberg>;
    let typePlate: string;
    let doubleTask: string;
    let visualAppreciationWalk: Array<Assessment>;
    let shoesSelectVal: ShoesSelectorValues = {
      shoesType: this.courseToPrint.shoesType,
      helpStability: this.courseToPrint.helpStability,
      walkingAid: this.courseToPrint.walkingAid,
    };
    let closedEyesLeftSupport: number;
    let openedEyesLeftSupport: number;
    if (this.courseToPrint.type == 3) {
      //get visual appreciation from object
      await this.walkingService.walkingControllerFindById(courseObject.id).subscribe((walkObje) => {
        console.log('walkobj in history ', walkObje);
        if (walkObje.doubleTask) {
          this.doubleTaskService
            .doubleTaskControllerFindById(walkObje.doubleTask, this.translateService.currentLang)
            .subscribe((res) => {
              doubleTask = res.label;
              visualAppreciationWalk = [
                {
                  type: 0,
                  title: '',
                  value: walkObje.hesitantWalk,
                  nonEvaluated: !walkObje.hesitantWalk,
                },
                {
                  type: 1,
                  title: '',
                  value: walkObje.smallSteps,
                  nonEvaluated: !walkObje.smallSteps,
                },
                {
                  type: 2,
                  title: '',
                  value: walkObje.irregularWalk,
                  nonEvaluated: !walkObje.irregularWalk,
                },
                {
                  type: 3,
                  title: '',
                  value: walkObje.asymmetricWalk,
                  nonEvaluated: !walkObje.asymmetricWalk,
                },
                {
                  type: 4,
                  title: '',
                  value: walkObje.lossArmSwing,
                  nonEvaluated: !walkObje.lossArmSwing,
                },
                {
                  type: 5,
                  title: '',
                  value: walkObje.unbalancedWalk,
                  nonEvaluated: !walkObje.unbalancedWalk,
                },
              ];
              this.remoteInteractionWalkService
                .getAnalysisResultsForBilan(this.courseToPrint.id)
                .then(
                  (value) => {
                    this.walkAnalysisResult = value;
                    const obj = {
                      rombergAnalysisResult: this.rombergAnalysisResult,
                      walkAnalysisResult: this.walkAnalysisResult,
                      shoesSelectorValues: shoesSelectVal,
                      typeRapport: this.courseToPrint.type == 3 ? 'Walk' : 'Romberg',
                      dateConsultation: this.courseToPrint.date,
                      fromHistory: true,
                      walkAssessments: visualAppreciationWalk,
                      rombergAssessments: visualAppreciationsRombergs,
                      doubleTask: doubleTask,
                      patientId: this.patient.id,
                    };

                    this.router.navigateByUrl('/rapport', { state: obj });
                  },
                  (error) => {
                    this.errorWithData();
                    console.log('error getting analysis data ', this.dataNotAvailable);
                  },
                )
                .catch((error) => console.log('error getting analysis data ', this.dataNotAvailable));
            });
        } else {
          visualAppreciationWalk = [
            {
              type: 0,
              title: '',
              value: walkObje.hesitantWalk,
              nonEvaluated: !walkObje.hesitantWalk,
            },
            {
              type: 1,
              title: '',
              value: walkObje.smallSteps,
              nonEvaluated: !walkObje.smallSteps,
            },
            {
              type: 2,
              title: '',
              value: walkObje.irregularWalk,
              nonEvaluated: !walkObje.irregularWalk,
            },
            {
              type: 3,
              title: '',
              value: walkObje.asymmetricWalk,
              nonEvaluated: !walkObje.asymmetricWalk,
            },
            {
              type: 4,
              title: '',
              value: walkObje.lossArmSwing,
              nonEvaluated: !walkObje.lossArmSwing,
            },
            {
              type: 5,
              title: '',
              value: walkObje.unbalancedWalk,
              nonEvaluated: !walkObje.unbalancedWalk,
            },
          ];
        }
        this.remoteInteractionWalkService
          .getAnalysisResultsForBilan(this.courseToPrint.id)
          .then(
            (value) => {
              this.walkAnalysisResult = value;
              const obj = {
                rombergAnalysisResult: this.rombergAnalysisResult,
                walkAnalysisResult: this.walkAnalysisResult,
                shoesSelectorValues: shoesSelectVal,
                typeRapport: this.courseToPrint.type == 3 ? 'Walk' : 'Romberg',
                dateConsultation: this.courseToPrint.date,
                fromHistory: true,
                walkAssessments: visualAppreciationWalk,
                rombergAssessments: visualAppreciationsRombergs,
                doubleTask: doubleTask,
                patientId: this.patient.id,
              };
              this.router.navigateByUrl('/rapport', { state: obj });
            },
            (error) => {
              this.errorWithData();
              console.log('error getting analysis data ', this.dataNotAvailable);
            },
          )
          .catch((error) => console.log('error getting analysis data ', this.dataNotAvailable));
      });
    } else if (this.courseToPrint.type == 2) {
      this.rombergService.rombergControllerFindById(courseObject.id).subscribe((walkObje) => {
        typePlate = walkObje.typePlate;
        openedEyesLeftSupport = walkObje.openedEyesLeftSupport;
        closedEyesLeftSupport = walkObje.closedEyesLeftSupport;
        visualAppreciationsRombergs = [
          {
            type: 0,
            title: '',
            value: walkObje.openedEyesBalanceQuality,
            nonEvaluated: !walkObje.openedEyesBalanceQuality,
          },
          {
            type: 1,
            title: '',
            value: walkObje.closedEyesBalanceQuality,
            nonEvaluated: !walkObje.closedEyesBalanceQuality,
          },
          {
            type: 2,
            title: '',
            value: walkObje.walkingQuality,
            nonEvaluated: !walkObje.walkingQuality,
          },
          {
            type: 3,
            title: '',
            value: walkObje.fall ? 1 : 0,
            nonEvaluated: !walkObje.fall,
          },
        ];
      });
      this.remoteInteractionRombergService.getAnalysisResultsForBilan(this.courseToPrint.id);
      this.remoteInteractionRombergService
        .getAnalysisResultsForBilan(this.courseToPrint.id)
        .then(
          (value) => {
            this.rombergAnalysisResult = value;
            const obj = {
              rombergAnalysisResult: this.rombergAnalysisResult,
              walkAnalysisResult: this.walkAnalysisResult,
              shoesSelectorValues: shoesSelectVal,
              //walkAssessments: data.walkingSymmetry,
              rombergAssessments: visualAppreciationsRombergs,
              typeRapport: this.courseToPrint.type == 3 ? 'Walk' : 'Romberg',
              dateConsultation: this.courseToPrint.date,
              fromHistory: true,
              typePlate: typePlate,
              patientId: this.patient.id,
              openedEyesLeftSupport: openedEyesLeftSupport,
              closedEyesLeftSupport: closedEyesLeftSupport,
            };
            this.router.navigateByUrl('/rapport', { state: obj });
          },
          (error) => {
            this.errorWithData();
            console.log('error getting analysis data ', this.dataNotAvailable);
          },
        )
        .catch((error) => console.log('error getting analysis data ', this.dataNotAvailable));
    } else if (this.courseToPrint.type == 1) {
      this.questionnaryReplyService.questionnaireReplyControllerFindById(courseObject.id).subscribe((obj) => {
        this.router.navigateByUrl('/rapport/questionnary', { state: obj });
      });
    }
  }

  public openModal(): void {
    this.dataNotAvailable = true;
  }

  public closeModal() {
    this.dataNotAvailable = false;
  }

  @HostListener('window:load', ['$event'])
  public onChangePage($event) {
    this.changePage.emit();
  }

  goTo(page) {
    this.selectPage.emit(page);
    this.router.navigate([page]);
  }
}
