<div class="shoes_selector">
  <div class="row">
    <div class="col-lg-12">
      <span class="page-sub-title">{{ 'chaussure' | translate }}</span>
      <select [ngModel]="value.shoesType" (ngModelChange)="setShoes($event)" [disabled]="disabled">
        <option *ngFor="let shoes of footWears" [ngValue]="shoes.id">{{ shoes.translate }}</option>
      </select>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <span class="page-sub-title">{{ 'aideMarche' | translate }}</span>
      <div class="row">
        <div class="col-lg-6">
          <div *ngFor="let help of walkingAid; let i = index" [hidden]="i % 2 !== 0" class="checkbox-item">
            <input
              type="checkbox"
              [value]="help.id"
              [checked]="value.walkingAid && value.walkingAid.includes(help.id)"
              (change)="onCheckboxChange($event, help.id)"
              [disabled]="disabled"
            />
            &nbsp;
            <label>{{ help.translate }}</label>
          </div>
        </div>
        <div class="col-lg-6">
          <div *ngFor="let help of walkingAid; let i = index" [hidden]="i % 2 === 0" class="checkbox-item">
            <input
              type="checkbox"
              [value]="help.id"
              [checked]="value.walkingAid && value.walkingAid.includes(help.id)"
              (change)="onCheckboxChange($event, help.id)"
              [disabled]="disabled"
            />
            &nbsp;
            <label>{{ help.translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
