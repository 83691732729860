<div class="main-container container">
  <div class="container-health container flex-column">
    <div class="rapportPage container-fluid">
      <!-- WALKING PATIENT REPORT -->
      <div *ngIf="walkAnalysisResult">
        <div class="rapportPage-ct" id="print-section1">
          <div class="row infoPatient">
            <div class="col-md-3 col-xs-12">
              <div class="" id="imgWeight_protocole">
                <img src="{{ this.currentEstablishmentLogo }}" alt="" style="width: 10rem" />
              </div>
            </div>
            <div class="col-md-9 col-xs-12 infos-titre">
              <h1>{{ 'Rapport.titre.Marche' | translate }}</h1>
            </div>
          </div>
          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Patient.Lastname' | translate }}:</label>
                  {{ patient.lastName }}
                </div>
                <div>
                  <label> {{ 'Patient.Firstname' | translate }} : </label>
                  {{ patient.firstName }}
                </div>
                <div>
                  <label>{{ 'Patient.FolderNumber' | translate }}:</label>
                  {{ patient.folderNumber }}
                </div>
                <div>
                  <label>{{ 'Date' | translate }}: </label>
                  {{ dateConsultation | localdate: 'datetime' }}
                </div>
                <div *ngIf="shoesSelectorValues">
                  <label>{{ 'Rapport.TypeDeChaussure' | translate }}:</label>
                  {{ shoesType }}
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.Walk.Summary.distance' | translate }} : </label>
                  {{ walkAnalysisResult.distance }}&nbsp;m
                </div>
                <div *ngIf="shoesSelectorValues">
                  <label>{{ 'Checkup.Walk.Summary.WalkingHelpNeeded' | translate }}: </label>
                  {{ walkingAid }}
                </div>
                <div>
                  <label>{{ 'doubleTask' | translate }}: </label>
                  {{ doubleTask ? doubleTask : 'N/A' }}
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Assessment' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <ng-container *ngFor="let assessment of assessments; let i = index">
                  <div *ngIf="assessment && assessment.type == 0">
                    <label>{{ 'Checkup.Walk.Assessment.Type.Hesitant' | translate }}:</label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 2">
                    <label> {{ 'Checkup.Walk.Assessment.Type.Irregular' | translate }}: </label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 3">
                    <label>{{ 'Checkup.Walk.Assessment.Type.Asymetric' | translate }}:</label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                </ng-container>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <ng-container *ngFor="let assessment of assessments; let i = index">
                  <div *ngIf="assessment && assessment.type == 1">
                    <label>{{ 'Checkup.Walk.Assessment.Type.SmallSteps' | translate }}:</label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 5">
                    <label> {{ 'Checkup.Walk.Assessment.Type.Balance' | translate }}: </label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 4">
                    <label> {{ 'Checkup.Walk.Assessment.Type.SwingingArms' | translate }}: </label>
                    {{ !assessment.nonEvaluated ? assessment.value : ('NonEvalue' | translate) }}
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <h2>{{ 'RaportPraticien.mesures' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.Romberg.Graph.Longitu.SpeedMoy' | translate }}: </label>
                  {{ walkAnalysisResult.AvgSpeed | float: 3 }}&nbsp;m.s<sup>-1</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.walk.cycleVariability' | translate }}:</label>
                  {{ walkAnalysisResult.CycleVariability | float: 3 }}&nbsp;s
                </div>
                <div>
                  <label>{{ 'Checkup.Walk.Summary.WalkSymmetry' | translate }}:</label>
                  {{ walkAnalysisResult.SwingMeanStrideDuration | float: 3 }}
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.walk.meanStepDuration' | translate }}:</label>
                  {{ walkAnalysisResult.MeanStepDuration | float: 3 }}&nbsp;s
                </div>
                <div>
                  <label>{{ 'Checkup.walk.roliTronc' | translate }}:</label>
                  {{ walkAnalysisResult.RoliTronc | float: 3 }}&nbsp;rad.s<sup>-1</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.Walk.Summary.DoubleStandingDurationRatio' | translate }}:</label>
                  {{ walkAnalysisResult.DoubleStance * 100 | float: 3 }}&nbsp;%
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Locogram' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div id="graphesLoco" class="col-lg-12 col-xs-12">
                <img [attr.src]="walkAnalysisResult.graphes.loco | secure | async" />
              </div>
            </div>
          </div>
          <div class="force-page-break"></div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Rotagram' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div id="graphesRota" class="col-lg-12 col-xs-12">
                <img [attr.src]="walkAnalysisResult.graphes.rota | secure | async" />
              </div>
            </div>
          </div>
          <div class="force-page-break"></div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Semiogram' | translate }} :</h2>
          <div class="border m-0" style="overflow: visible;">
            <div>
              <!-- <div id="graphesSemio" class="col-md-12 radarChart" *ngIf="radarChartData != null" style="width: 800px; overflow: visible;"> -->
              <!-- <div id="graphesSemio" class="col-md-12 radarChart" *ngIf="radarChartData != null" > -->
              <div id="graphesSemio" class="col-md-12" *ngIf="radarChartData != null">
                <!-- <div style="overflow: visible;"> -->
                <img [attr.src]="imageSemio | secure | async" />
                <!--
                <canvas
                  baseChart
                  [datasets]="radarChartData"
                  [options]="optionsRadarChart"
                  [labels]="radarChartLabels"
                  legend="true"
                  chartType="radar"
                  [colors]="colorsOverride"
                >
                </canvas>-->
                <!-- </div> -->
              </div>
            </div>
          </div>
          <div class="force-page-break"></div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Longitudinal' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div class="setOfGraph graphic span">
                <div *ngFor="let graph of graphs; let i = index" class="graphic2">
                  <app-graph-timeline
                    [title]="graph.title"
                    [data]="graph"
                    idTitre2="myTitreWalk{{ i }}"
                    idLongitudinal2="myLongitudinalWalk{{ i }}"
                    width="350"
                    height="280"
                  ></app-graph-timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ROMBERG PATIENT REPORT -->
      <div *ngIf="rombergAnalysisResult" class="rapportPage-ct">
        <div id="print-section3">
          <div class="row infoPatient">
            <div class="col-md-3 col-xs-12">
              <div>
                <img src="{{ this.currentEstablishmentLogo }}" style="width: 10rem;" alt="" />
              </div>
            </div>
            <div class="col-md-9 col-xs-12 infos-titre">
              <h1>{{ 'Rapport.titre.Equilibre' | translate }}</h1>
            </div>
          </div>

          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label> {{ 'Patient.Lastname' | translate }} : </label>
                  {{ patient.lastName }}
                </div>
                <div>
                  <label>{{ 'Patient.Firstname' | translate }} :</label>
                  {{ patient.firstName }}
                </div>
                <div>
                  <label>{{ 'Patient.FolderNumber' | translate }}:</label>
                  {{ patient.folderNumber }}
                </div>
                <div>
                  <label>{{ 'Date' | translate }} : </label>
                  {{ dateConsultation | localdate: 'datetime' }}
                </div>
                <div *ngIf="shoesSelectorValues">
                  <label>{{ 'Rapport.TypeDeChaussure' | translate }} :</label>
                  {{ shoesType }}
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'RaportPraticien.dureeTest' | translate }} : </label>
                  {{ rombergAnalysisResult.duration }}&nbsp;s
                </div>
                <div *ngIf="shoesSelectorValues">
                  <label>{{ 'stabilityhelp' | translate }}:</label>
                  {{ helpStability }}
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div *ngIf="typePlate">
                  <label>{{ 'Rapport.TypePlate' | translate }}: </label>
                  {{ typePlate }}
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Assessment' | translate | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div *ngFor="let assessment of assessmentsRomberg; let i = index">
                <div class="col-md-6 col-xs-12 lablist">
                  <div *ngIf="assessment && assessment.type == 0 && assessment.nonEvaluated == false">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYO' | translate }}:</label>
                    {{ assessment.value }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 2 && assessment.nonEvaluated == false">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityWalk' | translate }}:</label>
                    {{ assessment.value }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 1 && assessment.nonEvaluated == false">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYF' | translate }}:</label>
                    {{ assessment.value }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 3 && assessment.nonEvaluated == false">
                    <label>Le sujet a-t-il chuté durant la mesure ? :</label>
                    {{ (assessment.value ? 'oui' : 'non') | translate }}
                  </div>

                  <div *ngIf="assessment && assessment.type == 0 && assessment.nonEvaluated == true">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYO' | translate }}:</label>
                    {{ 'NonEvalue' | translate }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 2 && assessment.nonEvaluated == true">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityWalk' | translate }}:</label>
                    {{ 'NonEvalue' | translate }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 1 && assessment.nonEvaluated == true">
                    <label>{{ 'Checkup.Romberg.Assessment.Type.QualityEquiYF' | translate }}:</label>
                    {{ 'NonEvalue' | translate }}
                  </div>
                  <div *ngIf="assessment && assessment.type == 3 && assessment.nonEvaluated == true">
                    <label>{{ 'Checkup.Romberg.Assessment.appreciation' | translate }}:</label>
                    {{ (assessment.value ? 'oui' : 'non') | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'RaportPraticien.mesures' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.ScoreYO' | translate }}:</label>
                  {{ this.rombergAnalysisResult.ScoreYO | float: 1 }}
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SurfaceYO' | translate }}:</label>
                  {{ rombergAnalysisResult.SurfaceYO | float: 1 }}&nbsp;cm<sup>2</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SwayDensityYO' | translate }}:</label>
                  {{ rombergAnalysisResult.SwayDensityYO | float: 1 }}&nbsp;s
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.VarianceLateraleYO' | translate }}: </label>
                  {{ rombergAnalysisResult.VarianceLateraleYO | float: 3 }}&nbsp;cm<sup>2</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SpeedMoyYO' | translate }}:</label>
                  {{ rombergAnalysisResult.SpeedMoyYO | float: 1 }}&nbsp;cm.s<sup>-1</sup>
                </div>
                <div *ngIf="typePlate == 'Kinvent' && openedEyesLeftSupport">
                  <label>{{ 'Checkup.Romberg.Summary.OpenedprcLeftSupport' | translate }}:</label>
                  {{ openedEyesLeftSupport | number: '1.0-0' }}%
                </div>
                <div *ngIf="typePlate == 'Kinvent' && openedEyesLeftSupport">
                  <label>{{ 'Checkup.Romberg.Summary.OpenedprcRightSupport' | translate }}:</label>
                  {{ 100 - openedEyesLeftSupport | number: '1.0-0' }}%
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.ScoreYF' | translate }}:</label>
                  {{ rombergAnalysisResult.ScoreYF | float: 1 }}
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SurfaceYF' | translate }}:</label>
                  {{ rombergAnalysisResult.SurfaceYF | float: 1 }}&nbsp;cm<sup>2</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SwayDensityYF' | translate }}:</label>
                  {{ rombergAnalysisResult.SwayDensityYF | float: 1 }}&nbsp;s
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.VarianceLateraleYF' | translate }}:</label>
                  {{ rombergAnalysisResult.VarianceLateraleYF | float: 3 }}&nbsp;cm<sup>2</sup>
                </div>
                <div>
                  <label>{{ 'Checkup.Romberg.Summary.SpeedMoyYF' | translate }}:</label>
                  {{ rombergAnalysisResult.SpeedMoyYF | float: 1 }}&nbsp;cm.s<sup>-1</sup>
                </div>
                <div *ngIf="typePlate == 'Kinvent' && closedEyesLeftSupport">
                  <label>{{ 'Checkup.Romberg.Summary.ClosedprcLeftSupport' | translate }}:</label>
                  {{ closedEyesLeftSupport | number: '1.0-0' }}%
                </div>
                <div *ngIf="typePlate == 'Kinvent' && closedEyesLeftSupport">
                  <label>{{ 'Checkup.Romberg.Summary.ClosedprcRightSupport' | translate }}:</label>
                  {{ 100 - closedEyesLeftSupport | number: '1.0-0' }}%
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'Checkup.Romberg.Breadcrumb.StatoKinesiGram' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div class="col-md-6 col-xs-12 lablist">
                <label>{{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }} :</label>
                <div class="graphstato">
                  <img [attr.src]="rombergAnalysisResult.graphes.statok | secure | async" />
                </div>
              </div>
              <div class="col-md-6 col-xs-12 lablist">
                <div>
                  <label>{{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }} :</label>
                </div>
                <div class="graphstato">
                  <img [attr.src]="rombergAnalysisResult.graphes.stasym | secure | async" />
                </div>
              </div>
            </div>
          </div>

          <div class="force-page-break"></div>
          <h2>{{ 'Checkup.Romberg.Breadcrumb.StabiloGram' | translate }} :</h2>
          <div class="row">
            <div class="border">
              <div id="graphesStabilo">
                <img [attr.src]="rombergAnalysisResult.graphes.stabilo | secure | async" />
              </div>
            </div>
          </div>
          <div class="force-page-break"></div>
          <h2>{{ 'Checkup.Walk.Breadcrumb.Longitudinal' | translate }} : YO</h2>
          <div class="row">
            <div class="border">
              <div class="setOfGraph graphic span">
                <div *ngFor="let graph of graphsRomb; let i = index" class="graphic2">
                  <!--    
                  <app-graph-verticalbar
                    [title]="graph.title"
                    [data]="graph"
                    idTitre="myTitre{{ i }}"
                    idLongitudinal="myLongitudinal{{ i }}"
                  ></app-graph-verticalbar> -->
                  <!--
                  <app-graph-verticalbar-report
                  [title]="graph.title"
                  [data]="graph"
                  idTitre="myTitre{{ i }}"
                  idLongitudinal="myLongitudinal{{ i }}"
                  width="350"
                  height="280"
                ></app-graph-verticalbar-report>
                -->
                  <app-graph-timeline
                    [title]="graph.title"
                    [data]="graph"
                    idTitre2="myTitre{{ i }}"
                    idLongitudinal2="myLongitudinal{{ i }}"
                    width="350"
                    height="280"
                  ></app-graph-timeline>
                </div>
              </div>
            </div>
          </div>

          <h2>{{ 'Checkup.Walk.Breadcrumb.Longitudinal' | translate }} : YF</h2>
          <div class="row">
            <div class="border">
              <div class="setOfGraph graphic span">
                <div *ngFor="let graph of graphsRombYF; let i = index" class="graphic2">
                  <!--    
                  <app-graph-verticalbar
                    [title]="graph.title"
                    [data]="graph"
                    idTitre="myTitre{{ i }}"
                    idLongitudinal="myLongitudinal{{ i }}"
                  ></app-graph-verticalbar> -->
                  <!--
                  <app-graph-verticalbar-report
                  [title]="graph.title"
                  [data]="graph"
                  idTitre="myTitre{{ i }}"
                  idLongitudinal="myLongitudinal{{ i }}"
                  width="350"
                  height="280"
                ></app-graph-verticalbar-report>
                -->
                  <app-graph-timeline
                    [title]="graph.title"
                    [data]="graph"
                    idTitre2="myTitre{{ i }}"
                    idLongitudinal2="myLongitudinal{{ i }}"
                    width="350"
                    height="280"
                  ></app-graph-timeline>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="col-md-12 footer-content-rapport">
  <button class="btn btn-primary" (click)="returnChoiceReport()">
    {{ 'previous' | translate }}
  </button>
  <button class="btn btn-primary" (click)="printPractitionnerReport()">{{ 'print' | translate }}</button>
</div>
