<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <!-- DIV CONTAIN HEADER LIST -->
      <div class="flex-column big-div">
        <div class="flex-row div-find-filter header-block">
          <div class="col-md-3 text-center mb-3">
            <label>{{ 'Patient.DebutPeriod' | translate }}</label>
            <input
              class="form-control py-2 border-left-0 border bg-grey"
              type="date"
              value=""
              id="input-search"
              [(ngModel)]="startDate"
              (ngModelChange)="onStartDateChanged($event)"
            />
          </div>
          <div class="col-md-3 text-center mb-3">
            <label>{{ 'Patient.finPeriod' | translate }}</label>
            <input
              class="form-control py-2 border-left-0 border bg-grey"
              type="date"
              value=""
              id="input-search"
              [(ngModel)]="endDate"
              (ngModelChange)="onEndDateChanged($event)"
            />
          </div>
          <div class="col-md-3 text-center mb-3">
            <label>&nbsp;</label>
            <!--mat-form-field-->
            <input
              matInput
              (keyup)="applyFilter($event.target.value)"
              style="border:none"
              placeholder="{{ 'Rechercher un patient' | translate }}"
              [(ngModel)]="filterValue"
            />
            <!--/mat-form-field-->
          </div>
          <div class="col-md-3 text-center mb-3">
            <label>&nbsp;</label>
            <button
              *ngIf="!hasFilterApplicated"
              class="btn-tertiary text-uppercase"
              type="button"
              data-toggle="modal"
              data-target="#modalTestFilter"
              data-backdrop="static"
              data-keyboard="false"
            >
              <img src="assets/images/icons/filter.svg" width="22" height="33" />
              {{ 'Filtrer' | translate }}
            </button>
            <button
              *ngIf="hasFilterApplicated"
              class="btn-tertiary text-uppercase"
              type="button"
              data-toggle="modal"
              data-target="#modalTestFilter"
              data-backdrop="static"
              data-keyboard="false"
            >
              <img src="assets/images/icons/filter_applicated.svg" width="22" height="33" />
              {{ 'Filtrer' | translate }}
            </button>
          </div>
          <!--
            <div class="col-md-3 text-center mb-3">
              <label>&nbsp;</label>
              <button
                class="btn-tertiary text-uppercase"
                type="button"
                (click) = "onSubmit()"
              >
                {{ 'Search' | translate }}
              </button>
          </div>
          -->
          <div class="col-md-3 text-center mb-3">
            <label>&nbsp;</label>
            <button
              *ngIf="bilanListToExport.length > 0 && !exportRunning"
              class="btn btn-secondary"
              type="button"
              data-toggle="modal"
              data-target="#exportBilanModal"
              [disabled]="typeExport == 2 && !getAllAnalysisData"
            >
              <img src="assets/images/icons/download.svg" />
              {{ 'Export' | translate }}
            </button>
            <br />
            <input
              *ngIf="bilanListToExport.length > 0 && !exportRunning"
              type="checkbox"
              id="anonymousData"
              [(ngModel)]="anonymousData"
              [disabled]="typeExport == 2 || typeExport == 4"
              checked
            />
            <label *ngIf="bilanListToExport.length > 0 && !exportRunning" for="anonymousData" translate>{{
              'anonymousData' | translate
            }}</label>
            <br />
            <select
              class="form-control"
              *ngIf="bilanListToExport.length > 0 && !exportRunning"
              [(ngModel)]="typeExport"
              (ngModelChange)="onChangeTypeExport($event)"
            >
              <option [ngValue]="0">{{ 'exportOnlyData' | translate }}</option>
              <option [ngValue]="1" *ngIf="hasRoleExportData">{{ 'exportRawData' | translate }}</option>
              <option [ngValue]="2">{{ 'exportReports' | translate }}</option>
              <option [ngValue]="3">{{ 'exportQuest' | translate }}</option>
              <option [ngValue]="4">{{ 'exportReportsQuest' | translate }}</option>
            </select>
            <!--
            <input
              *ngIf="bilanListToExport.length > 0 && !exportRunning"
              type="checkbox"
              id="exportRawData"
              [(ngModel)]="exportRawData"
              [disabled]="!hasRoleExportData"
            />
            <label *ngIf="bilanListToExport.length > 0 && !exportRunning" for="exportRawData" translate>{{
              'exportRawData' | translate
            }}</label>
            -->
            <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;" *ngIf="exportRunning">
              <span class="sr-only">Loading...</span>
            </div>
            <label *ngIf="exportRunning" for="ExportRunning" translate>Export en cours</label>
            <label *ngIf="exportError" for="ExportError" translate>Erreur export</label>
            <div class="col-lg-7 status-text" *ngIf="exportFinished">
              <button
                *ngIf="exportFinished && !exportRunning"
                class="btn-tertiary text-uppercase"
                type="button"
                data-toggle="modal"
                data-target="#DownloadInProgressModal"
                (click)="downloadExport()"
              >
                <img src="assets/images/images/download.png" width="30%" height="30%" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
    <div class="row justify-content">
      <div class="flex-row div-find-filter header-block">
        <button class="btn btn-tertiary" (click)="selectunSelectAllRows()" *ngIf="patients.length > 0">
          {{ selectionTitle ? selectionTitle : ('select.all' | translate) }}
        </button>
      </div>
    </div>
    <div class="wrapper">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z8">
        <ng-container matColumnDef="patientId">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <i class="fa fa-chevron-up icon" *ngIf="element.expanded"></i>
            <i class="fa fa-chevron-down icon" *ngIf="!element.expanded"></i>
            &nbsp;
            <input
              type="checkbox"
              [(ngModel)]="element.isSelected"
              name="list_name"
              value="{{ element.patientId }}"
              (ngModelChange)="parentCheck(element)"
            />
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ 'Patient' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <button class="btn btn-tertiary" (click)="getPatientDetail(element.patientId)">
              {{ element.name }}
            </button>
            <p>{{ element.folderNumber }}</p>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="birthDate">
          <th mat-header-cell *matHeaderCellDef>{{ 'Patient.YearsOld' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ getAge(element.birthDate) }}</td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="lastTest">
          <th mat-header-cell *matHeaderCellDef>{{ 'Dernier_test' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ testListName[element.lastTest] | translate }}</td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="lastTestDate">
          <th mat-header-cell *matHeaderCellDef>{{ 'Dernier_passage' | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element.lastTestDate | localdate: 'datetime' }}</td>
        </ng-container>

        <!--
        <ng-container matColumnDef="">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element"> 
            <button
            *ngIf="rolesCheck.hasManagePatient | async"
            class="btn btn-tertiary"
            data-toggle="modal"
            (click)="getPatientDetail(element.patientId)"
          >
            {{ 'app.bilan.voir' | translate }}
          </button>
          </td>
        </ng-container>
  -->
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
            <div class="example-element-detail" [@detailExpand]="element.expanded ? 'expanded' : 'collapsed'">
              <table class="detail-table">
                <!--
                <tr>
                  <th>&nbsp;</th>
                  <th>Test Name</th>
                  <th>Test Date</th>
                </tr>
              -->
                <ng-container matColumnDef="expandedDetail" *ngFor="let bilan of element.listBilansDetails">
                  <tr>
                    <td>
                      <input
                        type="checkbox"
                        [(ngModel)]="bilan.isSelected"
                        name="list_name_child"
                        value="{{ bilan.testId }}"
                        (ngModelChange)="childCheck(element, element.listBilansDetails)"
                      />
                    </td>
                    <td>
                      {{ bilan.testType == 1 ? bilan.testName : (testListName[bilan.testType] | translate) }}
                    </td>
                    <td>{{ bilan.testDate | localdate: 'datetime' }}</td>
                    <td *ngIf="bilan.courseId && bilan.courseId > -1">
                      {{ 'Patient.Profil.course_fragility' | translate }}
                    </td>
                    <td *ngIf="!bilan.courseId || bilan.courseId < 0">
                      &nbsp;
                    </td>
                    <td>
                      <button class="btn btn-tertiary" (click)="viewCourseObject(bilan)">
                        {{ 'app.bilan.voir' | translate }}
                      </button>
                    </td>
                  </tr>
                </ng-container>
              </table>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr
          mat-row
          *matRowDef="let element; columns: columnsToDisplay"
          class="example-element-row"
          [class.example-expanded-row]="element.expanded"
          (click)="toggleRow(element)"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>
      </table>
    </div>
  </div>
</section>

<!-- Modal filter Test -->
<div
  class="modal fade"
  id="modalTestFilter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalTestFilterTitle"
  aria-hidden="true"
  #basicModalFilter
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTestFilterTitle">
          {{ 'Filter.patients' | translate }}
        </h5>
        <button
          #btnCloseFilter
          type="button"
          class="close"
          (click)="closeModalFilter()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <mat-list>
            <mat-list-item *ngFor="let item of checkboxList">
              <mat-checkbox
                name="{{ item.id }}"
                [checked]="item.checked"
                [(ngModel)]="item.checked"
                [disabled]="item.disabled"
                [labelPosition]="item.labelPosition"
                (change)="listChange()"
                class="dropdown-item"
              >
                {{ item.name | translate }}
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
          <div class="flex-row div-find-filter modal-footer">
            <mat-checkbox
              class="btn-tertiary "
              name="selectChange"
              [(ngModel)]="allCheckboxChecked"
              [(indeterminate)]="allCheckboxIndeterminate"
              (change)="allCheckboxChange()"
              mat-button
            >
              <ng-container *ngIf="!allCheckboxChecked">{{ 'select.all' | translate }}</ng-container>
              <ng-container *ngIf="allCheckboxChecked">{{ 'deselect.all' | translate }}</ng-container>
            </mat-checkbox>
            <button class="btn btn-primary col-sm-4" (click)="onSubmit()" type="submit">
              {{ 'button.validation' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<!-- Modal Bilans Export -->
<div
  class="modal fade"
  id="exportBilanModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exportBilanModalTitle"
  aria-hidden="true"
  #exportBilanModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exportBilanModalTitle">
          {{ 'app.warning' | translate }}
        </h5>
        <button #btnCancelExport type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="confirm-text" [innerHtml]="'app.patient.confirmExport' | translate"></div>
      </div>

      <div class="flex-row justify-content-around modal-footer">
        <button
          class="btn btn-tertiary"
          data-toggle="modal"
          data-target="#ExportInProgressModal"
          (click)="startExport()"
        >
          {{ 'understand' | translate }}
        </button>
        <button #btnNoExport class="btn btn-primary" (click)="closeModalExportBilan()" autofocus>
          {{ 'Annuler' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Bilans export en cours -->
<div
  class="modal fade"
  id="ExportInProgressModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ExportInProgressModalTitle"
  aria-hidden="true"
  #ExportInProgressModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ExportInProgressModalTitle">
          {{ 'app.warning' | translate }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="confirm-text" [innerHtml]="'app.patient.exportInProgress' | translate"></div>
      </div>

      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" data-dismiss="modal" aria-label="Close">{{ 'understand' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Bilans download en cours -->
<div
  class="modal fade"
  id="DownloadInProgressModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="DownloadInProgressModalTitle"
  aria-hidden="true"
  #DownloadInProgressModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="DownloadInProgressModalTitle">
          {{ 'app.warning' | translate }}
        </h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="confirm-text" [innerHtml]="'app.patient.confirmDownload' | translate"></div>
      </div>

      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" data-dismiss="modal" aria-label="Close">{{ 'understand' | translate }}</button>
      </div>
    </div>
  </div>
</div>
