import { Component, OnInit, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import {
  PatientDetail,
  GripControllerService,
  NewGrip,
  Grip,
  CourseControllerService,
  Course,
  PatientControllerService,
  UpdatePatient,
  QuestionnaireReplyControllerService,
} from '@abilycare/dal-client';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { GripMonitorComponent } from './grip-monitor/grip-monitor.component';
import * as bluetoothConst from 'src/app/tools/bluetooth.device.constants';
import { Timer } from 'src/app/modules/time';
import { Location } from '@angular/common';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';

export interface Measures {
  hand: string;
  timestamp: string;
  measure: number;
}

@Component({
  selector: 'app-fried-grip',
  templateUrl: './grip.component.html',
  styleUrls: ['./grip.component.scss'],
  providers: [GripMonitorComponent],
})
export class GripComponent implements OnInit {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;
  @ViewChild('gripMonitor', { static: true }) gripMonitor: GripMonitorComponent;
  @Input() onMesures: EventEmitter<number> = new EventEmitter();

  public grip: Grip;
  public leftHandV1: number;
  public rightHandV1: number;
  public leftHandV2: number;
  public rightHandV2: number;

  public isTest1RightHandV1Finished: boolean = false;
  public isTest1LeftHandV1Finished: boolean = false;
  public isTest1RightHandV2Finished: boolean = false;
  public isTest1LeftHandV2Finished: boolean = false;
  public isTestRightV1Running: boolean = false;
  public isTestLeftV1Running: boolean = false;
  public isTestRightV2Running: boolean = false;
  public isTestLeftV2Running: boolean = false;
  public startTestTitle: string;
  public restartTestTitle: string;
  public ArrayMeasures: number[];
  public measuresData: Array<Measures>;
  /*
  public isRightMeasureValid: boolean;
  public isLeftMeasureValid: boolean;
*/
  public loadingGrip: boolean;
  public gripCalculated: boolean;
  public gripSaved: boolean;
  public gripConnected: boolean;

  public disabledTestButton: boolean = false;

  // public readonly boardReady: Observable<boolean>;

  public patient: PatientDetail;

  // private state: State = new State();

  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public testName: string = i18nKey('Course.filter.grip.result');

  public newPage: string = '';
  public rightValue: number = 0;
  public leftValue: number = 0;

  public scoreProfil: number = 0;

  /** timer */
  public timer: Timer = new Timer(bluetoothConst.GRIP_TIME_MEASURE);
  public course: any;

  public numberInputInvalidChars: string[] = ['-', '+', 'e'];
  private errorLogged = false;

  constructor(
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private gripService: GripControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService, //private gripMonitorService: GripMonitorService
    private location: Location,
    private courseDataService: CourseControllerService,
    private patientDataService: PatientDataService,
    private questionnaireReplyService: QuestionnaireReplyControllerService,
  ) {
    this.gripCalculated = false;
    this.gripSaved = false;
    //this.gripMonitor.connectDevice();
  }

  ngOnInit() {
    this.eventTrackingService.init();
    this.course = this.location.getState();
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.gripCalculated = false;
    this.gripSaved = false;
    this.gripConnected = false;
    this.startTestTitle = this.translateService.instant('app.romberg.startTest');
    this.restartTestTitle = this.translateService.instant('app.test.restart');
    this.measuresData = new Array<Measures>();

    //getScore profil Light
    this.questionnaireReplyService
      .questionnaireReplyControllerFindQuestionnaryByPatientIdAndQuestId(this.patient.id, 33)
      .subscribe((questReply) => {
        this.scoreProfil = questReply.scores[0];
      });
  }

  assignValue(inputName: string, value: number) {
    this[inputName] = value;
  }
  myValue: number;
  changeMeasure(data: number) {
    //this.onMesures.emit(data);
    this.myValue = data;
  }

  changeStatusRunning(handMeasureInputName: string, value: boolean) {
    if (handMeasureInputName == 'rightHandV1') this.isTestRightV1Running = value;
    else if (handMeasureInputName == 'leftHandV1') this.isTestLeftV1Running = value;
    else if (handMeasureInputName == 'rightHandV2') this.isTestRightV2Running = value;
    else if (handMeasureInputName == 'leftHandV2') this.isTestLeftV2Running = value;
  }

  changeStatusTestFinished(handMeasureInputName: string, value: boolean) {
    if (handMeasureInputName == 'rightHandV1') this.isTest1RightHandV1Finished = value;
    else if (handMeasureInputName == 'leftHandV1') this.isTest1LeftHandV1Finished = value;
    else if (handMeasureInputName == 'rightHandV2') this.isTest1RightHandV2Finished = value;
    else if (handMeasureInputName == 'leftHandV2') this.isTest1LeftHandV2Finished = value;
  }

  getMaxValue(handMeasureInputName: string) {
    this[handMeasureInputName] = Math.max(...this.ArrayMeasures);
  }

  restartTestFromGrip(handMeasureInputName: string) {
    this.changeStatusRunning(handMeasureInputName, false);
    this.changeStatusTestFinished(handMeasureInputName, false);
    this.timer.resetReverse(bluetoothConst.GRIP_TIME_MEASURE);
    this.startTestFromGrip(handMeasureInputName);
  }

  startTestFromGrip(handMeasureInputName: string) {
    this.ArrayMeasures = [];
    //this.JSONMeasures = new Array<MeasureDetail>();
    this.gripMonitor.getValue();
    this.disabledTestButton = true;
    this.gripMonitor.lastCheckout = new Date();
    //start measure from handler
    this.changeStatusRunning(handMeasureInputName, true);
    this.timer.startReverse();
    this.gripMonitor.measures.subscribe({
      next: (value) => {
        const valuesTable = value.split('|');
        const measure = valuesTable[0];
        const timeMeasure = valuesTable[1];
        this[handMeasureInputName] = measure;
        this.ArrayMeasures.push(parseFloat(measure));
        const measureDetail: Measures = {
          hand: handMeasureInputName,
          timestamp: timeMeasure,
          measure: parseFloat(measure),
        };
        this.measuresData.push(measureDetail);
      },
      complete: () => {
        //console.log('entrer dans le complete de start et affiche arrayMesures ', this.ArrayMeasures);
        this.changeStatusTestFinished(handMeasureInputName, true);
        this.getMaxValue(handMeasureInputName);
        this.validateMesure();
        this.timer.resetReverse(bluetoothConst.GRIP_TIME_MEASURE);
      },
    });
  }

  public modify(): void {
    this.gripCalculated = true;
    this.gripSaved = false;
  }

  // On click on another page, we check if test is started and display message
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.loadingGrip) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }

    if (this.gripCalculated) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  // On click on navigator button (before reload or quit), we check if test is started and display message
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event: Event): boolean {
    if (this.loadingGrip) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }
    if (this.gripCalculated) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  public changeMesure(): void {
    this.gripCalculated = false;
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  resetModal() {}

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  public validateMesure(): void {
    if (this.leftHandV1 > 0 && this.rightHandV1 > 0) this.gripCalculated = true;
  }
  /**
   * Method which get the last grip saved in database.
   */
  public getLastGripSaved(): void {
    this.gripService.gripControllerFindLastGrip(this.patient.id).subscribe((data) => {
      this.grip = data;
    });
  }
  /**
   * Method which save the grip in database.
   */
  public saveGrip(): void {
    let that = this;

    let gripObj: NewGrip = {
      leftHandV1: this.leftHandV1,
      leftHandV2: this.leftHandV2 ? this.leftHandV2 : 0,
      rightHandV1: this.rightHandV1,
      rightHandV2: this.rightHandV2 ? this.rightHandV2 : 0,
      leftHandV3: 0,
      rightHandV3: 0,
      measuredata: this.measuresData ? this.measuresData : null,
      scoreProfil: this.scoreProfil,
    };
    if (this.course && this.course.id) gripObj.courseId = this.course.id;

    this.gripService.gripControllerCreate(this.patient.id, gripObj).subscribe(
      async (data) => {
        this.eventTrackingService.logDataChangePatient('DATA_CREATED', this.patient.id, data.id, 'Grip', {}, data);
        this.gripCalculated = await false;
        //this.router.navigate(['/Patient/' + this.patient.id]);
        await this.snackBarService.show(this.translateService.instant('app.grip.successSave'), 'success');
        this.gripSaved = await true;
        await this.getLastGripSaved();
        await that.updateLastTestForPatient();
        if (this.course && this.course.id) {
          await that.updateStatusAndScore(this.course);
          //await this.router.navigateByUrl('/courses/fried/gripView', { state: this.course });
        }
      },
      (error) => {
        console.log('Error :', error);
        this.snackBarService.show(this.translateService.instant('Err-Grip-01'), 'danger');
        if (!this.errorLogged) {
          this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Grip-01', error);
          this.errorLogged = true;
        }
      },
    );
  }

  public updateLastTestForPatient() {
    const updatePatient: UpdatePatient = {
      id: this.patient.id,
      lasttestdate: new Date(),
      lasttesttype: this.course && this.course.id ? 0 : 4,
    };

    this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
      });
    });
  }

  public async updateStatusAndScore(course: Course) {
    const that = this;
    await this.courseDataService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      //refresh navbar to display score
      //get the course object updated
      this.courseDataService.courseControllerFindById(course.id).subscribe((courseObj) => {
        that.router.navigateByUrl('/courses/fried/grip', { state: courseObj });
      });
    });
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.gripCalculated = false;
    this.router.navigate([this.newPage]);
  }
}
