import { Footwear, WalkingAid, WalkingAidControllerService } from '@abilycare/dal-client';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FootWearDataService } from 'src/app/modules/abilycare-data-access';
import { ShoesSelectorValues } from 'src/app/modules/force-platform/models/shoes-selector-values.model';

@Component({
  selector: 'app-checkup-romberg-shoes-selector',
  templateUrl: './shoes-selector-romberg.component.html',
  styleUrls: ['./shoes-selector.component.scss'],
  providers: [FootWearDataService],
})

/**
 * This class represents a component visible in differents checkup. It allows to specify the type of shoes of the patient
 * and if the healthcare helped him during the checkup
 */
export class ShoesRombergSelectorComponent implements OnInit {
  @Input() public value: ShoesSelectorValues;
  @Output() public valueChange = new EventEmitter<ShoesSelectorValues>();

  public footWears: Footwear[] = [];
  public helpStability: WalkingAid[] = [];

  constructor(private footWearService: FootWearDataService, private translateService: TranslateService) {}

  public async ngOnInit() {
    this.footWears = await this.footWearService.GetAllFootWears(this.translateService.currentLang);
    this.helpStability = await this.footWearService.GetAllWalkingAid(this.translateService.currentLang);
    this.value.helpStability = [];
  }

  public setShoes(shoesType: number): void {
    this.value.shoesType = shoesType;
    this.valueChange.emit(this.value);
  }

  onCheckboxChange(event: Event, value: number): void {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      // Add the selected value to the walkingAid array
      this.value.helpStability.push(value);
    } else {
      // Remove the value if unchecked
      this.value.helpStability = this.value.helpStability.filter((aid: number) => aid !== value);
    }
  }
}
