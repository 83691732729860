import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { LANGUAGES } from 'src/app/tools/divers.const';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public login: string;
  public password: string;
  public loading: boolean;
  public errorMessage: string;
  public cguHtml: string = '';
  private readonly redirectUrlAfterFirstLogin: string = '/Patient';
  private subscriptions: Subscription[] = [];
  private errorLogged = false;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private router: Router,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private dataService: DataProviderService,
  ) {
    if (this.dataService) {
      this.dataService.storeHeaderShowed(false);
      let currentLanguage = this.dataService.getLanguage();
      if ((currentLanguage = null)) {
        this.translateService.setDefaultLang(LANGUAGES[2]);
        this.translateService.use(LANGUAGES[2]);
      }
    } else {
      this.translateService.setDefaultLang(LANGUAGES[2]);
      this.translateService.use(LANGUAGES[2]);
    }
  }

  public async ngOnInit() {
    // Nothing
    //console.log('currentLanguage dans login: NgInit ', this.translateService.getDefaultLang());
    this.translateService.get('CGU.Content').subscribe((translated: string) => {
      this.cguHtml = this.translateService.instant('CGU.Content');
    });
    this.loading = false;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public get isImplicit(): boolean {
    return this.authService.implicit;
  }

  public authentication(): void {
    this.eventTrackingService.init();
    this.loading = true;
    if (this.authService.implicit) {
      this.authService.initImplicitFlow();
    } else {
      this.subscriptions.push(
        this.authService.loginWithPasswordFlow(this.login, this.password).subscribe(
          () => {
            console.log('LoginComponent: successfully logged in.');
            this.loading = false;
            this.router.navigate([this.redirectUrlAfterFirstLogin]);
            console.log('redirectUrlAfterFirstLogin=' + this.redirectUrlAfterFirstLogin);
          },
          (err) => {
            console.error('LoginComponent: Err-Login-01 ', err);
            const errMsg = this.translateService.instant('Err-Login-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logError('Err-Login-01', err);
              this.errorLogged = true;
            }
            this.loading = false;
          },
        ),
      );
    }
  }
}
