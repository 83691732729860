import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { StaticDataService } from 'src/app/modules/abilycare-data-access/api/static-data.service';
import {
  StaticItemReference,
  NewPatattInPatient,
  PatattDetail,
  SymptomDetail,
  PatientDetail,
} from '@abilycare/dal-client';
import { PatAttDataService } from 'src/app/modules/abilycare-data-access/api/patatt-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-patatt-edition',
  templateUrl: './patatt-edition.component.html',
  styleUrls: ['./patatt-edition.component.scss'],
})
export class PatattEditionComponent implements OnInit, OnDestroy {
  @ViewChild('btnDuplicatePatAttModal', { static: true }) btnDuplicatePatAttModal: ElementRef;

  public etiologiesList: Array<StaticItemReference> = null;
  public pathologiesList: Array<StaticItemReference> = null;
  public symptomsList: Array<StaticItemReference> = null;

  public selectedEtiology: StaticItemReference = null;
  public selectedPathology: StaticItemReference = null;
  public selectedSymptom: StaticItemReference = null;
  public symptomDetailsModify: SymptomDetail = null;
  public selectedSymptomtransfer: SymptomDetail = null;

  public newPatAtt: NewPatattInPatient = {};

  public symptomSelectedOptions: Array<SymptomDetail> = new Array<SymptomDetail>();
  public isDisabled: boolean = true;
  public isSymptomDisabled: boolean = false;

  public patientId: number;
  public isCreation: boolean;

  public selectedPatatt: PatattDetail = null;

  public isLoaded = false;

  public isAddClicked: boolean = false;
  public istransferClicked: boolean = false;

  public isTransferSelected: boolean = false;

  public indexSelected: number;

  public transferPatattList: Array<SymptomDetail> = new Array<SymptomDetail>();
  public patattSelectedTransfer: PatattDetail = null;

  public unknownIdPatient = -1;
  public healthyIdPatient = -2;

  public allPatatt: Array<PatattDetail> = new Array<PatattDetail>();

  public patient: PatientDetail;

  private subscriptions: Subscription[] = [];
  public returnTitle: string = '';
  private errorLogged = false;

  constructor(
    private staticDataService: StaticDataService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataProviderService,
    private patattDataService: PatAttDataService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
  ) {
    this.returnTitle = 'Patient.Profil.back_patient';
  }

  ngOnInit() {
    this.eventTrackingService.init();
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((params) => {
        this.patientId = parseInt(params.get('patientId').toString(), 10);
        this.isCreation = params.get('isCreation') === 'true' ? true : false;

        this.subscriptions.push(
          this.staticDataService.getAllSymptoms().subscribe((symptoms) => {
            this.symptomsList = symptoms;
          }),
        );

        if (this.isCreation) {
          // ONLY ON CREATION MOD
          this.subscriptions.push(
            this.staticDataService.getAllEtiologies().subscribe((etiologies) => {
              this.getAllPatatt(etiologies, null);
            }),
          );
        } else {
          // ONLY ON EDITION MOD
          this.subscriptions.push(
            this.activatedRoute.queryParamMap.subscribe((parameters) => {
              const patattId: number = parseInt(parameters.get('patattId'), 10);
              this.getAllPatatt(null, patattId);
            }),
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public getAllPatatt(etiologies: Array<StaticItemReference>, patattId: number): void {
    this.subscriptions.push(
      this.patattDataService.getAllPatAtt(this.patientId).subscribe((allPatatt) => {
        this.allPatatt = allPatatt;
        if (etiologies) {
          if (allPatatt && allPatatt.length > 0) {
            etiologies.splice(0, 2);
          }
          this.etiologiesList = etiologies;
        }
        this.transferPatattList = new Array<SymptomDetail>();
        allPatatt.forEach((patatt) => {
          if (!patatt.etiology && !patatt.pathology && patatt.symptoms && patatt.symptoms.length === 1) {
            this.patattSelectedTransfer = patatt;
            this.transferPatattList.push(patatt.symptoms[0]);
          }
          if (!this.isCreation && patattId) {
            if (patatt.id === patattId) {
              this.selectedPatatt = patatt;
              this.isLoaded = false;
              this.symptomSelectedOptions = patatt.symptoms;

              if (
                !this.selectedPatatt.etiology ||
                this.selectedPatatt.etiologyId === this.healthyIdPatient
                //||
                //this.selectedPatatt.etiologyId === this.unknownIdPatient
              ) {
                this.isSymptomDisabled = true;
              } else {
                this.isSymptomDisabled = false;
              }
            }
          }
        });
      }),
    );
  }

  public onEtiologieChange(): void {
    if (this.selectedEtiology) {
      this.pathologiesList = this.selectedEtiology.sub;
      this.isDisabled = false;
      this.selectedSymptom = null;
      this.selectedSymptomtransfer = null;
      this.symptomDetailsModify = null;
      this.isSymptomDisabled = false;
      this.indexSelected = -1;
      if (
        this.selectedEtiology.id === this.healthyIdPatient
        //|| this.selectedEtiology.id === this.unknownIdPatient
      ) {
        this.isSymptomDisabled = true;
      }
    } else {
      this.pathologiesList = null;
      if (
        !this.symptomSelectedOptions ||
        this.symptomSelectedOptions.length <= 0 ||
        this.symptomSelectedOptions.length >= 2
      ) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    }
    console.log('onchange ethiology : this.isSymptomDisabled is ', this.isSymptomDisabled);
  }

  public onSymptomsChange(): void {
    this.indexSelected = -1;
    this.isTransferSelected = false;
    this.symptomDetailsModify = null;
  }

  public onSymptomsTranferChange(): void {
    this.isTransferSelected = true;
    this.indexSelected = -1;
    this.selectedSymptom = null;
    this.symptomDetailsModify = this.selectedSymptomtransfer;
  }

  public onValidateOption(symptonOptions: SymptomDetail): void {
    if (!this.symptomSelectedOptions) {
      this.symptomSelectedOptions = new Array<SymptomDetail>();
    }

    if (!this.symptomDetailsModify || this.isTransferSelected) {
      this.symptomSelectedOptions.push(symptonOptions);
      this.indexSelected = this.symptomSelectedOptions.length - 1;

      if (this.isTransferSelected) {
        const indexTransfer = this.transferPatattList.indexOf(this.selectedSymptomtransfer);
        this.transferPatattList.splice(indexTransfer, 1);
        this.selectedSymptomtransfer = null;
      }
    } else {
      this.symptomSelectedOptions[this.indexSelected] = symptonOptions;
    }
    this.selectedSymptom = null;
    this.symptomDetailsModify = this.symptomSelectedOptions[this.indexSelected];

    this.isTransferSelected = false;
    this.isDisabled = false;

    // DISABLED OR ENABLED SYMPTOM SELECTION
    if (!this.selectedEtiology && (!this.selectedPatatt || !this.selectedPatatt.etiology)) {
      this.isSymptomDisabled = true;
    } else {
      this.isSymptomDisabled = false;
    }
  }

  public createPatAtt(): void {
    this.newPatAtt = {};
    const patattInformation = {
      etiologyId: this.selectedEtiology ? this.selectedEtiology.id : null,
      pathologyId: this.selectedPathology ? this.selectedPathology.id : null,
    };

    this.patattCreation([], patattInformation, false);
  }

  public updatePatAtt(): void {
    this.newPatAtt = {};

    const patattInformation = {
      etiologyId: this.selectedPatatt.etiologyId ? this.selectedPatatt.etiologyId : null,
      pathologyId: this.selectedPatatt.pathologyId ? this.selectedPatatt.pathologyId : null,
    };
    const arrayNumber: Array<number> = new Array<number>();
    arrayNumber.push(this.selectedPatatt.id);

    this.patattCreation(arrayNumber, patattInformation, true);
  }

  public patattCreation(arrayNumber: Array<number>, patattInformation, fromupdate: boolean): void {
    if (patattInformation.etiologyId) this.newPatAtt.etiologyId = patattInformation.etiologyId;

    if (patattInformation.pathologyId) this.newPatAtt.pathologyId = patattInformation.pathologyId;

    if (this.symptomSelectedOptions && this.symptomSelectedOptions && this.symptomSelectedOptions.length > 0) {
      this.newPatAtt.symptoms = this.symptomSelectedOptions;
    }

    if (this.checkIfPatAttExist(this.newPatAtt) || fromupdate) {
      this.subscriptions.push(
        this.patattDataService.createPatAtt(this.patientId, arrayNumber, this.newPatAtt).subscribe(
          (data) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_CREATED',
              this.patient.id,
              data.id,
              'Pattat',
              {},
              data,
            );
            this.deletePatAtt();
            this.router.navigate(['PatAtt/', this.patientId]);
          },
          (err) => {
            console.error('PatattEditionComponent: Err-Pattat-01 ', err);
            const errMsg = this.translateService.instant('Err-Pattat-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Pattat-01', err);
              this.errorLogged = true;
            }
          },
        ),
      );
    } else {
      this.btnDuplicatePatAttModal.nativeElement.click();
    }
  }

  public checkIfPatAttExist(newPatatt: NewPatattInPatient): boolean {
    let isSinglePatatt: boolean = true;

    if (this.allPatatt && this.allPatatt.length) {
      this.allPatatt.forEach((patatt) => {
        if (
          (newPatatt.etiologyId === patatt.etiologyId && newPatatt.pathologyId === patatt.pathologyId) ||
          (newPatatt.etiologyId === patatt.etiologyId && !newPatatt.pathologyId && !patatt.pathologyId)
        ) {
          isSinglePatatt = false;
        }
      });
    }

    return isSinglePatatt;
  }

  public cancelPatAtt(): void {
    this.router.navigate(['PatAtt/', this.patientId]);
  }

  public deletePatAtt(): void {
    // DELETE ONLY IF IS SYMPTOM TRANSFER
    if (this.patattSelectedTransfer && this.isTransferSelected) {
      this.subscriptions.push(
        this.patattDataService.deletePatAtt(this.patientId, this.patattSelectedTransfer.id).subscribe(
          (data) => {
            this.eventTrackingService.logDataChangePatient(
              'DATA_DELETED',
              this.patientId,
              this.patattSelectedTransfer.id,
              'Pattat',
              data,
              {},
            );
            this.snackBarService.show(this.translateService.instant('app.delete.patatt.success'), 'success');
          },
          (err) => {
            console.error('PatattEditionComponent: Err-Pattat-02 ', err);
            const errMsg = this.translateService.instant('Err-Pattat-02');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Pattat-02', err);
              this.errorLogged = true;
            }
          },
        ),
      );
    }
  }

  public deleteSymptom(index): void {
    this.symptomSelectedOptions.splice(index, 1);
    if (this.indexSelected === index) {
      this.selectedSymptom = null;
      this.symptomDetailsModify = null;
    }

    if ((!this.symptomSelectedOptions || this.symptomSelectedOptions.length <= 0) && !this.selectedEtiology) {
      this.isDisabled = true;
    } else {
      this.isDisabled = false;
    }

    this.getAllPatatt(null, null);
    this.selectedSymptomtransfer = null;
    this.isSymptomDisabled = false;
  }

  public selectSymptom(symptom: SymptomDetail, index: number) {
    if (this.indexSelected === index) {
      this.indexSelected = -1;
      this.symptomDetailsModify = null;
    } else {
      this.indexSelected = index;
      this.symptomDetailsModify = symptom;
    }
    this.selectedSymptom = null;
  }
}
