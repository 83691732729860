import { ApiModule, BASE_PATH } from '@abilycare/dal-client';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  DateAdapter,
  MatCardModule,
  MatCheckboxModule,
  MatDateFormats,
  MatListModule,
  MatPaginator,
  MatPaginatorModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatTableModule,
  MatDialogModule,
} from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { NgIdleModule } from '@ng-idle/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OAuthModule } from 'angular-oauth2-oidc';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { environment } from 'src/environments/environment';
import { AppConfigurationService } from './app-configuration.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceDal } from './modules/abilycare-data-access/service-dal/service-dal';
/** Service imports */
import { ServiceDalConfig } from './modules/abilycare-data-access/service-dal/service-dal.config';
import { CoreModule } from './modules/core/core.module';
import { UUID } from './modules/core/services/uuid/uuid.service';
import { RemoteInteraction as RemoteInteractionRomberg } from './modules/romberg/services/remote-interaction.service';
import { RemoteInteraction as RemoteInteractionWalk } from './modules/walk/services/remote-interaction.service';
import { MomentDateAdapter } from './modules/time/models/moment-date-adapter';
import { TimeModule } from './modules/time/time.module';
import { FloatDisplayed } from './modules/math/pipes/float-displayed.pipe';
import { SecurePipe } from './modules/common/pipes/securePipe';
import { WebpackTranslateLoader } from './services/webpack-translate-loader';
import { SharedModule } from './shared/shared.module';
import { HorizontalBarComponent } from './view/common/horizontalBar/horizontalBar.component';
import { VerticalBarComponent } from './view/common/verticalBar/verticalBar.component';
import { FriedNavbarComponent } from './view/courses/fried/navbar/navbar.component';
import { AssessmentComponent } from './view/courses/fried/romberg/romberg-assessment/assessment.component';
import { RombergAssessmentComponent } from './view/courses/fried/romberg/romberg-assessment/romberg-assessment.component';
import { RombergTutorialComponent } from './view/courses/fried/romberg/romberg-tutorial/romberg-tutorial.component';
import { RombergComponent } from './view/courses/fried/romberg/romberg.component';
import { WeightTutorialComponent } from './view/courses/fried/weight/weight-tutorial/weight-tutorial.component';
import { WeightComponent } from './view/courses/fried/weight/weight.component';
import { CoursesBreadcrumbNavbarComponent } from './view/courses/navbar/breadcrumb/navbar.component';
// import { CoursesPatientNavbarComponent } from './view/courses/navbar/patient/navbar.component';
import { InformationConnexionComponent } from './view/information-connexion/information-connexion.component';
import { LoginComponent } from './view/login/login.component';
import { NavbarComponent } from './view/navbar/navbar.component';
import { PatattEditionSymptomsComponent } from './view/patatt/patatt-edition-symptoms/patatt-edition-symptoms.component';
import { PatattEditionComponent } from './view/patatt/patatt-edition/patatt-edition.component';
import { PatattInformationComponent } from './view/patatt/patatt-information/patatt-information.component';
import { PatattListComponent } from './view/patatt/patatt-list/patatt-list.component';
// import { PatattNavbarComponent } from './view/patatt/patatt-navbar/patatt-navbar.component';
import { PatattSynthesisComponent } from './view/patatt/patatt-synthesis/patatt-synthesis.component';
import { PatientCreationFormComponent } from './view/patient/patient-creation-form/patient-creation-form.component';
import { PatientCreationHealthFormComponent } from './view/patient/patient-creation-health-form/patient-creation-health-form.component';
import { PatientCreationPersonalFormComponent } from './view/patient/patient-creation-personal-form/patient-creation-personal-form.component';
import { PatientCreationPractitionerFormComponent } from './view/patient/patient-creation-practitioner-form/patient-creation-practitioner-form.component';
import { PatientCreationProfilFormComponent } from './view/patient/patient-creation-profil-form/patient-creation-profil-form.component';
import { PatientDetailAccordeonComponent } from './view/patient/patient-detail/patient-detail-accordeon/patient-detail-accordeon.component';
import { PatientDetailHeaderComponent } from './view/patient/patient-detail/patient-detail-header/patient-detail-header.component';
import { PatientDetailComponent } from './view/patient/patient-detail/patient-detail.component';
import { PatientListComponent } from './view/patient/patient-list/patient-list.component';
import { PatientComponent } from './view/patient/patient.component';
import { ProfilComponent } from './view/profil/profil.component';
import { SettingsComponent } from './view/settings/settings.component';
import { ShoesSelectorComponent } from './view/shoes-selector/shoes-selector.component';
import { CustomNavbarComponent } from './view/custom-navbar/custom-navbar.component';
import { WalkComponent } from './view/courses/fried/walk/walk.component';
import { SensorConnexionComponent } from './view/courses/fried/walk/sensor-connexion/sensor-connexion.component';
import { MathModule } from './modules/math/math.module';
import { LoggerService } from './services/logger/logger.service';
import { LocalInteraction } from './modules/walk/services/local-interaction.service';
import { WalkTutorialComponent } from './view/courses/fried/walk/walk-tutorial/walk-tutorial.component';
import { WalkAppreciationComponent } from './view/courses/fried/walk/walk-appreciation/walk-appreciation.component';
import { ShoesRombergSelectorComponent } from './view/shoes-selector/shoes-selector-romberg.component';
import { WalkAssessmentComponent } from './view/courses/fried/walk/walk-assessment/walk-assessment.component';
import { WalkAssessmentItemComponent } from './view/courses/fried/walk/walk-assessment/walk-assessment-item/walk-assessment-item.component';
import { PatientDelegateComponent } from './view/patient/patient-delegate/patient-delegate.component';
import { PatientDelegateItemComponent } from './view/patient/patient-delegate/patient-delegate-item/patient-delegate-item.component';
import { ModalComponent } from './view/common/modal/modal.component';
import { SnackBarComponent } from './view/common/snack-bar/snack-bar.component';
import { QuestionnaryComponent } from './view/courses/fried/questionnary/questionnary.component';
import { QuestionnaryListComponent } from './view/courses/fried/questionnary/questionnary-list/questionnary-list.component';
import { TruncatePipe } from './modules/common/pipes/truncate.pipe';
import { RombergAppreciationComponent } from './view/courses/fried/romberg/romberg-appreciation/romberg-appreciation.component';
import { QuestionnaryStepsComponent } from './view/courses/fried/questionnary/questionnary-steps/questionnary-steps.component';
import { QuestionnaryInfosComponent } from './view/courses/fried/questionnary/questionnary-infos/questionnary-infos.component';
import { WalkGraphicsComponent } from './view/courses/fried/walk/walk-graphics/walk-graphics.component';
import { RapportsModule } from './modules/rapports/rapports.module';
import { GaugeChartComponent } from './view/common/gauge-chart';
import { CommonModule as com } from './modules/common/common.module';
import { SpinnerComponent } from './view/common/spinner/spinner/spinner.component';
import { FriedComponent } from './view/courses/fried/fried.component';
import { WalkComparaisonComponent } from './view/courses/fried/walk/walk-graphics/walk-comparaison/walk-comparaison.component';
import { WalkSemiogramComponent } from './view/courses/fried/walk/walk-graphics/walk-semiogram/walk-semiogram.component';
import { WalkLongitudinalComponent } from './view/courses/fried/walk/walk-graphics/walk-longitudinal/walk-longitudinal.component';
import { WeightGraphicsComponent } from './view/courses/fried/weight/weight-graphics/weight-graphics.component';
import { PatientHistoryCoursesComponent } from './view/patient/patient-history-courses/patient-history-courses.component';
import { HistoryNavbarComponent } from './view/patient/patient-history-courses/history-navbar/history-navbar.component';
import { GripComponent } from './view/courses/fried/grip/grip.component';

import { GripGraphicsComponent } from './view/courses/fried/grip/grip-graphics/grip-graphics.component';
import { WalkViewComponent } from './view/courses/fried/walk/walk-view/walk-view.component';
import { RombergViewComponent } from './view/courses/fried/romberg/romberg-view/romberg-view.component';
import { FriedHistoryNavbarComponent } from './view/courses/fried/fried-history-navbar/fried-history-navbar.component';
import { WeightViewComponent } from './view/courses/fried/weight/weight-view/weight-view.component';
import { GripViewComponent } from './view/courses/fried/grip/grip-view/grip-view.component';
import { FriedCustomNavbarComponent } from './view/courses/fried/fried-custom-navbar/fried-custom-navbar.component';
import { QuestionnaryViewComponent } from './view/courses/fried/questionnary/questionnary-view/questionnary-view.component';
import { GripMonitorComponent } from './view/courses/fried/grip/grip-monitor/grip-monitor.component';
import { BilansComponent } from './view/Bilans/bilans.component';
import { NotificationsComponent } from './view/notifications/notifications.component';
import { CookieService } from 'ngx-cookie-service';
import { WalkComparisonComponent } from './view/courses/fried/walk/walk-comparison/walk-comparison.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { RombergComparisonComponent } from './view/courses/fried/romberg/romberg-comparison/romberg-comparison.component';
import { CourseListComponent } from './view/courses/fried/course/course-list/course-list.component';
import { CourseInformationComponent } from './view/courses/fried/course/course-list/course-information/course-information.component';
import { QuestionnaryCourseComponent } from './view/courses/fried/questionnary-course/questionnary-course.component';
import { CourseSynthesisComponent } from './view/courses/fried/course/synthesis/course-synthesis.component';
import { ConfigComponent } from './view/common/config/config.component';
import { QuestionnaryGraphicsComponent } from './view/courses/fried/questionnary/questionnary-graphics/questionnary-graphics.component';
import { LoginErrorComponent } from './view/login-error/login-error.component';
import { NavbarService } from './services/nav-bar.service';
import { RapportQuestionnaryComponent } from './modules/rapports/rapportQuestionnary/rapportQuestionnary.component';
import { DialogComponent } from './view/app-dialog/app-dialog.component';

export function loadConfiguration(configService: AppConfigurationService): () => Promise<void> {
  return () => configService.load();
}

export function getApiUrl(configService: AppConfigurationService): string {
  const url: string = configService.serviceDalUri;
  return url;
}

export const MY_FORMAT: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function createServiceDalConfig(configService: AppConfigurationService): ServiceDalConfig {
  return configService.createServiceDalConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoginErrorComponent,
    PatientComponent,
    NavbarComponent,
    PatientListComponent,
    PatientCreationFormComponent,
    ProfilComponent,
    SettingsComponent,
    PatientDetailComponent,
    PatientDetailHeaderComponent,
    WeightComponent,
    WeightTutorialComponent,
    RombergComponent,
    RombergTutorialComponent,
    RombergAssessmentComponent,
    RombergAppreciationComponent,
    AssessmentComponent,
    InformationConnexionComponent,
    FriedNavbarComponent,
    CoursesBreadcrumbNavbarComponent,
    ShoesRombergSelectorComponent,
    ShoesSelectorComponent,
    PatientDetailAccordeonComponent,
    PatientCreationProfilFormComponent,
    PatientCreationPersonalFormComponent,
    PatientCreationPractitionerFormComponent,
    PatientCreationHealthFormComponent,
    PatattSynthesisComponent,
    PatattListComponent,
    PatattInformationComponent,
    PatattEditionComponent,
    PatattEditionSymptomsComponent,
    CustomNavbarComponent,
    WalkComponent,
    SensorConnexionComponent,
    WalkTutorialComponent,
    WalkAppreciationComponent,
    WalkAssessmentComponent,
    WalkAssessmentItemComponent,
    PatientDelegateComponent,
    PatientDelegateItemComponent,
    QuestionnaryComponent,
    QuestionnaryListComponent,
    TruncatePipe,
    QuestionnaryStepsComponent,
    QuestionnaryInfosComponent,
    WalkGraphicsComponent,
    FriedComponent,
    WalkComparaisonComponent,
    WalkSemiogramComponent,
    WalkLongitudinalComponent,
    WeightGraphicsComponent,
    PatientHistoryCoursesComponent,
    HistoryNavbarComponent,
    GripComponent,
    GripGraphicsComponent,
    WalkViewComponent,
    RombergViewComponent,
    FriedHistoryNavbarComponent,
    WeightViewComponent,
    GripViewComponent,
    FriedCustomNavbarComponent,
    QuestionnaryViewComponent,
    GripMonitorComponent,
    BilansComponent,
    NotificationsComponent,
    WalkComparisonComponent,
    RombergComparisonComponent,
    CourseListComponent,
    CourseInformationComponent,
    QuestionnaryCourseComponent,
    CourseSynthesisComponent,
    ConfigComponent,
    QuestionnaryGraphicsComponent,
    RapportQuestionnaryComponent,
    DialogComponent,
  ],
  entryComponents: [
    PatientCreationFormComponent,
    PatientCreationProfilFormComponent,
    SnackBarComponent,
    DialogComponent,
  ],
  imports: [
    ApiModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    Ng2SearchPipeModule,
    RouterModule,
    AppRoutingModule,
    CoreModule,
    CommonModule,
    com,
    SharedModule,
    MathModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader,
      },
    }),
    NgIdleModule.forRoot(),
    OAuthModule.forRoot({
      resourceServer: {
        // UGLY send token for all url (but reading configuration at this point is nightmare)
        allowedUrls: ['http'],
        sendAccessToken: true,
      },
    }),
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatListModule,
    MatCardModule,
    TimeModule,
    RapportsModule,
    MatPaginatorModule,
    MatTableModule,
    NgxSliderModule,
    MatDialogModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: loadConfiguration,
      deps: [AppConfigurationService],
      multi: true,
    },
    {
      provide: BASE_PATH,
      useFactory: getApiUrl,
      deps: [AppConfigurationService],
    },

    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMAT },
    { provide: DateAdapter, useClass: MomentDateAdapter },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    {
      provide: ServiceDalConfig,
      useFactory: createServiceDalConfig,
      deps: [AppConfigurationService],
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
    ServiceDal,
    UUID,
    RemoteInteractionRomberg,
    RemoteInteractionWalk,
    LoggerService,
    LocalInteraction,
    SnackBarComponent,
    CookieService,
    NavbarService,
    NavbarComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {
    if (environment.enableRouteDebug) {
      console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
    }
  }
}
