<div class="section-2" *ngIf="analysisResult">
  <div class="row type-h">
    <div class="col-md-4 col-xs-12 d-flex">
      <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/runner2x.png" alt="" /></div>
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'Rapport.TypeDeChaussure' | translate }}</div>
        <div class="info-2">{{ shoesType }}</div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex">
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'Rapport.WalkingAid' | translate }}</div>
        <div class="info-2">{{ walkingAid }}</div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex">
      <div *ngIf="analysisResult.graphes.semioInfo.uTurn === 'left'" class="col-md-2 col-xs-12 icon-container">
        <img src="assets/images/images/arrow-circle-with-half-broken-line2x.png" alt="" />
      </div>
      <div *ngIf="analysisResult.graphes.semioInfo.uTurn === 'right'" class="col-md-2 col-xs-12 icon-container">
        <img src="assets/images/images/arrow-circle-left-halh-broken-line2x.png" alt="" />
      </div>
      <div class="col-md-10 col-xs-12">
        <div class="info-1">{{ 'Rapport.sensdemietour' | translate }}</div>
        <div class="info-2">{{ analysisResult.graphes.semioInfo.uTurn | translate }}</div>
      </div>
    </div>

    <div class="col-md-4 col-xs-12 d-flex">
      <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/footprint2x.png" alt="" /></div>
      <div class="col-md-10 col-xs-12">
        <div class="info-1" id="nombrepas">{{ 'Rapport.nombrepas' | translate }}</div>
        <div class="info-2" id="stepNumber">{{ stepNumber }}</div>
      </div>
    </div>
    <div class="col-md-4 col-xs-12 d-flex">
      <div class="col-md-10 col-xs-12">
        <div class="info-1">{{ 'doubleTask' | translate }}</div>
        <div class="info-2">{{ doubleTask ? doubleTask : 'N/A' }}</div>
      </div>
    </div>
  </div>
  <div class="row mini-section">
    <div class="col-md-1 col-xs-12 icon-container">
      <img src="assets/images/images/vitesse2x.png" alt="" width="30%" height="30%" />
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="titre-1">{{ 'Rapport.Vitesse' | translate }}</div>
      <div class="titre-2">{{ 'Rapport.Vitesse.Description' | translate }}</div>
    </div>
    <div class="col-md-5 col-xs-12 graph" *ngIf="speedValue != null">
      <ngx-gauge
        class="semi-circle gauge"
        [value]="speedValue"
        type="semi"
        append="m/s"
        max="2"
        size="150"
        thick="10"
        cap="round"
        foregroundColor="rgba(250,16,119,1)"
        backgroundColor="rgba(182,213,230,1)"
      >
        <ngx-gauge-value>{{ speedValue | float: 1 }} </ngx-gauge-value>
      </ngx-gauge>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-1 col-xs-12 icon-container">
      <img src="assets/images/images/Symetrie2x.png" alt="" width="30%" height="30%" />
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="titre-1" id="symetrie">{{ 'Rapport.Symetrie' | translate }}</div>
      <div class="titre-2" id="symetrieDescription">{{ 'Rapport.Symetrie.Description' | translate }}</div>
    </div>
    <div class="col-md-5 col-xs-12 graph">
      <div id="gauge2" style="display: inline-block">
        <app-gauge-chart
          canvasWidth="200"
          [needleValue]="symetryNeedle"
          [options]="symetryoptions"
          [bottomLabel]="symetryLabel | float: 1"
        ></app-gauge-chart>
      </div>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-1 col-xs-12 icon-container">
      <img src="assets/images/images/Analyse2x.png" alt="" width="30%" height="30%" />
    </div>
    <div class="col-md-6 col-xs-12">
      <div class="titre-1">{{ 'Rapport.AnalyseDetailMarche' | translate }}</div>
      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.vigueur' | translate }}
        <span> ({{ (semioDataForAge.vigor | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.vigueur.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.fluidite' | translate }}
        <span> ({{ (semioDataForAge.fluidity | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.fluidite.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.stabilite' | translate }}
        <span> ({{ (semioDataForAge.stability | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.stabilite.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.regularite' | translate }}
        <span> ({{ (semioDataForAge.regularity | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.regularite.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.symetrie' | translate }}
        <span> ({{ (semioDataForAge.symmetry | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.symetrie.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.Synchronisation' | translate }}
        <span> ({{ (semioDataForAge.synchronization | float: 1) || ('UndefinedValue' | translate) }})</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.Synchronisation.desc' | translate }}</p>

      <div class="titre-3">
        {{ 'Rapport.AnalyseDetailMarche.Rythme' | translate }}
        <span> ({{ (semioDataForAge.pace | float: 1) || ('UndefinedValue' | translate) }} )</span>
      </div>
      <p>{{ 'Rapport.AnalyseDetailMarche.Rythme.desc' | translate }}</p>
    </div>

    <div class="col-md-5 col-xs-12 radarChart graph">
      <div>
        <div id="graphesSemio" *ngIf="radarChartData != null">
          <div>
            <img [attr.src]="imageSemio | secure | async" width="100%" height="100%" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="disclaimer" class="row mini-section disclaimer">
    <div class="col-md-12 col-xs-12">{{ 'Rapport.disclaimer' | translate }}</div>
  </div>
</div>
