import { PatientDetail, PractitionerControllerService } from '@abilycare/dal-client';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Compute } from 'src/app/tools/compute';
import { ShoesSelectorValues } from '../../force-platform';
import { AnalysisResults as AnalysisResultsRomberg } from '../../romberg/models/analysis-results.model';
import { AssessmentRomberg } from '../../romberg/models/assessment.model';
import { AnalysisResults as AnalysisResultsWalk } from '../../walk/models/analysis-results.model';
import { Assessment } from '../../walk/models/assessment.model';

@Component({
  selector: 'app-rapport-new',
  templateUrl: './rapport-new.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-new.component.scss'],
})
export class RapportNewComponent implements OnInit, OnDestroy {
  public walkAnalysisResult: AnalysisResultsWalk = null;
  public rombergAnalysisResult: AnalysisResultsRomberg = null;
  public walkAssessements: Assessment = null;
  public rombergAssessments: AssessmentRomberg = null;
  public typeRapport: string;
  public shoesSelectorValues: ShoesSelectorValues;

  public patient: PatientDetail = null;
  public dateConsultation: Date;
  public agePatient: number;

  public currentEstablishmentLogo: string;
  public currentEstablishmentName: string;

  public titleWalk: string = null;
  public titleRomberg: string = null;

  public fromHistory: boolean = false;

  public doubleTask: string = null;

  public typePlate: string = null;

  public openedEyesLeftSupport: number = 0;
  public closedEyesLeftSupport: number = 0;

  private subscriptions: Subscription[] = [];

  constructor(
    private practitionnerService: PractitionerControllerService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private location: Location,
    private router: Router,
  ) {}

  ngOnInit() {
    const obj: any = this.location.getState();
    this.patient = this.dataService.retrievePatient();

    if (obj.fromHistory) this.fromHistory = obj.fromHistory;

    this.walkAnalysisResult = obj.walkAnalysisResult;
    this.rombergAnalysisResult = obj.rombergAnalysisResult;
    this.shoesSelectorValues = obj.shoesSelectorValues;
    this.typeRapport = obj.typeRapport;
    this.walkAssessements = obj.walkAssessments;
    this.rombergAssessments = obj.rombergAssessments;
    this.dateConsultation = obj.dateConsultation;
    this.doubleTask = obj.doubleTask;
    this.typePlate = obj.typePlate;
    this.openedEyesLeftSupport = obj.openedEyesLeftSupport;
    this.closedEyesLeftSupport = obj.closedEyesLeftSupport;

    if (!this.walkAnalysisResult && !this.rombergAnalysisResult) {
      this.router.navigateByUrl('/Patient/' + this.patient.id);
    }

    this.subscriptions.push(
      this.practitionnerService.practitionerControllerMe().subscribe(
        (practitionnerMe) => {
          if (practitionnerMe && practitionnerMe.establishment && practitionnerMe.establishment.logo) {
            this.currentEstablishmentLogo = practitionnerMe.establishment.logo;
          }

          if (practitionnerMe && practitionnerMe.establishment && practitionnerMe.establishment.name) {
            this.currentEstablishmentName = practitionnerMe.establishment.name;
          }
        },
        (err) => {
          console.error('RapportNewComponent : Err-Practitionner-01 ', err);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
        },
      ),
    );

    this.agePatient = Compute.age(new Date(this.patient.birthDate));

    this.titleWalk = this.translateService.instant('Rapport.titre.Marche');
    this.titleRomberg = this.translateService.instant('Rapport.titre.Equilibre');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public returnChoiceReport(): void {
    const obj = {
      walkAnalysisResult: this.walkAnalysisResult,
      shoesSelectorValues: this.shoesSelectorValues,
      typeRapport: this.typeRapport,
      rombergAnalysisResult: this.rombergAnalysisResult,
      walkAssessments: this.walkAssessements,
      rombergAssessments: this.rombergAssessments,
      dateConsultation: this.dateConsultation,
      fromHistory: this.fromHistory,
      doubleTask: this.doubleTask,
      patientId: this.patient.id,
      typePlate: this.typePlate,
      openedEyesLeftSupport: this.openedEyesLeftSupport,
      closedEyesLeftSupport: this.closedEyesLeftSupport,
    };

    this.router.navigateByUrl('/rapport', { state: obj });
  }

  public printPatientReport(): void {
    window.print();
  }
}
