<div class="modal-dialog modal-dialog-centered border-0" role="document">
  <div class="modal-content no-border">
    <div class="modal-header">
      <h2 mat-dialog-title>{{ data.title }}</h2>
      <button type="button" class="close" aria-label="Close" (click)="onNoClick()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <mat-dialog-content>
        <p>{{ data.message }}</p>
      </mat-dialog-content>
    </div>
    <div class="modal-footer d-flex justify-content-around">
      <mat-dialog-actions align="end">
        <button class="btn btn-primary" (click)="onNoClick()">{{ 'understand' | translate }}</button>
        <!-- <button class="btn btn-primary" (click)="onYesClick()">{{ data.yesLabel }}</button>-->
      </mat-dialog-actions>
    </div>
  </div>
</div>
