<app-fried-history-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
></app-fried-history-navbar>
<app-fried-custom-navbar [testName]="testName" [analysisResult]="analyisResult" [testData]="testData">
</app-fried-custom-navbar>
<div class="col-md-12">
  <div class="row">
    <div class="col-md-7 simple-content-component" style="height: 50%;" *ngIf="showdata">
      <div>{{ 'app.romberg.results' | translate }}</div>
      <div class="result-summary">
        <div class="row">
          <div class="col-lg-6" style="border-right: 1px solid #DCE2E7;">
            <div class="row">
              <div class="col-lg-12 content-box">
                <img
                  class="d-inline-block align-top"
                  width="20"
                  height="20"
                  src="assets/images/icons/eye-open.svg"
                  alt=""
                />
                <span style="margin-right: 10px; color:#70757a;text-transform: uppercase;">{{
                  'Romberg.EyesOpen' | translate
                }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYO' | translate }}</span>
              <span class="text value">{{ data.ScoreYO | float: 1 }}</span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesScore }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesScore"
                  [max]="maxEyesScore"
                  [step]="1"
                  [(ngModel)]="data.ScoreYO"
                  [ngClass]="{
                    'assessment-container': !isOffLimitOpenedEyesScore,
                    'assessment-container-offlimit': isOffLimitOpenedEyesScore
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesScore }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYO' | translate }}</span>
              <span class="text value">{{ data.SurfaceYO | float: 1 }} &nbsp;cm<sup>2</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesArea }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesArea"
                  [max]="maxEyesArea"
                  [step]="1"
                  [(ngModel)]="data.SurfaceYO"
                  [ngClass]="{
                    'assessment-container': !isOffLimitOpenedEyesArea,
                    'assessment-container-offlimit': isOffLimitOpenedEyesArea
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesArea }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYO' | translate }}</span>
              <span class="text value">{{ data.SwayDensityYO | float: 1 }}&nbsp;s</span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesSwayDensity }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesSwayDensity"
                  [max]="maxEyesSwayDensity"
                  [step]="1"
                  [(ngModel)]="data.SwayDensityYO"
                  [ngClass]="{
                    'assessment-container': !isOffLimitOpenedEyesSwayDensity,
                    'assessment-container-offlimit': isOffLimitOpenedEyesSwayDensity
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesSwayDensity }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYO' | translate }}</span>
              <span class="text value">{{ data.VarianceLateraleYO | float: 3 }}&nbsp;cm<sup>2</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesLateralVariance }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesLateralVariance"
                  [max]="maxEyesLateralVariance"
                  [step]="0.025"
                  [(ngModel)]="data.VarianceLateraleYO"
                  [ngClass]="{
                    'assessment-container': !isOffLimitOpenedEyesLateralVariance,
                    'assessment-container-offlimit': isOffLimitOpenedEyesLateralVariance
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesLateralVariance }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYO' | translate }}</span>
              <span class="text value">{{ data.SpeedMoyYO | float: 1 }}&nbsp;cm.s<sup>-1</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesAverageSpeed }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesAverageSpeed"
                  [max]="maxEyesAverageSpeed"
                  [step]="1"
                  [(ngModel)]="data.SpeedMoyYO"
                  [ngClass]="{
                    'assessment-container': !isOffLimitOpenedEyesAverageSpeed,
                    'assessment-container-offlimit': isOffLimitOpenedEyesAverageSpeed
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesAverageSpeed }}</span>
              </div>
            </div>
            <div
              class="col-md-12 flex-column assessment-container"
              *ngIf="testData.typePlate == 'Kinvent' && testData.openedEyesLeftSupport"
            >
              <span class="text title">{{ 'Checkup.Romberg.Summary.OpenedprcSupport' | translate }}</span>
              <span class="text value">
                {{ testData.openedEyesLeftSupport | number: '1.0-0' }}% |
                {{ 100 - testData.openedEyesLeftSupport | number: '1.0-0' }}%
              </span>
              <div class="d-flex slider-container custom-slider">
                <!--
                <span class="number">{{'Romberg.appui.Left' | translate}}</span>               
                <mat-slider
                  thumbLabel 
                  tickInterval="20"
                  [disabled]="false"
                  [min]="0"
                  [max]="100"
                  [step]="50"
                  [(ngModel)]="100 - testData.openedEyesLeftSupport"
                ></mat-slider>
                <span class="number">{{'Romberg.appui.Right' | translate}}</span>
              </div>
              <div>
                <span class="number text-align: center">50%</span>
              </div>
              
              <div class="custom-slider">-->
                <ngx-slider
                  [(value)]="minOpenedEyesLeftSupport"
                  [(highValue)]="maxOpenedEyesLeftSupport"
                  [options]="optionsopenedEyesLeftSupport"
                >
                </ngx-slider>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-12 content-box">
                <img
                  class="d-inline-block align-top"
                  width="20"
                  height="20"
                  src="assets/images/icons/eye-close.svg"
                  alt=""
                />
                <span style="margin-right: 10px; color:#70757a; text-transform: uppercase;">{{
                  'Romberg.EyesClosed' | translate
                }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYF' | translate }}</span>
              <span class="text value">{{ data.ScoreYF | float: 1 }}</span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesScore }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesScore"
                  [max]="maxEyesScore"
                  [step]="1"
                  [(ngModel)]="data.ScoreYF"
                  [ngClass]="{
                    'assessment-container': !isOffLimitClosedEyesScore,
                    'assessment-container-offlimit': isOffLimitClosedEyesScore
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesScore }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYF' | translate }}</span>
              <span class="text value">{{ data.SurfaceYF | float: 1 }}&nbsp;cm<sup>2</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesArea }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesArea"
                  [max]="maxEyesArea"
                  [step]="1"
                  [(ngModel)]="data.SurfaceYF"
                  [ngClass]="{
                    'assessment-container': !isOffLimitClosedEyesArea,
                    'assessment-container-offlimit': isOffLimitClosedEyesArea
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesArea }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYF' | translate }}</span>
              <span class="text value">{{ data.SwayDensityYF | float: 1 }}&nbsp;s</span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesSwayDensity }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesSwayDensity"
                  [max]="maxEyesSwayDensity"
                  [step]="1"
                  [(ngModel)]="data.SwayDensityYF"
                  [ngClass]="{
                    'assessment-container': !isOffLimitClosedEyesSwayDensity,
                    'assessment-container-offlimit': isOffLimitClosedEyesSwayDensity
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesSwayDensity }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYF' | translate }}</span>
              <span class="text value">{{ data.VarianceLateraleYF | float: 3 }}&nbsp;cm<sup>2</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesLateralVariance }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesLateralVariance"
                  [max]="maxEyesLateralVariance"
                  [step]="0.025"
                  [(ngModel)]="data.VarianceLateraleYF"
                  [ngClass]="{
                    'assessment-container': !isOffLimitClosedEyesLateralVariance,
                    'assessment-container-offlimit': isOffLimitClosedEyesLateralVariance
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesLateralVariance }}</span>
              </div>
            </div>
            <div class="col-md-12 flex-column assessment-container">
              <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYF' | translate }}</span>
              <span class="text value">{{ data.SpeedMoyYF | float: 1 }}&nbsp;cm.s<sup>-1</sup></span>
              <div class="d-flex slider-container">
                <span class="number">{{ minEyesAverageSpeed }}</span>
                <mat-slider
                  [disabled]="true"
                  [min]="minEyesAverageSpeed"
                  [max]="maxEyesAverageSpeed"
                  [step]="1"
                  [(ngModel)]="data.SpeedMoyYF"
                  [ngClass]="{
                    'assessment-container': !isOffLimitClosedEyesAverageSpeed,
                    'assessment-container-offlimit': isOffLimitClosedEyesAverageSpeed
                  }"
                ></mat-slider>
                <span class="number">{{ maxEyesAverageSpeed }}</span>
              </div>
            </div>
            <div
              class="col-md-12 flex-column assessment-container"
              *ngIf="testData.typePlate == 'Kinvent' && testData.closedEyesLeftSupport"
            >
              <span class="text title">{{ 'Checkup.Romberg.Summary.ClosedprcSupport' | translate }}</span>
              <span class="text value">
                {{ testData.closedEyesLeftSupport | number: '1.0-0' }}% |
                {{ 100 - testData.closedEyesLeftSupport | number: '1.0-0' }}%
              </span>
              <div class="d-flex slider-container custom-slider">
                <ngx-slider
                  [(value)]="minClosedEyesLeftSupport"
                  [(highValue)]="maxClosedEyesLeftSupport"
                  [options]="optionsclosedEyesLeftSupport"
                >
                </ngx-slider>
                <!--
                <span class="number">{{'Romberg.appui.Left' | translate}}</span>
                <mat-slider
                  [thumbLabel]="true" 
                  [disabled]="true"
                  [min]="0"
                  [max]="100"
                  [step]="1"
                  [(ngModel)]="100 - testData.closedEyesLeftSupport"
                ></mat-slider>
                <span class="number">{{'Romberg.appui.Right' | translate}}</span>
-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div *ngIf="error" class="col-md-12 alert alert-danger">
        <div>{{ 'App.comparison.alert' | translate }}</div>
      </div>
      <div class="graphics simple-content-component flex-column">
        <span style="text-align: center; color: #1e62d6; text-transform: uppercase;">
          {{ 'App.comparison' | translate }}
        </span>
        <select [ngModel]="rombergToCompare" (ngModelChange)="setRombergToCompare($event)">
          <ng-container *ngFor="let rombergObj of listTestRombergs">
            <ng-container *ngIf="rombergObj.id !== testData.id">
              <option [ngValue]="rombergObj">{{ rombergObj.date | localdate: 'datetime' }}</option>
            </ng-container>
          </ng-container>
        </select>
      </div>
      <div class="graphics simple-content-component flex-column">
        <img
          class="d-inline-block align-top pointer"
          (click)="openGraphModal()"
          data-target="#rombergGraphModal"
          data-toggle="modal"
          src="assets/images/images/graphique.svg"
          alt=""
        />
        <button
          (click)="openGraphModal()"
          data-target="#rombergGraphModal"
          data-toggle="modal"
          class="btn btn-tertiary"
        >
          {{ 'app.walk.access.graphic' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Graph model romberg -->
<div
  class="modal fade"
  id="rombergGraphModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergGraphModalTitle"
  aria-hidden="true"
  #rombergGraphModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" style="max-width: 1500px;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergGraphModalTitle">
          {{ graphTitle }}
        </h5>
        <button #btnCloseGraphModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="romberg-graph">
          <div class="statokinesigram" *ngIf="isStatokinesigram">
            <div class="row">
              <ng-template #zoomstatokinesigram>
                <div class="zoom" (click)="closeChart()">
                  <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" alt="" />
                  <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" alt="" />
                </div>
              </ng-template>
              <div *ngIf="!zoom; else zoomstatokinesigram" class="col-lg-10 offset-lg-1" style="text-align : center;">
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      (click)="changeToStandard()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="standardGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }}
                    </button>
                    <button
                      (click)="changeToAsymetrie()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="asymetrieGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" (click)="zoomGraph()" style="cursor: zoom-in;">
                    <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" height="400px;" alt="" />
                    <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" height="400px;" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 210px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="stabilogram" *ngIf="isStabilogram">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('statokinesigram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
              <ng-template #zoomstabilogram>
                <div class="zoom" (click)="closeChart()">
                  <img [attr.src]="data.stabilo | secure | async" alt="" />
                </div>
              </ng-template>
              <div
                *ngIf="!zoom; else zoomstabilogram"
                (click)="zoomGraph()"
                class="col-lg-10"
                style="text-align : center; cursor: zoom-in;"
              >
                <img [attr.src]="data.stabilo | secure | async" height="400px;" alt="" />
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('comparison')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="comparison" *ngIf="isComparison">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <meta charset="utf-8" />
                  <!--
                  <div class="optionsBar row">
                    <button
                      *ngFor="let title of typesOfComparisons"
                      (click)="onSelectGraphComparaison(title)"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="title === choiceOfComparison"
                    >
                      {{ title | translate }}
                    </button>
                  </div>
                  -->
                  <div *ngIf="recupComparaison" class="test">
                    <ng-template #zoompart>
                      <div class="zoom" (click)="closeChart()">
                        <div style="width: 90%; margin: 0 auto;">
                          <app-graph-horizontal
                            [title]="comparisonGraphSelected.title"
                            [data]="comparisonGraphSelected"
                          ></app-graph-horizontal>
                        </div>
                      </div>
                    </ng-template>

                    <div *ngIf="!zoom; else zoompart" class="setOfGraph">
                      <div
                        *ngFor="let data of comparisonDatas; let i = index"
                        class="graphic2"
                        (click)="comparisonChartClicked(data)"
                      >
                        <app-graph-horizontal [title]="data.title" [data]="data"></app-graph-horizontal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalO')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="longitudinal" *ngIf="isLongitudinalO">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <div *ngIf="!zoom" class="setOfGraph">
                    <div *ngFor="let graph of graphs; let i = index" class="graphic2" (click)="chartClicked(graph)">
                      <app-graph-timeline [title]="graph.title" [data]="graph"> </app-graph-timeline>
                    </div>
                  </div>
                  <div class="zoom" *ngIf="zoom" (click)="closeChart()">
                    <div style="width: 90%; margin: 0 auto;">
                      <app-graph-timeline [title]="graphSelected.title" [data]="graphSelected"> </app-graph-timeline>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalC')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="longitudinal" *ngIf="isLongitudinalC">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalO')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 100px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <div *ngIf="!zoom" class="setOfGraph">
                    <div
                      *ngFor="let graph of graphsRombYF; let i = index"
                      class="graphic2"
                      (click)="chartClicked(graph)"
                    >
                      <app-graph-timeline [title]="graph.title" [data]="graph"> </app-graph-timeline>
                    </div>
                  </div>
                  <div class="zoom" *ngIf="zoom" (click)="closeChart()">
                    <div style="width: 90%; margin: 0 auto;">
                      <app-graph-timeline [title]="graphSelected.title" [data]="graphSelected"> </app-graph-timeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="closeGraphModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
