import {
  Course,
  CourseControllerService,
  NewQuestionnaireReply,
  PatientDetail,
  QuestionnaireDetailWithRelations,
  QuestionnaireQuestion,
  QuestionnaireReplyControllerService,
  UpdatePatient,
} from '@abilycare/dal-client';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatHorizontalStepper } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { flatMap, mergeMap } from 'rxjs/operators';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';

@Component({
  selector: 'app-questionnary-steps',
  templateUrl: './questionnary-steps.component.html',
  // styleUrls: ['./questionnary-steps.component.scss', '../../shared/scss/common.scss'],
  styleUrls: ['./questionnary-steps.component.scss'],
})
export class QuestionnaryStepsComponent implements OnInit, OnDestroy {
  @Output() public stopQuestionnary: EventEmitter<void> = new EventEmitter();
  @Output() public validateQuestionnary: EventEmitter<number> = new EventEmitter();
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();
  @Input() public questionnaryDetails: QuestionnaireDetailWithRelations;
  @Input() public course: Course;

  @ViewChild('btnBack', { static: false }) btnBack: ElementRef;
  @ViewChild('btnNext', { static: false }) btnNext: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;

  public isLinear: boolean = true;
  public maxDate: Date = new Date();
  public displayBack: boolean = false;
  public isLastStep: boolean = false;
  public canDisplayScore: boolean = false;
  public canClickAfterScore: boolean = false;
  public questionParent: number;
  public answerParent: number;
  public currentQuestion: QuestionnaireQuestion;

  public firstFormGroup: FormGroup;
  public reply: NewQuestionnaireReply;
  public response: Array<any>;

  public textValue: string = null;
  public numberValue: number = null;
  public dateValue: string = null;
  public radioValue: number = null;
  public choicesValue: Array<number> = new Array();
  public mapChoicesValueMultiple: Map<number, Array<number>> = new Map();

  public currentStepIndex: number = 0;
  public disabledNextButton: boolean = true;
  public lastQuestionAnswered: boolean = false;

  public scores: Array<number> = new Array<number>();
  public patient: PatientDetail;

  private subscriptions: Subscription[] = [];

  public scoreDefinition: Array<{ name: any; score: any; description: string }> = new Array();

  public isLoaded: boolean = false;
  public isInvalid: boolean = false;

  public checkboxQuestionList: Array<{
    index: number;
    stepIndex: number;
    checked: boolean;
    text: string;
  }> = new Array();

  public checkboxQuestionListToDisplay = [];

  private errorLogged = false;

  constructor(
    private questionnaryReplyService: QuestionnaireReplyControllerService,
    private dataService: DataProviderService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private router: Router,
    private courseDataService: CourseControllerService,
    private patientDataService: PatientDataService,
  ) {}

  ngOnInit() {
    this.eventTrackingService.init();
    this.patient = this.dataService.retrievePatient();
    this.canDisplayScore =
      this.questionnaryDetails.scoreDefinitions && this.questionnaryDetails.scoreDefinitions.length > 0;

    if (this.canDisplayScore) {
      this.questionnaryDetails.scoreDefinitions.forEach((score) => {
        this.scoreDefinition.push({ name: score.name, score: null, description: score.description });
      });
    }
    if (this.questionnaryDetails.questions.length === 1) this.isLastStep = true;
    else this.isLastStep = false;
    this.displayBack = false;

    this.response = new Array(this.questionnaryDetails.questions.length);

    this.currentQuestion = this.questionnaryDetails.questions[0];
    if (this.currentQuestion.optional) {
      this.disabledNextButton = false;
      this.putNullValuesForOptionnel(0);
    }

    //console.log('this.questionnaryDetails.questions is ', this.questionnaryDetails.questions);

    this.questionnaryDetails.questions.forEach((question, indexQuestion) => {
      let checkboxQuestionListPerIndex: Array<{
        index: number;
        stepIndex: number;
        checked: boolean;
        text: string;
      }> = new Array();
      if (question.type === 'multiplechoice') {
        question.answers.forEach((answer, indexAnswer) => {
          const element = {
            index: indexAnswer,
            stepIndex: indexQuestion,
            checked: false,
            text: answer.text,
          };
          checkboxQuestionListPerIndex.push(element);
          this.checkboxQuestionList.push(element);
        });
      }
      this.checkboxQuestionListToDisplay.push(checkboxQuestionListPerIndex);
    });

    this.questionnaryReplyService
      .questionnaireReplyControllerFindByPatientId(this.patient.id)
      .pipe(
        mergeMap((patientReplyListQuestionnary: any) => {
          const result = patientReplyListQuestionnary.filter(
            (questionnary) => questionnary.questionnaireId === this.questionnaryDetails.id,
          );
          if (result && result.length > 0) {
            const idLastQuestionnary = result[result.length - 1].id;
            return this.questionnaryReplyService.questionnaireReplyControllerFindById(idLastQuestionnary);
          } else {
            this.isLoaded = true;
            return new Observable<any>();
          }
        }),
      )
      .subscribe((reply) => {
        if (reply) {
          this.response = reply.answers;
          this.putSavedValues();
        }
        this.isLoaded = true;
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    this.scoreDefinition = null;
  }

  // On click on navigator button (before reload or quit), we check if test is started and display message
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event: Event): boolean {
    // this.btnOpenQuit.nativeElement.click();
    return false;
  }

  // Call when we change step from mat-horizontal-stepper
  public onStepChange(event: StepperSelectionEvent): void {
    this.numberValue = null;
    this.textValue = '';
    this.dateValue = null;
    this.radioValue = null;
    this.disabledNextButton = true;
    this.isInvalid = false;
    this.currentQuestion = this.questionnaryDetails.questions[event.selectedIndex];
    if (this.currentQuestion && this.currentQuestion.optional) this.disabledNextButton = false;

    this.currentStepIndex = event.selectedIndex;
    if (event.selectedIndex === 0) {
      this.displayBack = false;
    } else {
      this.displayBack = true;
    }

    const questionnaryLength = this.questionnaryDetails.questions.length - 1;
    if (event.selectedIndex === questionnaryLength) {
      this.isLastStep = true;
    } else {
      this.isLastStep = false;
    }
    this.putSavedValues();
  }

  putNullValuesForOptionnel(index) {
    const question = this.questionnaryDetails.questions[index];
    if (question) {
      const questionType = question.type;
      if (questionType === 'integer' || questionType === 'float') {
        this.response[index] = { numberValue: null };
      } else if (questionType === 'date') {
        this.response[index] = { dateValue: null };
      } else if (questionType === 'text') {
        this.response[index] = { textValue: null };
      } else if (questionType === 'singlechoice') {
        this.response[index] = { choiceValues: [] };
      } else if (questionType === 'multiplechoice') {
        this.response[index] = { choiceValues: [] };
      }
    }
    if (index == this.questionnaryDetails.questions.length - 2) {
      this.lastQuestionAnswered = question.optional;
      // this.isLastStep = true;
    }
  }

  // Put save values for the current Step
  putSavedValues() {
    if (this.response[this.currentStepIndex]) {
      const questionType = this.questionnaryDetails.questions[this.currentStepIndex].type;
      const key = Object.keys(this.response[this.currentStepIndex])[0];
      if (key === 'numberValue') {
        this.numberValue = this.response[this.currentStepIndex].numberValue;
      } else if (key === 'dateValue') {
        const dateSaved = this.response[this.currentStepIndex].dateValue;
        //if (new Date(dateSaved).getFullYear() > 1920)
        this.dateValue = dateSaved;
      } else if (key === 'textValue') {
        this.textValue = this.response[this.currentStepIndex].textValue;
      } else if (key === 'choiceValues' && questionType === 'singlechoice') {
        this.radioValue = this.response[this.currentStepIndex].choiceValues[0];
      } else if (key === 'choiceValues' && questionType === 'multiplechoice') {
        //remettre choicesValue à vide
        // Verification of checkbox and radio buttons states
        this.choicesValue = [];
        //console.log("put saved values : this.choicesValue 1 : ", this.choicesValue);
        this.checkboxQuestionListToDisplay[this.currentStepIndex].forEach((checkbox) => {
          this.response[this.currentStepIndex].choiceValues.forEach((choice) => {
            if (checkbox.stepIndex === this.currentStepIndex && choice === checkbox.index) {
              checkbox.checked = true;
              //console.log("put saved values : this.choicesValue 2 : ", this.choicesValue);
              this.choicesValue.push(checkbox.index);
            }
          });
        });
        //console.log("put saved values : this.choicesValue end : ", this.choicesValue);
        this.response[this.currentStepIndex] = { choiceValues: this.choicesValue };
        this.mapChoicesValueMultiple.set(this.currentStepIndex, this.choicesValue);
      }
      this.disabledNextButton = false;
      this.lastQuestionAnswered = this.currentStepIndex === this.questionnaryDetails.questions.length - 1;
    } else {
      this.putNullValuesForOptionnel(this.currentStepIndex);
    }
  }

  // Set focus on input type number, date and text
  public setFocus() {
    if (this.questionnaryDetails.questions[this.currentStepIndex]) {
      const questionType = this.questionnaryDetails.questions[this.currentStepIndex].type;
      if (questionType === 'integer' || questionType === 'float') {
        document.getElementById('number' + this.currentStepIndex).focus();
      } else if (questionType === 'date') {
        document.getElementById('date' + this.currentStepIndex).focus();
      } else if (questionType === 'text') {
        document.getElementById('text' + this.currentStepIndex).focus();
      }
    }
  }

  numericOnly(event): boolean {
    // restrict e,+,-,E characters in  input type number
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode == 101 || charCode == 69 || charCode == 45 || charCode == 43) {
      return false;
    }
    return true;
  }

  public onNumberValueChange(type: string, min: number, max: number) {
    this.isInvalid = false;
    if (min && this.numberValue < min) this.isInvalid = true;
    if (max && this.numberValue > max) this.isInvalid = true;

    // check if is an Integer  && this.numberValue % 1 !== 0
    if (type === 'integer' && !this.isInvalid && isNaN(this.numberValue)) {
      this.isInvalid = true;
    }
    if (this.isInvalid && !this.currentQuestion.optional) {
      this.disabledNextButton = true;
    } else {
      if (!isNaN(this.numberValue) || this.currentQuestion.optional) {
        this.disabledNextButton = false;
      } else this.disabledNextButton = true;
      this.response[this.currentStepIndex] = { numberValue: this.numberValue };
    }
    if (this.currentStepIndex == this.questionnaryDetails.questions.length - 1) {
      this.lastQuestionAnswered = true;
    }
  }

  public onDateValueChange(dateValue: string) {
    this.dateValue = dateValue;
    if (this.dateValue || this.currentQuestion.optional) this.disabledNextButton = false;
    else this.disabledNextButton = true;
    this.response[this.currentStepIndex] = { dateValue: this.dateValue };
    if (this.currentStepIndex == this.questionnaryDetails.questions.length - 1) {
      this.lastQuestionAnswered = true;
    }
  }

  public onTextValueChange(textValue: string) {
    this.textValue = textValue;
    if (textValue || this.currentQuestion.optional) this.disabledNextButton = false;
    else this.disabledNextButton = true;
    this.response[this.currentStepIndex] = { textValue: this.textValue };
    if (this.currentStepIndex == this.questionnaryDetails.questions.length - 1) {
      this.lastQuestionAnswered = true;
    }
  }

  public onChangeStateRadio(index: number): void {
    this.disabledNextButton = false;
    this.response[this.currentStepIndex] = { choiceValues: [index] };
    //console.log('onChangeStateRadio ', this.currentStepIndex, ' lenght ', this.questionnaryDetails.questions.length);
    if (this.currentStepIndex == this.questionnaryDetails.questions.length - 1) {
      this.lastQuestionAnswered = true;
    }
  }

  public onChangeStateCheckbox(isChecked: boolean, index: number) {
    //console.log("onChangeStateCheckbox : this.mapChoicesValueMultiple 0 : ", this.mapChoicesValueMultiple);
    this.choicesValue = this.mapChoicesValueMultiple.get(this.currentStepIndex);
    if (!this.choicesValue) this.choicesValue = [];
    const foundIndex = this.choicesValue.findIndex((i) => i === index);
    //console.log("onChangeStateCheckbox : this.choicesValue 1 : ", this.choicesValue);
    if (isChecked && foundIndex < 0) {
      this.choicesValue.push(index);
      this.disabledNextButton = false;
    } else if (!isChecked && foundIndex > -1) {
      this.choicesValue.splice(foundIndex, 1);
      if (this.choicesValue.length > 0) this.disabledNextButton = false;
    } else {
      //this.choicesValue.splice(this.choicesValue.length, 1);
      if (this.choicesValue.length > 0) this.disabledNextButton = false;
    }
    /*
    this.choicesValue = this.choicesValue.filter(function(e) {
      return !this.choicesValue.includes(e);
    });*/
    //console.log('onChangeStateCheckbox : this.choicesValue end : ', this.choicesValue);
    this.response[this.currentStepIndex] = { choiceValues: this.choicesValue };
    this.mapChoicesValueMultiple.set(this.currentStepIndex, this.choicesValue);
    if (this.currentStepIndex == this.questionnaryDetails.questions.length - 1) {
      this.lastQuestionAnswered = this.choicesValue.length > 0;
    }
  }

  public stopTest(): void {
    this.stopQuestionnary.emit();
    //this.goTo('/Patient/' + this.patient.id);
  }

  public getCriteriaByFamilyIndex(index) {
    const familyIndex = this.questionnaryDetails.questions[index].familyIndex;
    const criteriaByFamilies = this.questionnaryDetails.questionFamiliesCriteria[familyIndex];
    //console.log('criteriaByFamilies for question index is ', criteriaByFamilies);
    //if ()
  }

  public getAnswerValues(index) {
    let AnswerValues = [];
    const questionType = this.questionnaryDetails.questions[index].type;
    if (this.response[index]) {
      if (questionType === 'integer' || questionType === 'float') {
        AnswerValues.push(this.response[index].numberValue);
      } else if (questionType === 'date') {
        AnswerValues.push(this.response[index].dateValue);
      } else if (questionType === 'text') {
        AnswerValues.push(this.response[index].textValue);
      } else if (questionType === 'singlechoice') {
        AnswerValues = this.response[index].choiceValues;
      } else if (questionType === 'multiplechoice') {
        AnswerValues = this.response[index].choiceValues;
      }
    }
    return AnswerValues;
  }

  public answerMatchCriteria(index, criteriaAnswer, indexAnswer) {
    if (criteriaAnswer && criteriaAnswer == 'answerValues') {
      return this.getAnswerValues(index) && this.getAnswerValues(index).some((r) => indexAnswer.includes(r));
    } else if (criteriaAnswer && criteriaAnswer == 'answerNumber') {
      return this.getAnswerValues(index) && this.getAnswerValues(index).length >= indexAnswer[0];
    } else {
      return true;
    }
  }

  public goToNextQuestion(index) {
    // console.log('this.questionnaryDetails.questions.length ', this.questionnaryDetails.questions.length);
    // console.log('index  is ', index);
    if (this.questionnaryDetails.questions.length > index) {
      let nextQuestion = this.questionnaryDetails.questions[index + 1];
      //console.log('nextQuestion is ', nextQuestion);
      if (nextQuestion) {
        const indexParent = nextQuestion.questionParent;
        const criteriaAnswer = nextQuestion.criteriaAnswer;
        if (indexParent != undefined) {
          //get the anwsers for the questionParent
          const indexAnswer = nextQuestion.answerParent;
          //console.log('this.getAnswerValues(index) is ', this.getAnswerValues(index));
          //console.log('indexAnswer is ', indexAnswer);
          if (indexAnswer && !this.answerMatchCriteria(indexParent, criteriaAnswer, indexAnswer)) {
            console.log('I m in skip next question');
            //move to the next Question
            //save the result with null values for this question
            this.putNullValuesForOptionnel(index + 1);
            this.goToNextQuestion(index + 1);
            this.btnNext.nativeElement.click();
          } else {
            console.log('I dont skip next question');
            this.btnNext.nativeElement.click();
          }
        } else {
          this.btnNext.nativeElement.click();
        }
      } else {
        this.btnNext.nativeElement.click();
        this.isLastStep = true;
      }
    }
  }

  public goToBackQuestion(index) {
    if (this.questionnaryDetails.questions.length > index) {
      let backQuestion = this.questionnaryDetails.questions[index];
      //console.log('backQuestion is ', backQuestion);
      if (backQuestion) {
        const indexParent = backQuestion.questionParent;
        //console.log('indexParent is ', indexParent);
        const criteriaAnswer = backQuestion.criteriaAnswer;
        if (indexParent != undefined) {
          //get the anwsers for the questionParent
          const indexAnswer = backQuestion.answerParent;
          if (indexAnswer && !this.answerMatchCriteria(indexParent, criteriaAnswer, indexAnswer)) {
            console.log('I skip back question');
            //skip the next Question
            //this.putNullValuesForOptionnel(index - 1);
            this.btnBack.nativeElement.click();
            this.goToBackQuestion(index - 1);
          } else {
            console.log('I dont skip back question');
            this.btnBack.nativeElement.click();
          }
        } else {
          this.btnBack.nativeElement.click();
        }
      } else {
        this.btnBack.nativeElement.click();
      }
    }
  }

  public goNext(): void {
    this.goToNextQuestion(this.currentStepIndex);
    /*
    //get the next question
    if (this.skipNextQuestion(this.currentStepIndex)) {
     this.btnNext.nativeElement.click();
     this.currentStepIndex = this.currentStepIndex + 1;
    } else {
      this.btnNext.nativeElement.click();
    }
    // this.disabledNextButton = true;*/
  }

  public goBack(): void {
    //console.log('this.currentStepIndex is ', this.currentStepIndex);
    //get the next question
    this.goToBackQuestion(this.currentStepIndex - 1);
    this.disabledNextButton = false;
    this.canClickAfterScore = false;
  }

  public updateLastTestForPatient() {
    const updatePatient: UpdatePatient = {
      id: this.patient.id,
      lasttestdate: new Date(),
      lasttesttype: this.course && this.course.id ? 0 : 1,
    };

    this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
      });
    });
  }

  public async updateStatusAndScore(course: Course) {
    const that = this;
    await this.courseDataService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      //refresh navbar to display score
      //get the course object updated
      /*
      this.courseDataService.courseControllerFindById(course.id).subscribe((courseObj) => {
        that.router.navigateByUrl('/courses/fried', { state: courseObj });
      });*/
    });
  }

  public validate(): void {
    console.log('call validate can take time');
    this.canClickAfterScore = false;
    this.disabledNextButton = false;
    this.reply = {
      date: new Date(),
      answers: this.response,
      culture: this.translateService.currentLang,
      patientId: this.patient.id,
      questionnaireId: this.questionnaryDetails.id,
    };
    if (this.course && this.course.id) this.reply.courseId = this.course.id;
    this.subscriptions.push(
      this.questionnaryReplyService.questionnaireReplyControllerCreate(this.reply).subscribe(
        (reply) => {
          this.eventTrackingService.logDataChangePatient(
            'DATA_CREATED',
            this.patient.id,
            reply.id,
            'QuestionnaireReply',
            {},
            reply,
          );
          //console.log('reply created is ', reply);
          if (this.canDisplayScore) {
            this.scores = reply.scores;
            this.goNext();
            this.canClickAfterScore = true;
            this.scores.forEach((scoreValue, index) => {
              this.scoreDefinition[index].score = parseFloat(scoreValue.toFixed(2));
            });
          } else {
            this.finish();
          }
          this.updateLastTestForPatient();
          if (this.course && this.course.id) {
            this.updateStatusAndScore(this.course);
            //this.router.navigateByUrl('/courses/fried', { state: this.course });
          }
        },
        (error) => {
          console.error('QuestionnaryStepsComponent : Err-Quest-01 ', error);
          const errMsg = this.translateService.instant('Err-Quest-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Quest-01', error);
            this.errorLogged = true;
          }
        },
      ),
    );
  }

  public finish(): void {
    this.validateQuestionnary.emit(this.questionnaryDetails.id);
    if (this.course && this.course.id) {
      this.courseDataService.courseControllerFindById(this.course.id).subscribe((courseObj) => {
        this.router.navigateByUrl('/courses/fried', { state: courseObj });
      });
    }
  }

  goTo(page) {
    this.selectPage.emit(page);
    this.router.navigate([page]);
  }
}
