import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataProviderService } from 'src/app/services/data-provider.service';
import {
  PatientDetail,
  GripControllerService,
  NewGrip,
  Grip,
  Course,
  PatientControllerService,
} from '@abilycare/dal-client';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-grip-view',
  templateUrl: './grip-view.component.html',
  styleUrls: ['./grip-view.component.scss'],
})
export class GripViewComponent implements OnInit {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;

  public grip: Grip;
  public leftHandV1: number;
  public rightHandV1: number;
  public leftHandV2: number;
  public rightHandV2: number;

  public patient: PatientDetail;

  // private state: State = new State();

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');
  public testName: string = i18nKey('navbar.strengthTest');

  public newPage: string = '';

  public testData: any;
  public fromBilan: boolean;
  public fromCourse: boolean;
  public fromHistory: boolean;
  public course: Course;

  constructor(
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
    private gripService: GripControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private location: Location,
    private route: ActivatedRoute,
    private patientService: PatientControllerService,
  ) {}

  ngOnInit() {
    this.dataService.storeHeaderShowed(false);
    this.patient = this.dataService.retrievePatient();
    this.route.queryParams.subscribe((params) => {
      this.fromCourse = params.fromCourse == 1;
    });
    const objState: any = this.location.getState();
    let obj: any;
    if (this.fromCourse) {
      this.course = objState[0];
      obj = objState[1];
      const objReturn = objState[2];
      this.fromHistory = objReturn[1];
      if (objReturn[1]) this.returnTitle = i18nKey('Checkup.navbar.history.back');
      else this.returnTitle = i18nKey('Checkup.navbar.patient.back');
    } else {
      obj = objState;
    }
    this.testData = obj;
    this.patientService.patientControllerFindById(obj.patientId).subscribe((p) => {
      this.patient = p;
    });
    this.leftHandV1 = obj.leftHandV1;
    this.leftHandV2 = obj.leftHandV2;
    this.rightHandV1 = obj.rightHandV1;
    this.rightHandV2 = obj.rightHandV2;

    this.route.queryParams.subscribe((params) => {
      this.fromBilan = params.fromBilan == 1;
    });
  }

  onCloseModal() {
    this.btnClose.nativeElement.click();
  }

  resetModal() {}

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.router.navigate([this.newPage]);
  }
}
