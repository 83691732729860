import { Event, EventControllerService, PatientControllerService, Practitioner } from '@abilycare/dal-client';
import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  providers: [EventTrackingService],
})
export class EventsComponent implements OnInit, OnDestroy {
  @ViewChild('btnCloseFilter', { static: true }) btnCloseFilter: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @Output() changePage: EventEmitter<string> = new EventEmitter<string>();
  @Input() currentPage: string;

  public itemsPerPageLabel: string;

  public listEvents: Array<Event> = new Array<Event>();
  public listEventsInitial: Array<Event> = new Array<Event>();

  public canDelete: boolean = false;
  private subscriptions: Subscription[] = [];

  public allCheckboxChecked: boolean = false;
  public hasFilterApplicated: boolean = false;
  public allCheckboxIndeterminate: boolean = false;
  public checkboxList = [];

  public eventToDelete: Event = null;

  public startDate: Date = null;
  public endDate: Date = null;

  public isModalOpened: boolean = false;
  private unsubscribe = new Subject<void>();

  private errorLogged = false;
  public practitioner: Practitioner;

  public filterValue: string = '';

  constructor(
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    public rolesCheck: RolesCheckService,
    private eventService: EventControllerService,
    private practitionnerService: PractitionerDataService,
    private patientService: PatientControllerService,
  ) {
    this.itemsPerPageLabel = this.translateService.instant('Patient.Profil.back_patient');
  }

  ngOnInit() {
    this.checkboxList = [
      {
        id: 'ERROR',
        name: _('ERROR'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'DATA_CREATED',
        name: _('DATA_CREATED'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'DATA_UPDATED',
        name: _('DATA_UPDATED'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'DATA_DELETED',
        name: _('DATA_DELETED'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'DATA_EXPORT',
        name: _('DATA_EXPORT'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'LOGIN',
        name: _('LOGIN'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 'LOGOUT',
        name: _('LOGOUT'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
    ];
    this.practitionnerService.getPractitionerMe().subscribe((me) => {
      this.practitioner = me;
      this.getAllEventsList();
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public getAllEventsList() {
    const that = this;
    this.eventService.eventControllerFindByPractitioner(this.practitioner.id).subscribe((allEvents) => {
      //console.log('All Events: ', allEvents);
      this.listEvents = allEvents;
      if (that.startDate && that.startDate != undefined) {
        this.listEvents = this.listEvents.filter(function(e) {
          return new Date(e.eventDate) >= new Date(that.startDate);
        });
      }
      if (that.endDate && that.endDate != undefined) {
        this.listEvents = this.listEvents.filter(function(e) {
          return new Date(e.eventDate) <= new Date(that.endDate);
        });
      }
      if (this.filterValue) {
        this.listEvents = this.listEvents.filter((event) =>
          (event.metadata || []).some((meta) =>
            JSON.stringify(meta)
              .toLowerCase()
              .includes(this.filterValue),
          ),
        );
      }
      this.listEventsInitial = allEvents;
    });
  }

  public onSubmit() {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) {
        value.isValidate = true;
      } else {
        value.isValidate = false;
      }
    }
    if (!this.allCheckboxIndeterminate) {
      this.getAllEventsList();
      this.hasFilterApplicated = false;
    } else {
      this.getEventsWithFilter(this.checkboxList);
      this.hasFilterApplicated = true;
    }
    this.btnCloseFilter.nativeElement.click();
    this.isModalOpened = false;
  }

  public getPatientName(patientId) {
    this.patientService.patientControllerFindById(patientId).subscribe((p) => {
      return p.firstName + ' ' + p.lastName;
    });
  }

  public async getEventsWithFilter(tab: any) {
    const arrayListType = [];
    if (tab[0].checked) arrayListType.push('ERROR');
    if (tab[1].checked) arrayListType.push('DATA_CREATED');
    if (tab[2].checked) arrayListType.push('DATA_UPDATED');
    if (tab[3].checked) arrayListType.push('DATA_DELETED');
    if (tab[4].checked) arrayListType.push('DATA_EXPORT');
    if (tab[5].checked) arrayListType.push('LOGIN');
    if (tab[6].checked) arrayListType.push('LOGOUT');

    if (arrayListType.length > 0) {
      this.listEvents = await this.listEventsInitial.filter((obj) => {
        return arrayListType.includes(obj.eventType);
      });
      //console.log("enddate and startdate ", this.startDate, " end ", this.endDate);
      if (this.startDate) {
        this.listEvents = this.listEvents.filter((e) => {
          return new Date(e.eventDate) >= new Date(this.startDate);
        });
      }
      if (this.endDate) {
        this.listEvents = this.listEvents.filter((e) => {
          return new Date(e.eventDate) <= new Date(this.endDate);
        });
      }
      if (this.filterValue) {
        this.listEvents = this.listEvents.filter((event) =>
          (event.metadata || []).some((meta) =>
            JSON.stringify(meta)
              .toLowerCase()
              .includes(this.filterValue),
          ),
        );
      }
    } else {
      this.getAllEventsList();
    }
    this.paginator.pageIndex = await 0;
  }

  public closeModalFilter(): void {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked && !value.isValidate) {
        value.checked = false;
        this.allCheckboxChecked = false;
      } else if (!value.checked && value.isValidate) {
        value.checked = true;
      }
    }
  }
  public listChange(): void {
    let checkedCount = 0;
    // Get total checked items
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) checkedCount++;
    }

    if (checkedCount > 0 && checkedCount < this.checkboxList.length) {
      this.allCheckboxIndeterminate = true;
    } else if (checkedCount === this.checkboxList.length) {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = true;
    } else {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = false;
    }
  }

  public onStartDateChanged(value: any) {
    this.startDate = value;
    this.onSubmit();
    /*
    if (new Date(value) != undefined) {
      this.listEvents = this.listEvents.filter(function(e) {
        return new Date(e.eventDate) >= new Date(value);
      });
    } else {
      this.listEvents = this.listEventsInitial;
    }
    //this.listEventsInitial = this.listEvents;*/
  }

  public onEndDateChanged(value: any) {
    this.endDate = value;
    this.onSubmit();
    /*
    if (new Date(value) != undefined) {
      this.listEvents = this.listEvents.filter(function(e) {
        return new Date(e.eventDate) <= new Date(value);
      });
    } else {
      this.listEvents = this.listEventsInitial;
    }
    //this.listEventsInitial = this.listEvents;*/
  }

  async applyFilter(filterValue: string) {
    this.filterValue = filterValue.trim().toLocaleLowerCase();
    this.onSubmit();
  }

  public allCheckboxChange(): void {
    for (const value of Object.values(this.checkboxList)) {
      value.checked = this.allCheckboxChecked;
    }
  }

  @HostListener('window:load', ['$event'])
  public onChangePage($event) {
    this.changePage.emit();
  }
}
