import { Observable, BehaviorSubject } from 'rxjs';

export class Timer {
  private minutesTime: number = 0;
  private secondsTime: number = 0;
  private totalSecondsTime: number = 0;
  private timer;
  private totalSecondsTime$ = new BehaviorSubject<number>(this.totalSecondsTime);
  public running: boolean = false; // Nouvelle propriété

  private get totalSeconds(): number {
    return this.totalSecondsTime;
  }
  private set totalSeconds(value: number) {
    this.totalSecondsTime = value;
    this.totalSecondsTime$.next(value);
  }

  get minutes(): number {
    return this.minutesTime;
  }
  get minutesString(): string {
    if (this.minutesTime < 10) {
      return '0' + this.minutesTime;
    }
    return this.minutesTime.toString();
  }
  get secondes(): number {
    return this.secondsTime;
  }
  get secondesString(): string {
    if (this.secondsTime < 10) {
      return '0' + this.secondsTime;
    }
    return this.secondsTime.toString();
  }
  get totalSeconds$(): Observable<number> {
    return this.totalSecondsTime$.asObservable();
  }

  public constructor(init?: number) {
    if (!init) {
      init = 0;
    }
    this.totalSeconds = init;
    this.secondsTime = init;
  }
  public start() {
    this.timer = setInterval(() => {
      this.minutesTime = Math.floor(++this.totalSeconds / 60);
      this.secondsTime = this.totalSeconds - this.minutesTime * 60;
    }, 1000);
  }

  public startReverse() {
    if (this.running) return; // Empêche plusieurs démarrages simultanés
    this.running = true;
    this.timer = setInterval(() => {
      this.minutesTime = Math.floor(--this.totalSeconds / 60);
      this.secondsTime = this.totalSeconds - this.minutesTime * 60;
      if (this.secondsTime === 0) {
        this.stop();
      }
    }, 1000);
  }

  public stop() {
    clearInterval(this.timer);
    this.running = false; // Réinitialise l'état
  }

  public reset() {
    this.totalSeconds = this.minutesTime = this.secondsTime = 0;
  }

  public resetReverse(init: number) {
    this.totalSeconds = init;
    this.secondsTime = init;
    this.minutesTime = 0;
  }
}
