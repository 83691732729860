import { Injectable } from '@angular/core';
import { EventControllerService, NewEvent, PractitionerControllerService, Practitioner } from '@abilycare/dal-client';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { DataProviderService } from './data-provider.service';

@Injectable({
  providedIn: 'root',
})
export class EventTrackingService {
  public practitionner: Practitioner;

  constructor(
    private eventService: EventControllerService,
    private practitionnerService: PractitionerControllerService,
    private dataService: DataProviderService,
  ) {}

  /** Initialiser le praticien au démarrage */
  init() {
    this.practitionnerService
      .practitionerControllerMe()
      /*.pipe(
        tap((me) => console.log("Practitioner récupéré lors de l'init des event tracking :", me)
        ),
        catchError((error) => {
          console.error('Erreur lors de la récupération du praticien:', error);
          return of(null);
        }),
      )*/
      .subscribe((me) => (this.practitionner = me));
  }

  /** Fonction pour logguer une erreur */
  async logError(errorCode: string, errorDetails: any) {
    await this.init();
    await console.log('errorDetails ', errorDetails);
    const errorEvent: NewEvent = await {
      eventType: 'ERROR',
      eventDate: new Date(),
      practitionerId: this.practitionner.id,
      patientId: this.dataService.retrievePatient().id,
      details: errorCode,
      metadata: [
        {
          stackTrace: errorDetails.message,
          oldValue: {},
          newValue: {},
          exportFile: '',
        },
      ],
    };
    await console.log("Envoi de l'événement d'erreur :", errorEvent);
    await this.eventService.eventControllerInsert(errorEvent).subscribe({
      next: () => console.log('Erreur loggée avec succès'),
      error: (err) => console.error("Erreur lors de l'envoi de l'erreur", err),
    });
  }

  /** Fonction pour logguer une erreur with a patientID*/
  async logErrorPatient(patientId: number, errorCode: string, errorDetails: any) {
    await this.init();
    const errorEvent: NewEvent = await {
      eventType: 'ERROR',
      eventDate: new Date(),
      practitionerId: this.practitionner.id,
      patientId: patientId,
      details: errorCode,
      metadata: [
        {
          stackTrace: errorDetails.message,
          oldValue: {},
          newValue: {},
          exportFile: '',
        },
      ],
    };
    await console.log("Envoi de l'événement d'erreur :", errorEvent);
    await this.eventService.eventControllerInsert(errorEvent).subscribe({
      next: () => console.log('Erreur loggée avec succès'),
      error: (err) => console.error("Erreur lors de l'envoi de l'erreur", err),
    });
  }

  /** Fonction pour logguer une modification de données */
  logDataChangePatient(
    eventType: string,
    patientId: number,
    entityId: number,
    entityType: string,
    oldValue: any,
    newValue: any,
  ) {
    console.log('call logDataChange');
    const dataChangeEvent: NewEvent = {
      eventType: eventType,
      eventDate: new Date(),
      practitionerId: this.practitionner.id,
      patientId: patientId,
      entityId: entityId,
      entityType: entityType,
      details: `${eventType} on ${entityType} (ID: ${entityId})`,
      metadata: [
        {
          stackTrace: '',
          oldValue: oldValue,
          newValue: newValue,
          exportFile: '',
        },
      ],
    };
    console.log("Envoi de l'événement de ", eventType, ' de données :', dataChangeEvent);
    this.eventService.eventControllerInsert(dataChangeEvent).subscribe({
      next: () => console.log(eventType, ' de données loggée avec succès'),
      error: (err) => console.error("Erreur lors de l'envoi de l'événement ${eventType}", err),
    });
  }

  /** Fonction pour logguer un export de fichier */
  logExportFile(exportFile: any) {
    const exportEvent: NewEvent = {
      eventType: 'DATA_EXPORT',
      eventDate: new Date(),
      practitionerId: this.practitionner.id,
      details: `Evt-Export`,
      metadata: [
        {
          stackTrace: '',
          oldValue: {},
          newValue: {},
          exportFile: '',
        },
      ],
    };
    console.log("Envoi de l'événement d export :", exportEvent);
    this.eventService.eventControllerInsert(exportEvent).subscribe({
      next: () => console.log('Export loggée avec succès'),
      error: (err) => console.error("Erreur lors de l'envoi de l'événement de export", err),
    });
  }

  /** Fonction pour logguer un export de fichier */
  logLoginInOut(eventType: string) {
    const exportEvent: NewEvent = {
      eventType: eventType,
      eventDate: new Date(),
      practitionerId: this.practitionner.id,
      details: eventType,
      metadata: [
        {
          stackTrace: '',
          oldValue: {},
          newValue: {},
          exportFile: '',
        },
      ],
    };
    //console.log("Envoi de l'événement d export :", exportEvent);
    this.eventService.eventControllerInsert(exportEvent).subscribe({
      next: () => console.log('loginout loggée avec succès'),
      error: (err) => console.error("Erreur lors de l'envoi de l'événement de export", err),
    });
  }
}
