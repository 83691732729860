<div class="connexion-global" *ngIf="!plateStateLeft.plateAvailable && !plateStateRight.plateAvailable && !config">
  <ng-container *ngIf="wiiConnected | async">
    <div class="connected">
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
        <img class="d-inline-block align-top" width="200" src="assets/images/images/wiipad_ok.svg" alt="" />
      </div>
      <div style="text-align: center;">
        <span>{{ 'app.weight.boardconnected' | translate }}</span>
        <div *ngIf="wiiState | async as state" style="margin-left: 10px; display:inline;">
          <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/baterie.svg" alt="" />
          {{ state.niveauBatterie | number: '1.0-0' }}%
        </div>
      </div>
    </div>
  </ng-container>

  <!-- Test with false because it start with null -->
  <div class="unconnected" *ngIf="(wiiConnected | async) === false">
    <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
      <img class="d-inline-block align-top" width="200" src="assets/images/images/wiipad_nok.svg" alt="" />
    </div>
    <span>{{ 'app.weight.boardnotconnected' | translate }}</span>

    <!-- <div class="wiisyncbutton">
      <button (click)="isWiiBoardAvailable()" [disabled]="wiiBoardAvailable" class="btn btn-secondary">
        {{ 'wiiBoardSearching' | translate }}
      </button>
      <div *ngIf="loadingConnect">Connexion en cours</div>
    </div> -->
  </div>
</div>
<div class="connexion_checkup" *ngIf="plateStateLeft.plateAvailable || plateStateRight.plateAvailable || config">
  <ng-container>
    <div>
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
        <img class="d-inline-block align-top" width="100" src="assets/images/images/kforcePlate.png" alt="" />
      </div>
      <div class="d-flex">
        <div class="col-md-6 flex-column">
          {{ 'PIED GAUCHE' | translate }} :
          <br />
          <span *ngIf="plateStateLeft.plateConnected; else elseNotConnected" class="connected">
            <img src="/assets/images/icons/bluetooth.svg" />
            {{ 'sensor.connected' | translate }}</span
          >
          {{ plateStateLeft.batteryLevel | number: '1.0-0' }}%
        </div>
        <div class="col-md-6 flex-column">
          {{ 'PIED DROIT' | translate }} :
          <br />
          <span *ngIf="plateStateRight.plateConnected; else elseNotConnected" class="connected">
            <img src="/assets/images/icons/bluetooth.svg" />
            {{ 'sensor.connected' | translate }}</span
          >
          {{ plateStateRight.batteryLevel | number: '1.0-0' }}%
        </div>
      </div>
      <!-- a remettre une fois on intégre la synchro. -->
      <div style="text-align: center; margin-top: 25px; margin-bottom: 25px;">
        <button
          class="btn btn-primary text-align: center"
          type="button"
          disabled
          *ngIf="plateStateRight.plateConnected && plateStateLeft.plateConnected && !synchActivate && !config"
        >
          <span class="spinner-border spinner-border-sm text-align: center" role="status" aria-hidden="true"></span>
          Calibration...
        </button>
        <p
          class="alert alert-warning"
          *ngIf="plateStateRight.plateConnected && plateStateLeft.plateConnected && !synchActivate && !config"
        >
          {{ 'plate.calibarate.alert' | translate }}
        </p>
        <button
          class="btn btn-primary text-align: center"
          type="button"
          *ngIf="plateStateRight.plateConnected && plateStateLeft.plateConnected && synchActivate && !config"
          (click)="recalibrate()"
          [disabled]="testOnProgress"
        >
          {{ 'plate.recalibrate' | translate }}
        </button>
      </div>
    </div>
    <div class="d-flex" *ngIf="config">
      <div class="col-md-6 flex-column">
        <button class="btn btn-primary" (click)="connectKForceDevice('LEFT')">
          {{ 'Connect plate Left' }}
        </button>
        <button class="btn btn-primary" (click)="onForgetBluetoothDeviceButtonClick('LEFT')">
          {{ 'forget Plate Left' }}
        </button>
      </div>
      <div class="col-md-6 flex-column">
        <button class="btn btn-primary" (click)="connectKForceDevice('RIGHT')">
          {{ 'Connect plate Right' }}
        </button>
        <button class="btn btn-primary" (click)="onForgetBluetoothDeviceButtonClick('RIGHT')">
          {{ 'forget Plate Right' }}
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #elseNotConnected>
    <span class="not-connected">
      {{ 'sensor.notconnected' | translate }}
    </span>
  </ng-template>
</div>
