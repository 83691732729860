export class KinventData {
  public plate: string;
  public originalTimeSTampHexa: string;
  public originalTimeSTamp: number;
  public timestamp: number;
  public rearLeft: number;
  public rearRight: number;
  public frontLeft: number;
  public frontRight: number;
  public measureWeight: number;
}
