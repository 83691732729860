export const MIN_HEIGHT_CM: number = 50;
export const MAX_HEIGHT_CM: number = 300;
export const MIN_WEIGHT_KG: number = 5;
export const MAX_WEIGHT_KG: number = 150;
// The minimal value for weigh to save it. If the weight is less than 1kg, we don't save it.
export const MIN_WEIGHT_MEASURED_KG: number = 1;
// Waiting duration before weighing
export const TIME_TO_WEIGHING: number = 5;

// Minimal duration for romberg
export const TIME_TO_ROMBERG: number = 30;
export const WAITING_TIME_AFTER_ROMBERG: number = 2;

// Waiting duration before grip
export const TIME_TO_GRIP: number = 5;

// Waiting duration before walk
export const WAITING_TIME_WALK: number = 6;

// Waiting duration after finish walk
export const WAITING_TIME_AFTER_WALK: number = 3;

export const DISTANCE_TO_LOCOMOTION: number = 10;
