export class RombergSummary {
  public shoesType: number;
  public distance: number;
  public helpStability: number[];
  public StablizationHelp: boolean;
  public ScoreYF: number;
  public ScoreYO: number;
  public SurfaceYF: number;
  public SurfaceYO: number;
  public SwayDensityYF: number;
  public SwayDensityYO: number;
  public VarianceLateraleYF: number;
  public VarianceLateraleYO: number;
  public SpeedMoyYO: number;
  public SpeedMoyYF: number;
  public Duration: number;
  public statsym: string;
  public statok: string;
  public stabilo: string;
  public loco: string;
  public rota: string;
  public semio: string[];
}
