import { InlineObject, PatientBrief, Practitioner } from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { Roles } from 'src/app/modules/core/roles.enum';
import { AuthService } from 'src/app/modules/core/services/authentification/auth.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { Router } from '@angular/router';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';

@Component({
  selector: 'app-patient',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit, OnDestroy {
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(PatientListComponent, { static: true }) patientList: PatientListComponent;
  @ViewChild(PatientDetailComponent, { static: true }) patientDetailComponent: PatientDetailComponent;
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnCloseFilter', { static: true }) btnCloseFilter: ElementRef;
  @ViewChild('btnDeletePatient', { static: true }) btnDeletePatient: ElementRef;
  @ViewChild('btnCloseDeletePatient', { static: true }) btnCloseDeletePatient: ElementRef;
  @ViewChild('btnDelegation', { static: false }) btnDelegation: ElementRef;
  @ViewChild('btnCloseDelegation', { static: true }) btnCloseDelegation: ElementRef;
  @ViewChild('btnNoDelete', { static: true }) btnNoDelete: ElementRef;

  public hasFilterApplicated: boolean = false;
  public canHide: boolean = true;
  public canCreatePatient: boolean = false;
  public submitted: boolean = false;
  public allCheckboxChecked: boolean = false;
  public allCheckboxIndeterminate: boolean = false;
  public isModalOpened: boolean = false;
  public searchText;
  public checkboxList = [];
  public selectedPatient: PatientBrief = null;
  public patientIdDelete: number;

  public patientIdListUnique: Array<number> = new Array<number>();
  public patientIdListMultiple: Array<number> = new Array<number>();
  public patientIdListDelegation: Array<number> = new Array<number>();

  private selectedPractitionnersListTests: Array<any> = new Array<any>();
  private selectedPractitionnersListResults: Array<any> = new Array<any>();
  private deletedPractitionnersListTests: Array<any> = new Array<any>();
  private deletedPractitionnersListResults: Array<any> = new Array<any>();
  private subscriptions: Subscription[] = [];
  public patientToDelete: PatientBrief = null;
  public disableDelegation: boolean = true;
  public practitionerMe: Practitioner;

  public canDisplayDelegation: boolean = this.patientIdListDelegation.length < 0;

  public practitioners: Array<Practitioner> = new Array<Practitioner>();
  public selectedPractitioner: Practitioner;
  public displayPractionnerList: boolean = false;

  private errorLogged = false;

  constructor(
    private dataService: DataProviderService,
    public rolesCheck: RolesCheckService,
    private activatedRoute: ActivatedRoute,
    private patientDataService: PatientDataService,
    private router: Router,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
    private authService: AuthService,
    private practitionerDataService: PractitionerDataService,
  ) {
    this.dataService.storeHeaderShowed(true);
    this.checkboxList = [
      {
        id: 0,
        name: _('Patient.filter.delegated.test'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 1,
        name: _('Patient.filter.delegated.result'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 2,
        name: _('Patient.filter.not.delegated'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 3,
        name: _('Patient.filter.all'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
      {
        id: 4,
        name: _('Patient.filter.referent.parctitioner'),
        disabled: false,
        checked: false,
        labelPosition: 'after',
        isValidated: false,
      },
    ];
  }

  ngOnInit() {
    this.eventTrackingService.init();
    this.subscriptions.push(
      this.practitionerDataService.getPractitionerMe().subscribe((practitioner) => {
        this.practitionerMe = practitioner;
        this.subscriptions.push(
          this.practitionerDataService.getAllPractitioners().subscribe(
            (practitioners) => {
              this.practitioners = practitioners;
              this.practitioners = this.practitioners.filter(function(e) {
                return e.id != practitioner.id;
              });
            },
            (error) => {
              console.error('PatientComponent: Err-Practitionner-01 ', error);
              const errMsg = this.translateService.instant('Err-Practitionner-01');
              this.snackBarService.show(errMsg, 'danger');
              if (!this.errorLogged) {
                this.eventTrackingService.logError('Err-Practitionner-01', error);
                this.errorLogged = true;
              }
            },
          ),
        );
      }),
    );
    this.refresh();
  }

  public refresh(): void {
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe((params) => {
        if (params.get('patientId')) {
          const patientId = parseInt(params.get('patientId').toString(), 10);
          if (!this.allCheckboxIndeterminate) this.patientList.getAllPatients(patientId);
          else this.patientList.getPatientsWithFilter(this.checkboxList, this.selectedPractitioner, patientId);
        } else {
          if (!this.allCheckboxIndeterminate) this.patientList.getAllPatients();
          else this.patientList.getPatientsWithFilter(this.checkboxList, this.selectedPractitioner);
        }
      }),
    );
  }

  public checkCanDelegate(): boolean {
    let canDelegate: boolean = false;
    if (
      this.authService.hasRoleInstant(Roles.delegationChekup) ||
      this.authService.hasRoleInstant(Roles.delegationResult)
    ) {
      if (
        this.authService.hasRoleInstant(Roles.managePatient) ||
        this.authService.hasRoleInstant(Roles.listAllPatient)
      ) {
        canDelegate = true;
      }
    }
    return canDelegate;
  }

  public toggleDisplayDelegation() {
    // Clear patientIdListUnique when we close modal
    if (this.canDisplayDelegation) {
      this.patientIdListUnique = [];
    }
    if (this.patientIdListUnique.length === 0 && !this.canDisplayDelegation) {
      this.patientIdListDelegation = this.patientIdListMultiple;
    }
    this.canDisplayDelegation = !this.canDisplayDelegation;
    this.onRefreshInformation();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public closeSidenav(sidenav): void {
    sidenav.close();
    this.canHide = false;
  }

  public openSidenav(sidenav): void {
    sidenav.open();
    this.canHide = true;
  }

  public createPatient(): void {
    this.canCreatePatient = true;
  }

  public allCheckboxChange(): void {
    for (const value of Object.values(this.checkboxList)) {
      value.checked = this.allCheckboxChecked;
    }
  }

  public listChange(item): void {
    //desactivate my patients if referring practionner is checked or the inverse.
    if (item.id == 2 && item.checked) this.checkboxList[4].checked = false;
    if (item.id == 4 && item.checked) this.checkboxList[2].checked = false;

    let checkedCount = 0;
    // Get total checked items
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) {
        checkedCount++;
        this.displayPractionnerList = value.id == 4;
      } else {
        this.displayPractionnerList = false;
      }
    }

    if (checkedCount > 0 && checkedCount < this.checkboxList.length) {
      this.allCheckboxIndeterminate = true;
    } else if (checkedCount === this.checkboxList.length) {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = true;
    } else {
      this.allCheckboxIndeterminate = false;
      this.allCheckboxChecked = false;
    }
  }

  public onCloseModal(patientCreated: PatientBrief): void {
    //this.btnClose.nativeElement.click();
    if (!this.allCheckboxIndeterminate) this.patientList.getAllPatients(patientCreated.id);
    else this.patientList.getPatientsWithFilter(this.checkboxList, this.selectedPractitioner, patientCreated);
  }

  public onCreateMandaQuest(): void {
    this.btnClose.nativeElement.click();
  }

  public openModal(): void {
    this.isModalOpened = true;
  }

  public closeModal(): void {
    this.isModalOpened = false;
  }

  public closeModalFilter(): void {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked && !value.isValidate) {
        value.checked = false;
        this.allCheckboxChecked = false;
      } else if (!value.checked && value.isValidate) {
        value.checked = true;
      }
    }
  }

  public onSubmit(): void {
    for (const value of Object.values(this.checkboxList)) {
      if (value.checked) {
        value.isValidate = true;
      } else {
        value.isValidate = false;
      }
    }
    console.log('practitionner ', this.selectedPractitioner);
    if (!this.allCheckboxIndeterminate) {
      this.patientList.getAllPatients();
      this.hasFilterApplicated = false;
    } else {
      this.patientList.getPatientsWithFilter(this.checkboxList, this.selectedPractitioner);
      this.hasFilterApplicated = true;
    }
    this.btnCloseFilter.nativeElement.click();
    this.isModalOpened = false;
  }

  public onClickDeletePatient(patient: PatientBrief): void {
    this.patientToDelete = patient;
    this.btnDeletePatient.nativeElement.click();
    setTimeout(() => {
      this.btnNoDelete.nativeElement.focus();
    }, 500);
  }

  public deletePatient(): void {
    this.subscriptions.push(
      this.patientDataService.deletePatient(this.patientToDelete.id).subscribe(
        (res) => {
          if (this.patientToDelete.id === this.patientDetailComponent.patient.id) {
            this.patientDetailComponent.patient = null;
          }
          this.eventTrackingService.logDataChangePatient(
            'DATA_UPDATED',
            this.patientToDelete.id,
            this.patientToDelete.id,
            'Patient',
            this.patientToDelete,
            {},
          );
          this.patientList.getAllPatients();
          this.goTo('/Patient');
          this.btnCloseDeletePatient.nativeElement.click();
          this.snackBarService.show(this.translateService.instant('app.snack.delete.patient.success'), 'success');
        },
        (error) => {
          console.error('PatientComponent: Err-Patient-02 ', error);
          const errMsg = this.translateService.instant('Err-Patient-02');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logError('Err-Patient-02', error);
            this.errorLogged = true;
          }
          this.btnCloseDeletePatient.nativeElement.click();
        },
      ),
    );
  }

  public onRefreshInformation(): void {
    this.refresh();
  }

  public closeModalDeletePatient(): void {
    this.btnCloseDeletePatient.nativeElement.click();
  }

  public onOpenDelegation(patientId: number): void {
    this.patientIdListUnique = new Array<number>();
    this.patientIdListUnique.push(patientId);
    this.patientIdListDelegation = this.patientIdListUnique;
    this.btnDelegation.nativeElement.disabled = false;
    this.btnDelegation.nativeElement.click();
    this.btnDelegation.nativeElement.disabled = this.patientIdListMultiple.length === 0;
  }

  public onPatientDelegateListSelected(patientIdList: Array<number>) {
    this.patientIdListUnique = new Array<number>();
    this.patientIdListMultiple = patientIdList;
  }

  public unSelectpatientIdListMultiple() {
    this.patientIdListMultiple.forEach((patientId, index) => {
      //add uncheck patient
    });
  }

  public onSelectPractitionner(selectedPractitionner: { list: Array<any>; type: string }): void {
    if (selectedPractitionner.type === 'TestDelegation') {
      this.selectedPractitionnersListTests = selectedPractitionner.list;
    } else if (selectedPractitionner.type === 'ResultDelegation') {
      this.selectedPractitionnersListResults = selectedPractitionner.list;
    }
  }

  public onDeletePractitionner(deletedPractitionner: { list: Array<any>; type: string }): void {
    if (deletedPractitionner.type === 'TestDelegation') {
      this.deletedPractitionnersListTests = deletedPractitionner.list;
    } else if (deletedPractitionner.type === 'ResultDelegation') {
      this.deletedPractitionnersListResults = deletedPractitionner.list;
    }
  }

  public delegatePatientMultiple(): void {
    const selectedListIdTests: Array<number> = new Array<number>();
    const selectedListIdResults: Array<number> = new Array<number>();
    const deletedListIdTests: Array<number> = new Array<number>();
    const deletedListIdResults: Array<number> = new Array<number>();

    this.selectedPractitionnersListTests.forEach((practitionner) => {
      selectedListIdTests.push(practitionner.id);
    });

    this.deletedPractitionnersListTests.forEach((practitionner) => {
      deletedListIdTests.push(practitionner.id);
    });

    this.selectedPractitionnersListResults.forEach((practitionner) => {
      selectedListIdResults.push(practitionner.id);
    });

    this.deletedPractitionnersListResults.forEach((practitionner) => {
      deletedListIdResults.push(practitionner.id);
    });

    this.addDelegation(selectedListIdTests, 'TestDelegation');
    this.addDelegation(selectedListIdResults, 'ResultDelegation');

    this.deleteDelegation(deletedListIdTests, 'TestDelegation');
    this.deleteDelegation(deletedListIdResults, 'ResultDelegation');

    this.btnCloseDelegation.nativeElement.click();
  }

  public addDelegation(selectedListId: Array<number>, typeDelegation: InlineObject.TypeEnum): void {
    console.log('typeDelegation ', typeDelegation);
    if (selectedListId.length > 0) {
      const objectDelegate: InlineObject = {
        patientIds: this.patientIdListDelegation,
        type: typeDelegation,
        practitionerIds: selectedListId,
      };
      this.subscriptions.push(
        this.patientDataService.addDelegationPatientsMultiple(objectDelegate).subscribe(
          (res) => {
            if (this.patientList.id) {
              this.patientList.getAllPatients(this.patientList.id);
            }
            console.log('res is ', res);
            res.forEach((element) => {
              this.eventTrackingService.logDataChangePatient(
                'DATA_CREATED',
                this.patientList.id,
                element.id,
                'PatientPractitionerRelation',
                {},
                element,
              );
            });
            this.patientList.getAllPatients();
            this.goTo('/Patient/' + this.patientList.id);
            this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
          },
          (error) => {
            console.error('PatientComponent: Err-Delegation-01 ', error);
            const errMsg = this.translateService.instant('Err-Delegation-01');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logError('Err-Delegation-01', error);
              this.errorLogged = true;
            }
          },
        ),
      );
    }
  }
  public deleteDelegation(deletededListId: Array<number>, typeDelegation: InlineObject.TypeEnum): void {
    if (deletededListId.length > 0) {
      const objectDelegate: InlineObject = {
        patientIds: this.patientIdListDelegation,
        practitionerIds: deletededListId,
        type: typeDelegation,
      };
      this.subscriptions.push(
        this.patientDataService.deleteDelegationPatientsMultiple(objectDelegate).subscribe(
          (res) => {
            if (this.patientList.id) {
              this.patientList.getAllPatients(this.patientList.id);
            }
            for (let i = 0; i < deletededListId.length; i++) {
              const practionnerId = deletededListId[i];
              const delegationObject = {
                patientId: this.patientList.id,
                practionnerId: practionnerId,
                typeDelegation: typeDelegation,
              };
              this.eventTrackingService.logDataChangePatient(
                'DATA_DELETED',
                this.patientList.id,
                practionnerId,
                'PatientPractitionerRelation',
                delegationObject,
                {},
              );
            }
            this.patientList.getAllPatients();
            this.goTo('/Patient/' + this.patientList.id);
            this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
          },
          (error) => {
            console.error('PatientComponent: Err-Delegation-02 ', error);
            const errMsg = this.translateService.instant('Err-Delegation-02');
            this.snackBarService.show(errMsg, 'danger');
            if (!this.errorLogged) {
              this.eventTrackingService.logError('Err-Delegation-02', error);
              this.errorLogged = true;
            }
          },
        ),
      );
    }
  }

  goTo(page) {
    this.selectPage.emit(page);
    this.router.navigate([page]);
  }
}
