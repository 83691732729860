<nav class="navbar navbar-expand-lg sticky-top" *ngIf="isShowed">
  <a class="navbar-brand title text-capitalize" href="/Patient">
    <img src="assets/images/icons/logo.svg" width="30" height="30" class="d-inline-block align-top" alt="" />
    {{ 'App.Title' | translate }}
  </a>

  <div class="navbar-expand-sm" id="navbarContent">
    <ul class="navbar-nav">
      <li class="nav-item" *ngFor="let item of items">
        <a
          class="nav-link text-uppercase item"
          [routerLink]="item.routerLink"
          [routerLinkActive]="['router-link-active']"
          [routerLinkActiveOptions]="{ exact: true }"
          (click)="onLinkClick()"
          >{{ item.title | translate }}</a
        >
      </li>
    </ul>
  </div>

  <div class="navbar-expand-sm" id="navbarPorperties">
    <ul class="navbar-nav dropdown">
      <li class="nav-item active">
        <a class="nav-link" (click)="goTo('/Notifications')">
          <img class="d-inline-block align-top" width="30" height="30" src="{{ notifImg }}" alt=""
        /></a>
      </li>

      <li class="nav-item">
        <a class="nav-link" mat-icon-button [matMenuTriggerFor]="menu">
          <img class="d-inline-block align-top" width="30" height="30" src="assets/images/icons/user-icon.svg" alt=""
        /></a>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="goTo('/Profil')">{{ 'Mon compte' | translate }}</button>
          <button mat-menu-item (click)="goTo('/Settings')">{{ 'my.param' | translate }}</button>
          <button mat-menu-item (click)="goTo('/config')" *ngIf="hasRoleConfigurator">
            {{ 'Configuration' | translate }}
          </button>
          <button mat-menu-item [matMenuTriggerFor]="Apropos">{{ 'App.About' | translate }}</button>
          <button mat-menu-item (click)="onSignOut()">{{ 'logout.confirmation' | translate }}</button>
        </mat-menu>
        <mat-menu #Apropos="matMenu">
          <button mat-menu-item data-toggle="modal" data-target="#noteVersionModal" (click)="showVersionModal()">
            {{ 'App.Version' | translate }}
          </button>
          <button mat-menu-item data-toggle="modal" data-target="#noteMarkCEModal">{{ 'App.Mark' | translate }}</button>
        </mat-menu>
      </li>
    </ul>
  </div>
</nav>
<!-- Modal Version -->
<div
  class="modal fade"
  id="noteVersionModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="noteVersionModalTitle"
  aria-hidden="true"
  #noteVersionModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="noteVersionModalTitle">
          {{ 'App.Version' | translate }}
          &nbsp;N° 5.4.1
        </h5>
        <button
          #btnCloseVersion
          type="button"
          (click)="closeVersionModal()"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>{{ 'App.NewsFct' | translate }}</h4>
        <li>
          Nouveaux Questionnaires:
        </li>
        <ul>
          <li>
            SHORT FES-I
          </li>
        </ul>
        <li>
          Possibilité de personnaliser les questionnaires par établissement
        </li>
        <li>
          Page Bilan - Possibilité d’exporter au format excel les réponses aux questionnaires
        </li>
        <h4>{{ 'App.Upgrade' | translate }}</h4>
        <li>
          Gestion des erreurs: ajout d’un délai avant de lancer des erreurs de connexion Bluetooth
        </li>
        <li>
          Lors des tests d'équilibre et de marche, possibilité d’ajouter de multiple aides à la marche
        </li>
        <li>
          Test d'équilibre:
        </li>
        <ul>
          <li>
            ajout de l’unité de la variance latérale
          </li>
          <li>
            ajout d’un test pour s’assurer que les données font bien 30 secondes minimum
          </li>
          <li>
            l’histogramme du suivi longitudinal est remplacé par un diagramme en point
          </li>
          <li>
            ajout d’un rappel de ne pas monter sur les plates pendant la phase de calibration
          </li>
          <li>
            possibilité de recalibrer
          </li>
        </ul>
        <h4>{{ 'App.Correction' | translate }}</h4>
        <li>
          Page Bilan:
        </li>
        <ul>
          <li>
            correction pour afficher la date du dernier test
          </li>
          <li>
            correction pour la recherche par date
          </li>
        </ul>
      </div>
      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-primary" (click)="closeVersionModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Modal Marquage CE -->
<div
  class="modal fade"
  id="noteMarkCEModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="noteMarkCEModalTitle"
  aria-hidden="true"
  #noteMarkCEModal
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="noteMarkCEModalTitle">
          {{ 'App.Mark' | translate }}
        </h5>
        <button #btnCloseMarkCE type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body modal-container">
        <div class="title">
          <img class="align-center" src="assets/images/icons/logoCE.svg" alt="" />
        </div>
        <div class="text">
          AbilyCare
        </div>
        <div class="value">
          <span>
            1560 RTE DE QUARANTE SOUS 78630 ORGEVAL
          </span>
        </div>
        <div></div>
        <div class="value">
          <span>{{ 'App.Mark.Content1' | translate }}</span>
        </div>
        <div class="text">
          AbilyCare
        </div>
        <div class="value">
          <span>{{ 'App.Mark.Content2' | translate }}</span>
        </div>
      </div>
      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-primary" (click)="closeMarkModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
