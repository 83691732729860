import {
  Course,
  CourseControllerService,
  DoubleTask,
  DoubleTaskControllerService,
  Footwear,
  FootwearControllerService,
  NewWalking,
  PatientDetail,
  PractitionerControllerService,
  QuestionnaireReplyControllerService,
  UpdatePatient,
  WalkingAid,
  WalkingAidControllerService,
  WalkingControllerService,
} from '@abilycare/dal-client';
import { HttpClient } from '@angular/common/http';
import { RemoteInteraction as RemoteInteractionWalk } from 'src/app/modules/walk/services/remote-interaction.service';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, from, from as fromPromise, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, delay, finalize, flatMap, map, retryWhen, tap } from 'rxjs/operators';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { HistoVersusConsultationService } from 'src/app/modules/consultation';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import { Timer } from 'src/app/modules/time';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { AnalysisParameters } from 'src/app/modules/walk/models/analysis-parameters.model';
import { AnalysisResults as AnalysisResultsWalk } from 'src/app/modules/walk/models/analysis-results.model';
import { Assessment } from 'src/app/modules/walk/models/assessment.model';
import { ForcePlatformOrchestrator } from 'src/app/modules/walk/services/orchestrator.service';
import { WalkOperation, WalkOperationService } from 'src/app/modules/walk/services/walk-operation.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';
import { Compute } from 'src/app/tools/compute';
import * as medicalConst from 'src/app/tools/medical.constants';
import { ModalComponent } from 'src/app/view/common/modal/modal.component';
import { AssessmentItem } from './walk-assessment/walk-assessment-item.model';
import { WalkTutorialComponent } from './walk-tutorial/walk-tutorial.component';
import { Location } from '@angular/common';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { InterceptorHttpParams } from 'src/app/modules/core/services/http/interceptor-http-params';
import { WebCamConnexionComponent } from 'src/app/view/webcam-connexion/webcam-connexion.component';

@Component({
  selector: 'app-walk',
  templateUrl: './walk.component.html',
  styleUrls: ['./walk.component.scss'],
  // styleUrls: ['./walk.component.scss', '../shared/scss/common.scss'],
  providers: [ForcePlatformOrchestrator, WalkOperationService, HistoVersusConsultationService],
})
export class WalkComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @ViewChild('btnCloseAppreciation', { static: true }) btnCloseAppreciation: ElementRef;
  @ViewChild('btnOpenAppreciation', { static: false }) btnOpenAppreciation: ElementRef;
  @ViewChildren(ModalComponent) public modals: QueryList<ModalComponent>;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;
  @ViewChild('btnQuitBeforeSave', { static: true }) btnQuitBeforeSave: ElementRef;
  @ViewChild('walkTutorial', { static: true }) walkTutorial: WalkTutorialComponent;
  @ViewChild('webCamMonitor', { static: false }) webCamMonitor: WebCamConnexionComponent;

  private walkProxyBaseUri: string = this.appConfigurationService.walkPlatformBaseUri;

  public patient: PatientDetail;
  public returnTitle: string = i18nKey('Checkup.navbar.patient.back');
  public shoesSelectorValues: ShoesSelectorValues;
  public visualAppreciations: Array<Assessment>;
  public walkAssessments: AssessmentItem[];
  public stepNumber: number;
  public isShoesSelectorOk: boolean = false;
  public areSensorsConnected: boolean = false;
  public isTestAvailable: boolean = false;
  public isTestStarted: boolean = false;
  public isTestStoped: boolean = false;

  public timerWalk: Timer = new Timer(medicalConst.WAITING_TIME_WALK);
  public timerStopWalk: Timer = new Timer(medicalConst.WAITING_TIME_AFTER_WALK);
  public intervalWalk;
  public isPositiveValue: boolean = false;
  public isTestFinish: boolean = false;
  public isAlgorithmFinish: boolean = false;
  public canDisplayAssessment: boolean = false;

  public recording = new BehaviorSubject<boolean>(false);
  public recording$ = this.recording.asObservable();

  public cameraRecording = new BehaviorSubject<boolean>(false);
  public cameraRecording$ = this.recording.asObservable();

  public lang: string;

  public error: boolean = false;
  private retryResponse = new Subject<boolean>();

  private subscriptions: Subscription[] = [];

  public currentWalkRecordingSession;

  public readonly waitAnalyseName = 'WaitAnalyse';
  public waitMessage = new BehaviorSubject<string>('');
  public readonly resultSnackBarName = 'SuccessSnackBar';

  public resultsWalk: AnalysisResultsWalk = null;
  public isWalkValidate: boolean = false;
  public typeRapport: string = 'Walk';
  public testName: string = i18nKey('navbar.walkTest');

  public newPage: string = '';
  public doubleTaskList: DoubleTask[] = [];
  public doubleTask: number;

  public scoreProfil: number = 0;

  private walkingProxyBaseUri: string = this.appConfigurationService.locomotionProxyBaseUri;

  public course: any;
  public currentEstablishmentId: number;
  public cameraConfigured = localStorage.getItem('configuredCameraId') != undefined;
  public hasVideo: boolean = false;
  private errorLogged = false;

  constructor(
    private dataService: DataProviderService,
    private operationService: WalkOperationService,
    private platformOrchestrator: ForcePlatformOrchestrator,
    private translateService: TranslateService,
    private walkingService: WalkingControllerService,
    private router: Router,
    public histoVerusConsultationService: HistoVersusConsultationService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private localDatePipe: LocalDatePipe,
    private walkOrchestrator: ForcePlatformOrchestrator,
    private appConfigurationService: AppConfigurationService,
    private footwearService: FootwearControllerService,
    private walkingAidService: WalkingAidControllerService,
    private httpClient: HttpClient,
    private remoteInteractionWalkService: RemoteInteractionWalk,
    private location: Location,
    private courseDataService: CourseControllerService,
    private patientDataService: PatientDataService,
    private doubleTaskService: DoubleTaskControllerService,
    private questionnaireReplyService: QuestionnaireReplyControllerService,
    private http: HttpClient,
    private practitionnerService: PractitionerControllerService,
  ) {}

  ngOnInit() {
    this.eventTrackingService.init();
    this.course = this.location.getState();
    this.lang = this.translateService.currentLang;

    this.patient = this.dataService.retrievePatient();
    this.shoesSelectorValues = new ShoesSelectorValues();

    this.subscriptions.push(
      this.practitionnerService.practitionerControllerMe().subscribe(
        (practitionnerMe) => {
          if (practitionnerMe && practitionnerMe.establishment && practitionnerMe.establishment.logo) {
            this.currentEstablishmentId = practitionnerMe.establishment.id;
          }
        },
        (err) => {
          console.error('WalkComponement : Err-Practitionner-01 ', err);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Practitionner-01', err);
            this.errorLogged = true;
          }
        },
      ),
    );

    this.doubleTaskService.doubleTaskControllerFind(this.translateService.currentLang).subscribe((res) => {
      this.doubleTaskList = res;
      this.doubleTask = this.doubleTaskList[0].id;
    });

    this.doubleTaskService.doubleTaskControllerFind(this.translateService.currentLang).subscribe((res) => {
      this.doubleTaskList = res;
      this.doubleTask = this.doubleTaskList[0].id;
    });

    const sessionId = this.platformOrchestrator.initSession();
    this.currentWalkRecordingSession = sessionId;
    this.operationService.setCurrentOperation(WalkOperation.Measure);
    this.retryResponse.next(false);

    //getScore profil Light
    this.questionnaireReplyService
      .questionnaireReplyControllerFindQuestionnaryByPatientIdAndQuestId(this.patient.id, 33)
      .subscribe((questReply) => {
        this.scoreProfil = questReply.scores[0];
      });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    if (this.isTestStarted || this.isTestStoped) {
      this.stopTest();
    }
  }

  public setDoubleTask(value: number): void {
    this.doubleTask = value;
  }

  // On click on another page, we check if test is started and display message
  public canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.isTestStarted || this.isTestStoped) {
      this.btnOpenQuit.nativeElement.click();
      return false;
    }
    if (this.isTestFinish || this.isAlgorithmFinish) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }
    return true;
  }

  @HostListener('window:unload', ['$event'])
  public unloadHandler(event): void {
    if (this.isTestStarted || this.isTestStoped) {
      this.stopTest();
    }
  }

  // On click on navigator button (before reload or quit), we check if test is started and display message
  @HostListener('window:beforeunload', ['$event'])
  public beforeUnloadHander(event: Event): boolean {
    if (this.isTestStarted) {
      this.btnOpenQuit.nativeElement.click();
      // this.stopTest();
      return false;
    }
    console.log('beforeUnloadHander this.isTestFinish : ', this.isTestFinish);
    if (this.isTestFinish || this.isAlgorithmFinish) {
      this.btnQuitBeforeSave.nativeElement.click();
      return false;
    }

    return true;
  }

  public onShoesSelectorValuesChange(value: ShoesSelectorValues): void {
    this.shoesSelectorValues = value;
    this.validate();
  }

  // Validation of shoes selector
  private validate(): void {
    if (this.shoesSelectorValues.shoesType !== undefined && this.shoesSelectorValues.walkingAid.length > 0) {
      this.isShoesSelectorOk = true;
      this.checkLaunchTest();
    }
  }

  public resetModal(): void {
    this.walkTutorial.reset();
  }

  private checkLaunchTest(): void {
    //if (true) {
    if (this.areSensorsConnected && this.isShoesSelectorOk) {
      this.isTestAvailable = true;
    } else {
      this.isTestAvailable = false;
      this.isTestFinish = false;
      this.canDisplayAssessment = false;
    }
  }

  // Verify if is possible to start test
  public onCanLaunchTest(sensorsConnected: boolean): void {
    this.areSensorsConnected = sensorsConnected;
    this.checkLaunchTest();
  }

  public onCloseModal(): void {
    this.btnClose.nativeElement.click();
    this.walkTutorial.reset();
    this.btnCloseAppreciation.nativeElement.click();
  }

  public async startTest() {
    if (this.webCamMonitor) await this.webCamMonitor.startRecording();
    this.timerWalk = await new Timer(medicalConst.WAITING_TIME_WALK);
    this.timerStopWalk = await new Timer(medicalConst.WAITING_TIME_AFTER_WALK);
    this.isTestStarted = await true;
    this.isTestFinish = await false;
    this.canDisplayAssessment = await false;
    this.isWalkValidate = await false;
    //start test to integrate 6 secondes before
    this.platformOrchestrator.startRecording(this.currentWalkRecordingSession);
    // Wait 6 seconds before launch test
    this.intervalWalk = await setInterval(() => {
      if (this.timerWalk.secondes === 0) {
        this.isPositiveValue = true;
        this.timerWalk.resetReverse(1);
        this.timerWalk.start();
        clearInterval(this.intervalWalk);
        this.recording.next(true);
      }
    }, 1000);
    await this.timerWalk.startReverse();
  }

  public async stopTest() {
    console.log('call stop Test');
    this.timerStopWalk.resetReverse(medicalConst.WAITING_TIME_AFTER_WALK);
    this.isTestStoped = true;
    this.isPositiveValue = false;
    this.isTestStarted = false;
    this.isTestFinish = true;
    this.timerStopWalk.startReverse();
    let intervalStopWalk = setInterval(async () => {
      if (this.timerStopWalk.secondes === 0) {
        this.timerWalk.stop();
        this.isTestStoped = false;
        this.isTestFinish = true;
        clearInterval(intervalStopWalk);
        try {
          // Step 2: Wait for 3 seconds
          await delay(3000);
          // Step 1: Stop platform recording
          const value = await this.platformOrchestrator.stopRecording(this.currentWalkRecordingSession);
          console.log('Value from stop record', value);
          this.recording.next(false);
          // Step 3: Stop webcam recording
          await this.webCamMonitor.stopRecording(true);
        } catch (error) {
          // Handle errors from either platformOrchestrator or webCamMonitor
          this.errorWithService();
        }
      }
    }, 1000);

    /*
    // Wait 3 seconds before stop test
    let intervalStopWalk = setInterval(() => {
      if (this.timerStopWalk.secondes === 0) {
        this.timerWalk.stop();
        this.isTestStoped = false;
        this.isTestFinish = true;
        clearInterval(intervalStopWalk);
        this.platformOrchestrator
          .stopRecording(this.currentWalkRecordingSession)
          .then(
            (value) => {
              console.log('Value from stop record', value);
              this.recording.next(false);
            },
            (error) => {
              this.errorWithService();
            },
          )
          .catch((error) => this.errorWithService());
        }
    }, 1000);
    await this.webCamMonitor.stopRecording();*/
  }

  private errorWithService(): void {
    console.log('ERRROR SERVICE');
  }

  private showModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.show());
  }
  private hideModal(modalName: string) {
    this.modals.filter((e) => e.name === modalName).forEach((e) => e.hide());
  }

  // This method is call when we click on validate appreciation
  public onValidate(appreciations): void {
    this.visualAppreciations = appreciations;
    this.recording.next(true);
    this.btnCloseAppreciation.nativeElement.click();

    // this.translateService
    //   .get('Checkup.Walk.Measure.InProgress.Modal.ContentTransfer')
    //   .subscribe((text) => this.waitMessage.next(text));
    if (!this.isAlgorithmFinish) {
      this.showModal(this.waitAnalyseName);
      let startPushMeasure = performance.now();
      let startAnalysis = performance.now();
      let startPushVideo = performance.now();
      console.log(`[onValidate - pushMeasure] Started at: ${new Date().toISOString()}`);
      this.subscriptions.push(
        this.pushMeasure()
          .pipe(
            retryWhen((error) => {
              return this.askRetry(error);
            }),
            tap(() => {
              const durationPushMeasure = performance.now() - startPushMeasure;
              console.log(`[onValidate - pushMeasure] Duration: ${durationPushMeasure.toFixed(2)} ms`);
            }),
            tap(() => {
              startAnalysis = performance.now();
              console.log(`[onValidate - analysis] Started at: ${new Date().toISOString()}`);
            }),
            flatMap(() => {
              return this.waitForAnalysisResults().pipe();
            }),
            tap(() => {
              const analysisDuration = performance.now() - startAnalysis;
              console.log(`[onValidate - analysis] Duration: ${analysisDuration.toFixed(2)} ms`);
            }),
            flatMap(() => {
              startPushVideo = performance.now();
              return this.pushVideo().pipe();
            }),
            tap(() => {
              const videoDuration = performance.now() - startPushVideo;
              console.log(`[onValidate - pushVideo] Duration: ${videoDuration.toFixed(2)} ms`);
            }),
          )
          .subscribe(
            () => {
              const totalDuration = performance.now() - startPushMeasure;
              console.log(`[onValidate - pushMeasure & Analysis] Total Duration: ${totalDuration.toFixed(2)} ms`);
              this.canDisplayAssessment = true;
              this.isAlgorithmFinish = true;
              this.hideModal(this.waitAnalyseName);
              this.snackBarService.show(this.translateService.instant('app.test.mesure.success'), 'success');
            },
            (error) => {
              const totalDuration = performance.now() - startPushMeasure;
              console.error(
                `WalkComponent Error: ${error.message} - Total Duration with error : ${totalDuration.toFixed(2)} ms`,
                error,
              );

              this.hideModal(this.waitAnalyseName);
              const errMsg = this.translateService.instant(error.message);
              this.snackBarService.show(errMsg, 'danger');
              if (!this.errorLogged) {
                this.eventTrackingService.logErrorPatient(this.patient.id, error.message, error);
                this.errorLogged = true;
              }
            },
          ),
      );
    }
  }

  //save info acquisition to server
  public writeInfoAcquisitionToFile(currentRecordingSessionId: string, idPatient: number) {
    const todayDate = this.localDatePipe.parse(new Date(), 'dd_MM_yyyyHH:mm:ss');
    const fileContent = 'id patient : ' + idPatient + '\nDate : ' + todayDate + '\nguid : ' + currentRecordingSessionId;
    const filename = 'WalkAcquisition_Info_' + idPatient + '_' + todayDate;
    const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8;' });

    // Ici, vous pourriez envoyer le Blob à un serveur par exemple
    const formData = new FormData();
    formData.append('file', blob, filename + '.txt');

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    //save file to static url
    /*
    let url: string;
    url = this.walkProxyBaseUri + '/rawFiles/UploadRawFiles';
    url += '?guid=' + currentRecordingSessionId;
    url += '&filename=' + filename;
    const body: any = blob;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post(url, blob, { params });*/
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public pushVideo(): Observable<void> {
    if (this.webCamMonitor && this.webCamMonitor.recordCamera) {
      try {
        const dateShared = new Date().toISOString();
        const guid = this.currentWalkRecordingSession;
        return from(this.webCamMonitor.SaveRecording(guid, dateShared)).pipe(
          map((result) => {
            console.log('result push video is ', result);
            if (result != false) {
              console.log('Enregistrement réussi.');
              this.hasVideo = true;
            } else {
              console.log("Échec de l'enregistrement.");
              this.hasVideo = false;
              console.error('WalkComponent : Err-Walking-03 ');
              const errMsg = this.translateService.instant('Err-Walking-03');
              this.snackBarService.show(errMsg, 'warning');
            }
          }),
          catchError((error) => {
            console.error('WalkComponent : Err-Walking-03 ', error);
            const errMsg = this.translateService.instant('Err-Walking-03');
            this.snackBarService.show(errMsg, 'warning');
            return of(void 0); // Retourner un Observable "vide" en cas d'erreur
          }),
          // Continue l'exécution même après l'erreur
          finalize(() => {
            console.log('Exécution de pushVideo terminée, avec ou sans erreur.');
          }),
        );
      } catch (error) {
        console.log('WalkComponent : Err-Walking-03 ', error);
        const errMsg = this.translateService.instant('Err-Walking-03');
        this.snackBarService.show(errMsg, 'warning');
        return of(void 0); // Retourne un Observable "vide" en cas d'erreur
      }
    } else {
      console.log('The video will not be saved');
      return of(void 0); // Retourne un Observable "vide" si aucune action n'est nécessaire
    }
  }

  private pushMeasure(): Observable<void> {
    /* a enlever pour l'établissement 25
    if (this.currentEstablishmentId == 25) {
      //save data in localhost for estabalishment 25
      this.writeInfoAcquisitionToFile(this.currentWalkRecordingSession, this.patient.id);
    }*/
    // const guid = LocalStorageEx.currentWalkRecordingSession;
    const guid = this.currentWalkRecordingSession;
    try {
      return this.platformOrchestrator.pushMeasure(guid);
    } catch (error) {
      console.log('WalkComponent : Err-Walking-02 ', error);
      const errMsg = this.translateService.instant('Err-Walking-02');
      this.snackBarService.show(errMsg, 'danger');
      if (!this.errorLogged) {
        this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Walking-02', error);
        this.errorLogged = true;
      }
      return;
    }
  }

  private waitForAnalysisResults(): Observable<AnalysisResultsWalk> {
    const guid = this.currentWalkRecordingSession;
    const parameters = this.buildAnalysisParameters();
    console.log('Parameters Locomotion', parameters);

    // this.translateService
    //   .get('Checkup.Walk.Measure.InProgress.Modal.ContentAnalyses')
    //   .subscribe((text) => this.waitMessage.next(text));

    // Get result to get the error when fail.
    return fromPromise(
      this.platformOrchestrator
        .startDataAnalysis(guid, parameters)
        .then(() => this.platformOrchestrator.getAnalysisResultsForSession(guid)),
    );
  }

  private askRetry(errors: Observable<any>): Observable<any> {
    // the delay is to handle time to display and hide box
    // (from previous try)
    console.error('askRetry');

    return errors.pipe(
      delay(1000),
      flatMap((err) => {
        console.error('askRetry', err);
        this.error = true;
        // this.btnCloseCalculationModal.nativeElement.click();
        /*this.logger.info('Retry send show box ');
        this.hideModal(this.waitAnalyseName);
        this.showModal(this.retrySendBoxName);*/
        this.hideModal(this.waitAnalyseName);
        this.snackBarService.show(this.translateService.instant('Err-Walking-04'), 'danger');
        if (!this.errorLogged) {
          this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Walking-04', err);
          this.errorLogged = true;
        }
        return this.retryResponse;
      }),
      map((response) => {
        console.error('askRetry', response);
        // this.logger.info('Retry send : ' + response);
        if (response) {
          // this.showModal(this.waitAnalyseName);
          return 'retry';
        } else {
          throw new Error('CancelRetry');
        }
      }),
    );
  }

  private buildAnalysisParameters(): AnalysisParameters {
    const out = new AnalysisParameters();

    out.taille = this.patient.height;
    // Ugly hack because we store mutltiple type of data in this object
    // const measuredWeight = LocalStorageEx.MeasuredWeight as any;
    // if (LocalStorageEx.currentPatientSelected && LocalStorageEx.currentPatientSelected.lastMeasuredWeight) {
    //   out.poids = +JSON.stringify(LocalStorageEx.currentPatientSelected.lastMeasuredWeight);
    // } else if (measuredWeight.weight && measuredWeight.weight !== undefined && measuredWeight.weight !== null) {
    //   out.poids = +JSON.stringify(measuredWeight.weight);
    // } else {
    //   out.poids = LocalStorageEx.MeasuredWeight || LocalStorageEx.Weight;
    // }
    out.distance = medicalConst.DISTANCE_TO_LOCOMOTION;
    out.frequence = 100;
    out.age = Compute.age(new Date(this.patient.birthDate));
    out.login = '' + this.patient.id;
    out.date = new Date(); // FIXME
    // out.sexe = Gender[this.patient.gender].slice(0, 1).toLocaleUpperCase();
    out.sexe = this.patient.gender;
    out.poids = this.patient.weight;
    out.imc = Compute.BMI(this.patient.height, this.patient.weight);
    out.lateralisation = this.patient.lateralization;
    // out.lateralisation = Handedness[this.patient.lateralization].slice(0, 1).toLocaleUpperCase();
    out.chaussure = this.shoesSelectorValues.shoesType;
    out.typebilan = 2;
    out.duree = medicalConst.TIME_TO_ROMBERG;
    return out;
  }

  // This method is call when we click on replay test
  public onReplayTest(): void {
    this.canDisplayAssessment = false;
    this.isTestFinish = false;
    this.isAlgorithmFinish = false;
  }

  // This method is call when we click on modify appreciation
  public onModifyAppreciation(): void {
    this.btnOpenAppreciation.nativeElement.click();
  }

  /**
   * Method which will get data walk using the new ID and forward to report: fix bug AB-374.
   */
  public forwardReportWithNewId(data) {
    //once persist data we had to change the obj to get value from the new walking
    //get object walkAnalysisResult
    //get double Task
    let doubleTask: string;
    if (this.doubleTask) {
      this.doubleTaskService
        .doubleTaskControllerFindById(this.doubleTask, this.translateService.currentLang)
        .subscribe((res) => {
          doubleTask = res.label;
          this.remoteInteractionWalkService.getAnalysisResultsForBilan(data.id).then((value) => {
            const obj = {
              walkAnalysisResult: value,
              shoesSelectorValues: this.shoesSelectorValues,
              typeRapport: this.typeRapport,
              walkAssessments: this.visualAppreciations,
              dateConsultation: data.date,
              doubleTask: doubleTask,
              patientId: this.patient.id,
            };
            //console.log('new walkAnalysis result after persist is ', this.resultsWalk);
            this.router.navigateByUrl('/rapport', { state: obj });
          });
        });
    } else {
      this.remoteInteractionWalkService.getAnalysisResultsForBilan(data.id).then((value) => {
        const obj = {
          walkAnalysisResult: value,
          shoesSelectorValues: this.shoesSelectorValues,
          typeRapport: this.typeRapport,
          walkAssessments: this.visualAppreciations,
          dateConsultation: data.date,
          doubleTask: doubleTask,
          patientId: this.patient.id,
        };
        //console.log('new walkAnalysis result after persist is ', this.resultsWalk);
        this.router.navigateByUrl('/rapport', { state: obj });
      });
    }
  }

  /**
   * Method which wil get all data, create a walking variable and save it in the database.
   */
  public async validateAssessment() {
    //console.log('this.walkAssessments before validate in database ', this.walkAssessments);
    let walking: NewWalking = {
      shoesType: this.shoesSelectorValues.shoesType,
      stabilityAid: this.shoesSelectorValues.walkingAid,
      // Parse is used because we get string due to the use of trunc method.
      averageSpeed: parseFloat(this.walkAssessments[0].value),
      averageStepDuration: parseFloat(this.walkAssessments[1].value),
      cycleLengthVariability: parseFloat(this.walkAssessments[2].value),
      pelvisBearing: parseFloat(this.walkAssessments[3].value),
      walkingSymmetry: parseFloat(this.walkAssessments[4].value),
      //devide to 100 to save in database
      doublePressTime: parseFloat(this.walkAssessments[5].value) / 100,
      stepNumber: this.stepNumber,
      hesitantWalk: null,
      smallSteps: null,
      irregularWalk: null,
      asymmetricWalk: null,
      lossArmSwing: null,
      unbalancedWalk: null,
      doubleTask: this.doubleTask,
      scoreProfil: this.scoreProfil,
      hasVideo: this.hasVideo,
    };
    if (this.course && this.course.id) walking.courseId = this.course.id;

    walking = this.checkVisualAppreciations(walking);

    this.subscriptions.push(
      this.walkingService.walkingControllerCreate(this.patient.id, walking).subscribe(
        async (data) => {
          this.eventTrackingService.logDataChangePatient('DATA_CREATED', this.patient.id, data.id, 'Walking', {}, data);
          this.isWalkValidate = true;
          this.isTestFinish = false;
          this.isAlgorithmFinish = false;
          this.snackBarService.show(this.translateService.instant('app.walk.successSave'), 'success');
          //persist data to server by changing guid ti bilanId
          await this.persistWalkBilan(walking, data.id);
          await this.updateLastTestForPatient();
          if (this.course && this.course.id) {
            await this.updateStatusAndScore(this.course);
            //await this.router.navigateByUrl('/courses/fried', { state: this.course });
          } else {
            await this.forwardReportWithNewId(data);
          }
        },
        (error) => {
          console.log('Error', error);
          this.snackBarService.show(this.translateService.instant('Err-Walking-05'), 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Walking-05', error);
            this.errorLogged = true;
          }
        },
      ),
    );
  }
  public updateLastTestForPatient() {
    const updatePatient: UpdatePatient = {
      id: this.patient.id,
      lasttestdate: new Date(),
      lasttesttype: this.course && this.course.id ? 0 : 3,
    };

    this.patientDataService.updatePatient(updatePatient).subscribe((data) => {
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
      });
    });
  }

  public async updateStatusAndScore(course: Course) {
    const that = this;
    await this.courseDataService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      //refresh navbar to display score
      //get the course object updated
      this.courseDataService.courseControllerFindById(course.id).subscribe((courseObj) => {
        that.router.navigateByUrl('/courses/fried', { state: courseObj });
      });
    });
  }

  /**
   * Method which will persist data romberg in the server: change guid to bilanId.
   */
  private async persistWalkBilan(newWalking: NewWalking, id: number): Promise<any> {
    try {
      const guid: string = this.currentWalkRecordingSession;
      const url: string = this.walkingProxyBaseUri + '/analysis/' + guid + '/persist';

      const footwear: Footwear = await this.footwearService
        .footwearControllerFindById(newWalking.shoesType, this.translateService.currentLang)
        .toPromise();
      const walkingAidIds = newWalking.stabilityAid.join(',');
      const helpStability: WalkingAid[] = await this.walkingAidService
        .walkingAidControllerFindByIds(walkingAidIds, this.translateService.currentLang)
        .toPromise();

      const body: any = {};
      //newWalking.Duration = medicalConst.TIME_TO_ROMBERG;
      body.bilanId = id;
      body.distance = medicalConst.DISTANCE_TO_LOCOMOTION;
      // TODO Set the correct ID
      if (helpStability != null) body.stablizationHelpId = walkingAidIds;
      else body.stablizationHelpId = walkingAidIds;
      if (footwear != null) body.footwearId = footwear.id;
      else body.footwearId = newWalking.shoesType;

      return this.httpClient.post(url, body).toPromise();
    } catch (error) {
      console.error('Error while persist walking ' + ', error');
      //this.notificationService.pushErrorNotifications('Error while persist walkinfg');
      return Promise.reject<any>(error);
    }
  }

  /**
   * Method to analyze each visual appreciations and check if they were evaluated or not.
   * If it's the case, the corresponding value is saved in the variable.
   */
  private checkVisualAppreciations(walking: NewWalking): NewWalking {
    if (this.visualAppreciations[0].nonEvaluated === false) {
      walking.hesitantWalk = this.visualAppreciations[0].value;
    }
    if (this.visualAppreciations[1].nonEvaluated === false) {
      walking.smallSteps = this.visualAppreciations[1].value;
    }
    if (this.visualAppreciations[2].nonEvaluated === false) {
      walking.irregularWalk = this.visualAppreciations[2].value;
    }
    if (this.visualAppreciations[3].nonEvaluated === false) {
      walking.asymmetricWalk = this.visualAppreciations[3].value;
    }
    if (this.visualAppreciations[4].nonEvaluated === false) {
      walking.lossArmSwing = this.visualAppreciations[4].value;
    }
    if (this.visualAppreciations[5].nonEvaluated === false) {
      walking.unbalancedWalk = this.visualAppreciations[5].value;
    }
    return walking;
  }

  /**
   * Method to get assessments which were calculated after the walking acquisition.
   */
  public onGetAssessments(assessments: AssessmentItem[]): void {
    this.walkAssessments = assessments;
  }

  /**
   * Method to get step number which was calculated after the walking acquisition.
   */
  public onGetStepNumber(stepNumber: number): void {
    this.stepNumber = stepNumber;
  }

  public onFinsihAnalysis(resultsWalk: AnalysisResultsWalk): void {
    this.resultsWalk = resultsWalk;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public goTo() {
    this.isTestFinish = false;
    this.isAlgorithmFinish = false;
    this.router.navigate([this.newPage]);
  }
}
