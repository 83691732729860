import {
  PatientDelegation,
  PatientDetail,
  PatientBrief,
  Course,
  CourseControllerService,
  BilansControllerService,
  QuestionnaireReplyControllerService,
  QuestionnaireControllerService,
  QuestionnaireSummary,
} from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { PatientDataService } from 'src/app/modules/abilycare-data-access/api/patient-data.service';
import { RolesCheckService } from 'src/app/modules/core/services/authentification/roles-check.service';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';

@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.scss'],
})
export class PatientDetailComponent implements OnInit, OnDestroy {
  @ViewChild('btnClose', { static: true }) btnClose: ElementRef;
  @Output() openDelegation: EventEmitter<number> = new EventEmitter<number>();
  @Output() clickDeletePatient: EventEmitter<PatientBrief> = new EventEmitter<PatientBrief>();
  @Output() refreshInfomation: EventEmitter<any> = new EventEmitter<any>();

  public patient: PatientDetail;
  public panelOpenState = false;
  public imc: number = 0;
  public isOk = false;

  public canEditPatatt: boolean = false;
  public hasPatAtt: boolean = false;
  public isHealthy: boolean = false;
  public isUnknown: boolean = false;
  public lastCourse: Course;
  public lastTestDate: Date;
  public lastTestType: string;
  public canViewHistory: boolean = false;
  public hasCoursInProgress: boolean = false;

  private subscriptions: Subscription[] = [];

  public questionaireMandatryIds: QuestionnaireSummary[] = [];
  public hasQuestionnaryMandatary: boolean = false;
  public idQuestionnaryMandatary: number;
  private errorLogged = false;

  constructor(
    private dataService: DataProviderService,
    private router: Router,
    public rolesCheck: RolesCheckService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
    private patientDataService: PatientDataService,
    private courseService: CourseControllerService,
    private bilansService: BilansControllerService,
    private questionnaireService: QuestionnaireControllerService,
    private questionnaireRepliesService: QuestionnaireReplyControllerService,
  ) {}

  checkIfHasQuestionnaryMondatary(patient) {
    const lang = this.translateService.currentLang;
    //find questionaire mandatary
    this.questionnaireService.questionnaireControllerFind(lang).subscribe((questionnaireList) => {
      //console.log("questionnaireList is ", questionnaireList);
      const questMandatryList = questionnaireList.filter((quest) => quest.mandatary === true);
      questMandatryList.forEach((quest) => {
        //console.log("this.currentPatient.id ", this.currentPatient.id);
        this.questionnaireRepliesService
          .questionnaireReplyControllerFindByPatientId(patient.id)
          .subscribe((patientReplyListQuestionnary: any) => {
            const result = patientReplyListQuestionnary.filter((reply) => reply.questionnaireId === quest.id);
            //console.log('result is ', result);
            if (result && result.length > 0) {
              const idLastQuestionnary = result[result.length - 1].questionnaireId;
              const dateQuest = result[result.length - 1].date;
              const validityMonth = quest.validityMonth;
              if (this.comparerDateAvecMois(dateQuest, validityMonth)) {
                this.hasQuestionnaryMandatary = true;
                this.idQuestionnaryMandatary = quest.id;
              }
            } else {
              this.hasQuestionnaryMandatary = true;
              this.idQuestionnaryMandatary = quest.id;
            }
          });
      });
    });
  }

  ngOnInit() {
    this.eventTrackingService.init();
    console.log('launch patient detail for patient ', this.patient);
    this.hasQuestionnaryMandatary = false;
    this.subscriptions.push(
      this.dataService.getPatient().subscribe(
        (patient) => {
          this.getPatient(patient);
        },
        (err) => {
          console.error('PatientDetailComponent : Err-Patient-01 ', err);
          const errMsg = this.translateService.instant('Err-Patient-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logErrorPatient(this.patient.id, 'Err-Patient-01', err);
            this.errorLogged = true;
          }
        },
      ),
    );
  }

  public checkCanViewResults() {
    let canViewResults: boolean = false;
    if (this.patient.currentPractionerRoles && this.patient.currentPractionerRoles.length > 0) {
      this.patient.currentPractionerRoles.forEach((role) => {
        if (role === 'ResultDelegation' || role === 'TestDelegation' || role === 'Owner') {
          canViewResults = true;
        }
      });
    }
    return canViewResults && this.patient.consent;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public onRefreshInfomation(): void {
    this.refreshInfomation.emit();
  }

  comparerDateAvecMois(dateComparaison: Date, moisAjoutes: number): boolean {
    // Obtenez la date du jour
    const dateDuJour = new Date();

    // Ajoutez le nombre de mois spécifié à la date de comparaison
    const dateAvecMois = new Date(dateComparaison);
    dateAvecMois.setMonth(dateAvecMois.getMonth() + moisAjoutes);

    // Comparez les deux dates
    if (dateDuJour < dateAvecMois) {
      return false;
    } else {
      return true;
    }
  }

  private getPatient(patient): void {
    let that = this;
    this.hasCoursInProgress = false;
    this.patient = patient;
    this.canEditPatatt = false;
    this.hasPatAtt = false;
    this.isHealthy = false;
    this.isUnknown = false;
    this.hasQuestionnaryMandatary = false;

    this.checkIfHasQuestionnaryMondatary(patient);
    //console.log('getPatient this.hasQuestionnaryMandatary ', this.hasQuestionnaryMandatary);

    if (this.patient.currentPractionerRoles && this.patient.currentPractionerRoles.length > 0) {
      this.patient.currentPractionerRoles.forEach((role) => {
        if (role === 'Owner' || role === 'TestDelegation' || role === 'ResultDelegation') {
          this.canEditPatatt = true;
        }
      });
    }

    if (this.patient.patattBrief && this.patient.patattBrief.count > 0) {
      this.hasPatAtt = true;
    }

    this.isHealthy = this.patient.patattBrief ? this.patient.patattBrief.isHealthy : true;
    this.isUnknown = this.patient.patattBrief ? this.patient.patattBrief.isUnknown : true;
    const weight = this.patient.weight;
    const height = this.patient.height / 100;

    if (weight >= 0 && height >= 0) {
      this.imc = parseFloat((weight / (height * height)).toFixed(1));
    }

    this.courseService.courseControllerFindAllCourse(patient.id).subscribe((allCourses) => {
      var resultArray = Object.keys(allCourses).map(async function(courseIndex) {
        let courseObj = allCourses[courseIndex];
        await that.courseService.courseControllerUpdateScoreAndStatus(patient.id, courseObj.id).subscribe((res) => {
          that.courseService.courseControllerFindById(courseObj.id).subscribe((course) => {
            if (course.status === 0 || course.status === 1) {
              that.hasCoursInProgress = true;
              return true;
            }
          });
        });
      });
    });

    const typeTest = patient.lasttesttype;
    if (typeTest == 0) {
      this.lastTestType = this.translateService.instant('Patient.Profil.course_fragility');
    } else if (typeTest == 1) {
      this.lastTestType = this.translateService.instant('app.questionnary');
    } else if (typeTest == 2) {
      this.lastTestType = this.translateService.instant('Course.filter.romberg.result');
    } else if (typeTest == 3) {
      this.lastTestType = this.translateService.instant('Course.filter.walking.result');
    } else if (typeTest == 4) {
      this.lastTestType = this.translateService.instant('Course.filter.grip.result');
    } else if (typeTest == 5) {
      this.lastTestType = this.translateService.instant('Course.filter.weight.result');
    } else {
      this.lastTestType = '';
    }
  }

  public async updateStatusAndScore(course: Course) {
    await this.courseService.courseControllerUpdateScoreAndStatus(course.patientId, course.id).subscribe((res) => {
      console.log('course updated status and score ');
    });
  }

  public onOpenDelegation(): void {
    this.openDelegation.emit(this.patient.id);
  }

  public onDeletePatient(): void {
    const patientBrief: PatientBrief = {
      id: this.patient.id,
      lastName: this.patient.lastName,
      firstName: this.patient.firstName,
      delegateTo: null,
    };
    this.clickDeletePatient.emit(patientBrief);
  }

  public openModal(): void {
    this.isOk = true;
  }

  public onCloseModal(): void {
    this.isOk = false;
    this.subscriptions.push(
      this.patientDataService.getPatientById(this.patient.id).subscribe((patient) => {
        this.dataService.setPatient(patient);
        this.getPatient(patient);
      }),
    );
    this.btnClose.nativeElement.click();
  }

  public addPatAtt(): void {
    this.router.navigate(['PatAtt/', this.patient.id, true, 'edition']);
  }

  public openHistory(): void {
    this.router.navigate(['PatientHistoryCourses/', this.patient.id]);
  }
}
