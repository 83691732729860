import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { PractitionerSpecialityDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-speciality-data.service';
import { Practitioner, PractitionerSpeciality } from '@abilycare/dal-client';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';

@Component({
  selector: 'app-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.scss'],
})
export class ProfilComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public practitionerMe: Practitioner;
  public practitionerSpecialities: PractitionerSpeciality[] = [];
  public isOk: boolean = false;
  public editable: boolean = true;
  private subscriptions: Subscription[] = [];
  private errorLogged = false;

  constructor(
    private formBuilder: FormBuilder,
    private practitionerDataService: PractitionerDataService,
    private practitionerSpecialityService: PractitionerSpecialityDataService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
  ) {
    this.subscriptions.push(
      this.practitionerDataService.getPractitionerMe().subscribe(
        (practitioner) => {
          this.practitionerMe = practitioner;

          this.formGroup = this.formBuilder.group({
            speciality: [practitioner, Validators.required],
          });

          this.isOk = true;
        },
        (error) => {
          console.error('ProfilComponent : Err-Practitionner-01 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logError('Err-Practitionner-01', error);
            this.errorLogged = true;
          }
        },
      ),
    );

    this.subscriptions.push(
      this.practitionerSpecialityService.getAllPractitionerSpecialities().subscribe(
        (practionerSpecialities) => {
          this.practitionerSpecialities = practionerSpecialities;
        },
        (error) => {
          console.error('ProfilComponent : Err-Practitionner-04 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-04');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logError('Err-Practitionner-04', error);
            this.errorLogged = true;
          }
        },
      ),
    );
  }

  ngOnInit() {
    this.eventTrackingService.init();
  }

  public compareFn(practitionerSpeciality: PractitionerSpeciality, practitioner: Practitioner): boolean {
    let selectSpeciality: boolean = false;
    if (practitionerSpeciality && practitioner)
      selectSpeciality = practitionerSpeciality.id === practitioner.specialityId;
    return selectSpeciality;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public canEditSpeciality(): void {
    this.editable = !this.editable;
  }

  public cancel(): void {
    this.formGroup.controls.speciality.setValue(this.practitionerMe, { onlySelf: true });
    this.canEditSpeciality();
  }

  public validate(): void {
    const speciality: PractitionerSpeciality = this.formGroup.value.speciality;
    this.subscriptions.push(
      this.practitionerDataService.setMySpeciality(speciality.id).subscribe(
        (data) => {
          this.editable = true;
          this.practitionerMe.specialityId = speciality.id;
          this.snackBarService.show(this.translateService.instant('app.snack.update.patient.success'), 'success');
        },
        (error) => {
          console.error('ProfilComponent : Err-Practitionner-01 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logError('Err-Practitionner-01', error);
            this.errorLogged = true;
          }
        },
      ),
    );
  }
}
