<app-custom-navbar
  [patient]="patient"
  [returnTitle]="returnTitle"
  (selectPage)="onSelectPage($event)"
  *ngIf="!fromBilan"
></app-custom-navbar>
<app-courses-breadcrumb-navbar [testName]="testName | translate"></app-courses-breadcrumb-navbar>
<!-- <div *ngIf="error" class="col-md-12 alert alert-danger">
  <div>une erreur est survenue, veuillez réessayer</div>
</div> -->
<div class="col-md-12">
  <div class="row">
    <div class="col-md-3 p-0">
      <app-fried-navbar [currentPage]="'romberg'" (selectPage)="onSelectPage($event)"></app-fried-navbar>
    </div>
    <div *ngIf="!isTestValidated" class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-11  full-size-content-component" style="font-weight: normal;">
          <img
            class="d-inline-block align-top"
            width="20"
            height="20"
            src="assets/images/icons/info-circle.svg"
            alt=""
          />
          {{ 'app.romberg.protocol.followStep' | translate }}
        </div>
      </div>
      <div class="row">
        <div class="col-md-7 simple-content-component">
          <div style="margin-bottom: 30px;">
            {{ 'app.romberg.protocol.stepTitle' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top " width="20" height="20" src="assets/images/icons/icon-1.svg" alt="" />
            {{ 'app.romberg.protocol.step1' | translate }}
          </div>
          <div class="protocol-step">
            <img class="d-inline-block align-top" width="20" height="20" src="assets/images/icons/icon-2.svg" alt="" />
            {{ 'app.romberg.protocol.step2' | translate }}
          </div>
          <div class="tutorial-button-container">
            <button
              (click)="resetModal()"
              class="btn btn-tertiary"
              data-target="#rombergTutorialModal"
              data-toggle="modal"
            >
              {{ 'app.romberg.protocol.tutorialLink' | translate }}
            </button>
          </div>
        </div>
        <div class="col-md-4 simple-content-component">
          <div>{{ 'app.romberg.platform' | translate }}</div>
          <app-information-connexion #plateMonitor></app-information-connexion>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 simple-content-component" style="height: 500px;">
          <div>{{ 'app.romberg.information' | translate }}</div>
          <div class="preamble-romberg">
            <div class="row">
              <div class="col-lg-12">
                <app-checkup-romberg-shoes-selector
                  [value]="shoesSelectorValues"
                  (valueChange)="onShoesSelectorValuesChange($event)"
                ></app-checkup-romberg-shoes-selector>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7 simple-content-component">
          <div style="margin-bottom: 30px;">
            <div>{{ 'app.romberg.mesurement' | translate }}</div>
          </div>
          <div class="row" style="margin-bottom: 30px; font-weight: normal;">
            <div class="col-md-6" style="border-right: 1px solid #DCE2E7;">
              <div>
                <div *ngIf="!isTestYOFinish" style="cursor:pointer;" (click)="playTestYO()">
                  <div style="text-align: center;">
                    <img
                      style="float: left; position: absolute; top: 0; left: 15px;"
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/icon-1.svg"
                      alt=""
                    />
                    <div>{{ 'Romberg.EyesOpen' | translate }}</div>
                    <div
                      [ngClass]="{ 'elipse-div-enable': isTestYOAvailable, 'elipse-div-disabled': !isTestYOAvailable }"
                    >
                      <img
                        class="watch-img d-inline-block align-top"
                        width="70"
                        height="70"
                        [src]="
                          isTestYOAvailable
                            ? 'assets/images/images/watch.svg'
                            : 'assets/images/images/watch-disable.svg'
                        "
                        alt=""
                      />
                    </div>
                  </div>
                  <div
                    *ngIf="!isTestYORunning"
                    class="btn-tertiary text-uppercase"
                    [ngClass]="{
                      'start-test-button': isTestYOAvailable,
                      'start-test-button-disabled': !isTestYOAvailable
                    }"
                  >
                    {{ 'app.romberg.startTest' | translate }}
                  </div>
                  <div *ngIf="isTestYORunning" class="start-test-button-disabled btn-tertiary">
                    {{ 'app.romberg.testRunning' | translate }}
                    <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <!--
                    <div *ngIf="wiiState | async as state" class="wiistate">
                      <div [ngClass]="{ acquire: state.acquisitionActive, connected: state.wiiBoardConnected }">
                        {{ 'Checkup.Romberg.Measure.Number.Measures' | translate }} {{ state.nbPoints }}
                      </div>
                    </div>
                    -->
                  </div>

                  <div
                    *ngIf="isTestYORunning"
                    class="start-test-button btn-tertiary"
                    style="cursor:pointer; margin-top: 0px;"
                    (click)="stopTest(0)"
                  >
                    {{ 'app.romberg.stopTest' | translate }}
                  </div>
                </div>
                <div *ngIf="isTestYOFinish">
                  <div style="text-align: center;">
                    <img
                      style="float: left; position: absolute; top: 0; left: 15px;"
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/icon-1.svg"
                      alt=""
                    />
                    <div>{{ 'Romberg.EyesOpen' | translate }}</div>
                    <div style="margin-top: 15px; margin-bottom: 15px;">
                      <img
                        class="d-inline-block align-top"
                        width="50"
                        height="50"
                        src="assets/images/icons/check-circle-check.svg"
                        alt=""
                      />
                    </div>
                    <div class="start-test-button-disabled btn-tertiary" style="margin-bottom: 10px;">
                      {{ 'done.at' | translate }} {{ timeFinishYO }}
                    </div>
                    <div
                      (click)="restartTestYO()"
                      class="start-test-button btn-tertiary text-uppercase"
                      style="cursor:pointer; margin-top: 0px;"
                    >
                      {{ 'app.test.restart' | translate }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="timer" style="text-align: center;">
                <div>
                  <span class="number">00:{{ timerYO.secondesString }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div *ngIf="!isTestYFFinish" style="cursor:pointer;" (click)="playTestYF()">
                <div style="text-align: center;">
                  <img
                    style="float: left; position: absolute; top: 0; left: 15px;"
                    class="d-inline-block align-top"
                    width="20"
                    height="20"
                    src="assets/images/icons/icon-2.svg"
                    alt=""
                  />
                  <div>{{ 'Romberg.EyesClosed' | translate }}</div>
                  <div
                    [ngClass]="{ 'elipse-div-enable': isTestYFAvailable, 'elipse-div-disabled': !isTestYFAvailable }"
                  >
                    <img
                      class="watch-img d-inline-block align-top"
                      width="70"
                      height="70"
                      [src]="
                        isTestYFAvailable ? 'assets/images/images/watch.svg' : 'assets/images/images/watch-disable.svg'
                      "
                      alt=""
                    />
                  </div>
                </div>
                <div
                  *ngIf="!isTestYFRunning"
                  class="btn-tertiary text-uppercase"
                  [ngClass]="{
                    'start-test-button': isTestYFAvailable,
                    'start-test-button-disabled': !isTestYFAvailable
                  }"
                >
                  {{ 'app.romberg.startTest' | translate }}
                </div>
                <div *ngIf="isTestYFRunning" class="start-test-button-disabled btn-tertiary">
                  {{ 'app.romberg.testRunning' | translate }}
                  <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;">
                    <span class="sr-only">Loading...</span>
                  </div>
                  <!--
                  <div *ngIf="wiiState | async as state" class="wiistate">
                    <div [ngClass]="{ acquire: state.acquisitionActive, connected: state.wiiBoardConnected }">
                      {{ 'Checkup.Romberg.Measure.Number.Measures' | translate }} {{ state.nbPoints }}
                    </div>
                  </div>
                  -->
                </div>
                <div
                  *ngIf="isTestYFRunning"
                  class="start-test-button btn-tertiary"
                  style="cursor:pointer; margin-top: 0px;"
                  (click)="stopTest(1)"
                >
                  {{ 'app.romberg.stopTest' | translate }}
                </div>
              </div>
              <div *ngIf="isTestYFFinish">
                <div style="text-align: center;">
                  <img
                    style="float: left; position: absolute; top: 0; left: 15px;"
                    class="d-inline-block align-top"
                    width="20"
                    height="20"
                    src="assets/images/icons/icon-2.svg"
                    alt=""
                  />
                  <div>{{ 'Romberg.EyesClosed' | translate }}</div>
                  <div style="margin-top: 15px; margin-bottom: 15px;">
                    <img
                      class="d-inline-block align-top"
                      width="50"
                      height="50"
                      src="assets/images/icons/check-circle-check.svg"
                      alt=""
                    />
                  </div>
                  <div class="start-test-button-disabled btn-tertiary" style="margin-bottom: 10px;">
                    {{ 'done.at' | translate }} {{ timeFinishYF }}
                  </div>
                  <div
                    (click)="restartTestYF()"
                    class="start-test-button btn-tertiary"
                    style="cursor:pointer; margin-top: 0px;"
                  >
                    {{ 'app.test.restart' | translate }}
                  </div>
                </div>
              </div>
              <div class="timer" style="text-align: center;">
                <div>
                  <span class="number">00:{{ timerYF.secondesString }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isTestValidated" class="col-md-9 fried-info">
      <div class="row">
        <div class="col-md-7 simple-content-component">
          <div style="margin-bottom: 30px;">
            <div>{{ 'app.romberg.results' | translate }}</div>
          </div>
          <div class="result-summary">
            <div class="row">
              <div class="col-lg-6" style="border-right: 1px solid #DCE2E7;">
                <div class="row">
                  <div class="col-lg-12 content-box">
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/eye-open.svg"
                      alt=""
                    />
                    <span style="margin-right: 10px; color:#70757a;text-transform: uppercase;">{{
                      'Romberg.EyesOpen' | translate
                    }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYO' | translate }}</span>
                  <span class="text value">{{ data.ScoreYO | float: 1 }}</span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesScore }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesScore"
                      [max]="maxEyesScore"
                      [step]="1"
                      [(ngModel)]="data.ScoreYO"
                      [ngClass]="{
                        'assessment-container': !isOffLimitOpenedEyesScore,
                        'assessment-container-offlimit': isOffLimitOpenedEyesScore
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesScore }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYO' | translate }}</span>
                  <span class="text value">{{ data.SurfaceYO | float: 1 }} &nbsp;cm<sup>2</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesArea }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesArea"
                      [max]="maxEyesArea"
                      [step]="1"
                      [(ngModel)]="data.SurfaceYO"
                      [ngClass]="{
                        'assessment-container': !isOffLimitOpenedEyesArea,
                        'assessment-container-offlimit': isOffLimitOpenedEyesArea
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesArea }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYO' | translate }}</span>
                  <span class="text value">{{ data.SwayDensityYO | float: 1 }}&nbsp;s</span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesSwayDensity }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesSwayDensity"
                      [max]="maxEyesSwayDensity"
                      [step]="1"
                      [(ngModel)]="data.SwayDensityYO"
                      [ngClass]="{
                        'assessment-container': !isOffLimitOpenedEyesSwayDensity,
                        'assessment-container-offlimit': isOffLimitOpenedEyesSwayDensity
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesSwayDensity }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYO' | translate }}</span>
                  <span class="text value">{{ data.VarianceLateraleYO | float: 3 }}&nbsp;cm<sup>2</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesLateralVariance }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesLateralVariance"
                      [max]="maxEyesLateralVariance"
                      [step]="0.025"
                      [(ngModel)]="data.VarianceLateraleYO"
                      [ngClass]="{
                        'assessment-container': !isOffLimitOpenedEyesLateralVariance,
                        'assessment-container-offlimit': isOffLimitOpenedEyesLateralVariance
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesLateralVariance }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYO' | translate }}</span>
                  <span class="text value">{{ data.SpeedMoyYO | float: 1 }}&nbsp;cm.s<sup>-1</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesAverageSpeed }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesAverageSpeed"
                      [max]="maxEyesAverageSpeed"
                      [step]="1"
                      [(ngModel)]="data.SpeedMoyYO"
                      [ngClass]="{
                        'assessment-container': !isOffLimitOpenedEyesAverageSpeed,
                        'assessment-container-offlimit': isOffLimitOpenedEyesAverageSpeed
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesAverageSpeed }}</span>
                  </div>
                </div>

                <div class="col-md-12 flex-column assessment-container" *ngIf="typePlate == 'Kinvent'">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.OpenedprcSupport' | translate }}</span>
                  <span class="text value">
                    {{ openedEyesPrcLeftSupport | number: '1.0-0' }}% |
                    {{ 100 - openedEyesPrcLeftSupport | number: '1.0-0' }}%
                  </span>
                  <div class="d-flex slider-container custom-slider">
                    <ngx-slider
                      [(value)]="minOpenedEyesLeftSupport"
                      [(highValue)]="maxOpenedEyesLeftSupport"
                      [options]="optionsopenedEyesLeftSupport"
                    >
                    </ngx-slider>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="row">
                  <div class="col-lg-12 content-box">
                    <img
                      class="d-inline-block align-top"
                      width="20"
                      height="20"
                      src="assets/images/icons/eye-close.svg"
                      alt=""
                    />
                    <span style="margin-right: 10px; color:#70757a; text-transform: uppercase;">{{
                      'Romberg.EyesClosed' | translate
                    }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.ScoreYF' | translate }}</span>
                  <span class="text value">{{ data.ScoreYF | float: 1 }}</span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesScore }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesScore"
                      [max]="maxEyesScore"
                      [step]="1"
                      [(ngModel)]="data.ScoreYF"
                      [ngClass]="{
                        'assessment-container': !isOffLimitClosedEyesScore,
                        'assessment-container-offlimit': isOffLimitClosedEyesScore
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesScore }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SurfaceYF' | translate }}</span>
                  <span class="text value">{{ data.SurfaceYF | float: 1 }}&nbsp;cm<sup>2</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesArea }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesArea"
                      [max]="maxEyesArea"
                      [step]="1"
                      [(ngModel)]="data.SurfaceYF"
                      [ngClass]="{
                        'assessment-container': !isOffLimitClosedEyesArea,
                        'assessment-container-offlimit': isOffLimitClosedEyesArea
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesArea }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SwayDensityYF' | translate }}</span>
                  <span class="text value">{{ data.SwayDensityYF | float: 1 }}&nbsp;s</span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesSwayDensity }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesSwayDensity"
                      [max]="maxEyesSwayDensity"
                      [step]="1"
                      [(ngModel)]="data.SwayDensityYF"
                      [ngClass]="{
                        'assessment-container': !isOffLimitClosedEyesSwayDensity,
                        'assessment-container-offlimit': isOffLimitClosedEyesSwayDensity
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesSwayDensity }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.VarianceLateraleYF' | translate }}</span>
                  <span class="text value">{{ data.VarianceLateraleYF | float: 3 }}&nbsp;cm<sup>2</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesLateralVariance }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesLateralVariance"
                      [max]="maxEyesLateralVariance"
                      [step]="0.025"
                      [(ngModel)]="data.VarianceLateraleYF"
                      [ngClass]="{
                        'assessment-container': !isOffLimitClosedEyesLateralVariance,
                        'assessment-container-offlimit': isOffLimitClosedEyesLateralVariance
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesLateralVariance }}</span>
                  </div>
                </div>
                <div class="col-md-12 flex-column assessment-container">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.SpeedMoyYF' | translate }}</span>
                  <span class="text value">{{ data.SpeedMoyYF | float: 1 }}&nbsp;cm.s<sup>-1</sup></span>
                  <div class="d-flex slider-container">
                    <span class="number">{{ minEyesAverageSpeed }}</span>
                    <mat-slider
                      [disabled]="true"
                      [min]="minEyesAverageSpeed"
                      [max]="maxEyesAverageSpeed"
                      [step]="1"
                      [(ngModel)]="data.SpeedMoyYF"
                      [ngClass]="{
                        'assessment-container': !isOffLimitClosedEyesAverageSpeed,
                        'assessment-container-offlimit': isOffLimitClosedEyesAverageSpeed
                      }"
                    ></mat-slider>
                    <span class="number">{{ maxEyesAverageSpeed }}</span>
                  </div>
                </div>

                <div class="col-md-12 flex-column assessment-container" *ngIf="typePlate == 'Kinvent'">
                  <span class="text title">{{ 'Checkup.Romberg.Summary.ClosedprcSupport' | translate }}</span>
                  <span class="text value">
                    {{ closedEyesPrcLeftSupport | number: '1.0-0' }}% |
                    {{ 100 - closedEyesPrcLeftSupport | number: '1.0-0' }}%
                  </span>
                  <div class="d-flex slider-container custom-slider">
                    <ngx-slider
                      [(value)]="minClosedEyesLeftSupport"
                      [(highValue)]="maxClosedEyesLeftSupport"
                      [options]="optionsclosedEyesLeftSupport"
                    >
                    </ngx-slider>
                  </div>
                </div>
              </div>
            </div>
            <div class="buttons-container d-flex justify-content-between">
              <button class="btn btn-tertiary" data-target="#restartRombergModal" data-toggle="modal">
                {{ 'app.test.restart' | translate }}
              </button>
              <button class="btn btn-tertiary" (click)="onModifyAppreciation()">
                {{ 'app.walk.changeAppreciation' | translate }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="row">
            <div class="col-md-12 simple-content-component">
              <div>{{ 'app.romberg.platform' | translate }}</div>
              <app-information-connexion #plateMonitor></app-information-connexion>
            </div>
            <div class="col-md-12 simple-content-component">
              <div style="text-align: center;">
                <div>
                  <img
                    class="d-inline-block align-top pointer"
                    (click)="openGraphModal()"
                    data-target="#rombergGraphModal"
                    data-toggle="modal"
                    src="assets/images/images/graphique.svg"
                    alt=""
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    (click)="openGraphModal()"
                    data-target="#rombergGraphModal"
                    data-toggle="modal"
                    class="btn btn-tertiary"
                  >
                    {{ 'app.walk.access.graphic' | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 footer-content-component">
      <!--<button
        (click)="runCalculation()"
        data-target="#rombergAssessmentModal"
        data-toggle="modal"
        class="btn btn-primary general-button right-button"
        [ngStyle]="{ opacity: !isTestYOFinish || !isTestYFFinish ? '0.30' : '1' }"
        [disabled]="!isTestYOFinish || !isTestYFFinish"
      >
        Continuer
      </button>-->
      <button
        *ngIf="!isTestValidated"
        data-target="#rombergAssessmentModal"
        data-toggle="modal"
        class="btn btn-primary general-button right-button"
        [ngStyle]="{ opacity: !isTestYOFinish || !isTestYFFinish ? '0.30' : '1' }"
        [disabled]="!isTestYOFinish || !isTestYFFinish"
      >
        {{ 'Continuer' | translate }}
      </button>
      <button
        *ngIf="isTestValidated"
        class="btn btn-primary general-button right-button"
        (click)="validateAssessment()"
      >
        {{ 'button.save' | translate }}
      </button>
      <button
        data-target="#rombergAssessmentModal"
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
        [hidden]="true"
        #btnOpenAssessmentModal
      >
        Open assessment modal
      </button>
      <button
        data-target="#rombergCalculationModal"
        data-toggle="modal"
        data-backdrop="static"
        data-keyboard="false"
        [hidden]="true"
        #btnOpenCalculationModal
      >
        Open calculation modal
      </button>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="rombergTutorialModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergTutorialModalTitle"
  aria-hidden="true"
  #basicModal
>
  <div class="modal-dialog modal-dialog-centered modal-medium" style="max-width: 1200px;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergTutorialModalTitle">
          {{ 'plateform.installation' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-romberg-tutorial #rombergTutorial></app-romberg-tutorial>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="onCloseModal()">{{ 'Close' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="rombergAssessmentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergAssessmentModalTitle"
  aria-hidden="true"
  #rombergAssessmentModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" role="document">
    <div class="modal-content modal-appreciation">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergAssessmentModalTitle">
          {{ 'Appreciation visuelle' | translate }}
        </h5>
        <button #btnCloseAssessmentModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-romberg-appreciation #rombergAssessment (validate)="runCalculation($event)" (replayTest)="onReplayTest()">
        </app-romberg-appreciation>
      </div>
    </div>
  </div>
</div>

<app-core-modal name="{{ waitAnalyseName }}" #measureWaitAnalyse>
  <div class="modal-body flex-column align-items-center">
    <label>{{ 'app.test.mesure.inprogress.modal.contentanalyses' | translate }}</label>
    <app-spinner></app-spinner>
  </div>
</app-core-modal>

<div
  class="modal fade"
  id="rombergCalculationModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergCalculationModalTitle"
  aria-hidden="true"
  #rombergCalculationModal
>
  <div class="modal-dialog modal-dialog-centered modal-xs" role="document">
    <div class="modal-content">
      <div class="modal-header" style="display: none;">
        <button #btnCloseCalculationModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <span *ngIf="calculResult">
            calcul des résultats en cours, veuillez patienter...
          </span>
          <span *ngIf="!calculResult">
            transfert des données en cours, veuillez patienter...
          </span>
          <div class="spinner-border" role="status" style="width: 1rem; height: 1rem;">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="rombergGraphModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergGraphModalTitle"
  aria-hidden="true"
  #rombergGraphModal
>
  <div class="modal-dialog modal-dialog-centered modal-large" style="max-width: 1200px;" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergGraphModalTitle">
          {{ graphTitle }}
        </h5>
        <button #btnCloseGraphModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="romberg-graph">
          <div class="statokinesigram" *ngIf="isStatokinesigram">
            <div class="row">
              <ng-template #zoomstatokinesigram>
                <div class="zoom" (click)="closeChart()">
                  <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" alt="" />
                  <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" alt="" />
                </div>
              </ng-template>
              <div *ngIf="!zoom; else zoomstatokinesigram" class="col-lg-10 offset-lg-1" style="text-align : center;">
                <div class="row">
                  <div class="col-lg-12">
                    <button
                      (click)="changeToStandard()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="standardGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Standard' | translate }}
                    </button>
                    <button
                      (click)="changeToAsymetrie()"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="asymetrieGraph"
                    >
                      {{ 'Checkup.Romberg.Graph.Statok.Asymetry' | translate }}
                    </button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12" (click)="zoomGraph()" style="cursor: zoom-in;">
                    <img *ngIf="standardGraph" [attr.src]="data.statok | secure | async" height="400px;" alt="" />
                    <img *ngIf="asymetrieGraph" [attr.src]="data.statsym | secure | async" height="400px;" alt="" />
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 210px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="stabilogram" *ngIf="isStabilogram">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('statokinesigram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
              <ng-template #zoomstabilogram>
                <div class="zoom" (click)="closeChart()">
                  <img [attr.src]="data.stabilo | secure | async" alt="" />
                </div>
              </ng-template>
              <div
                *ngIf="!zoom; else zoomstabilogram"
                (click)="zoomGraph()"
                class="col-lg-10"
                style="text-align : center; cursor: zoom-in;"
              >
                <img [attr.src]="data.stabilo | secure | async" height="400px;" alt="" />
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('comparison')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 150px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="comparison" *ngIf="isComparison">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <meta charset="utf-8" />
                  <!--
                  <div class="optionsBar row">
                    <button
                      *ngFor="let title of typesOfComparisons"
                      (click)="onSelectGraphComparaison(title)"
                      type="button"
                      class="btn btn-primary choice-button"
                      [class.selected]="title === choiceOfComparison"
                    >
                      {{ title | translate }}
                    </button>
                  </div>
                  -->

                  <div *ngIf="recupComparaison" class="test">
                    <ng-template #zoompart>
                      <div class="zoom" (click)="closeChart()">
                        <div style="width: 90%; margin: 0 auto;">
                          <app-graph-horizontal
                            [title]="comparisonGraphSelected.title"
                            [data]="comparisonGraphSelected"
                          ></app-graph-horizontal>
                        </div>
                      </div>
                    </ng-template>

                    <div *ngIf="!zoom; else zoompart" class="setOfGraph">
                      <div
                        *ngFor="let data of comparisonDatas; let i = index"
                        class="graphic2"
                        (click)="comparisonChartClicked(data)"
                      >
                        <app-graph-horizontal [title]="data.title" [data]="data"></app-graph-horizontal>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalO')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="longitudinal" *ngIf="isLongitudinalO">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('stabilogram')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <div *ngIf="!zoom" class="setOfGraph">
                    <div *ngFor="let graph of graphs; let i = index" class="graphic2" (click)="chartClicked(graph)">
                      <app-graph-timeline [title]="graph.title" [data]="graph"> </app-graph-timeline>
                    </div>
                  </div>
                  <div class="zoom" *ngIf="zoom" (click)="closeChart()">
                    <div style="width: 90%; margin: 0 auto;">
                      <app-graph-timeline [title]="graphSelected.title" [data]="graphSelected"> </app-graph-timeline>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalC')"
                  src="assets/images/icons/right-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 270px; cursor: pointer;"
                />
              </div>
            </div>
          </div>
          <div class="longitudinal" *ngIf="isLongitudinalC">
            <div class="row">
              <div class="col-lg-1">
                <img
                  (click)="goToGraph('longitudinalO')"
                  src="assets/images/icons/left-chevron.svg"
                  width="80px;"
                  alt=""
                  style="margin-top: 100px; cursor: pointer;"
                />
              </div>
              <div class="col-lg-10" style="text-align : center;">
                <div class="comparison-container">
                  <div *ngIf="!zoom" class="setOfGraph">
                    <div
                      *ngFor="let graph of graphsRombYF; let i = index"
                      class="graphic2"
                      (click)="chartClicked(graph)"
                    >
                      <app-graph-timeline [title]="graph.title" [data]="graph"> </app-graph-timeline>
                    </div>
                  </div>
                  <div class="zoom" *ngIf="zoom" (click)="closeChart()">
                    <div style="width: 90%; margin: 0 auto;">
                      <app-graph-timeline [title]="graphSelected.title" [data]="graphSelected"> </app-graph-timeline>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-end">
        <button class="btn btn-primary" (click)="closeGraphModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="restartRombergModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="restartRombergModalModalTitle"
  aria-hidden="true"
  #basicRombergModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="restartRombergModalModalTitle">
          {{ 'app.assessment.restart.warning' | translate }}
        </h5>
        <button #btnClose type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <label>
            {{ 'app.assessment.restart.message' | translate }}
          </label>
        </div>
        <div class="col-lg-12">
          <button #btnClose type="button" class="btn btn-danger restart-buttons" data-dismiss="modal">
            {{ 'app.assessment.restart.cancel' | translate }}
          </button>
          <button
            #btnClose
            (click)="restartTest()"
            type="button"
            class="btn btn-success restart-buttons"
            data-dismiss="modal"
          >
            {{ 'app.assessment.restart.validation' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<button #btnOpenQuit data-target="#rombergQuitTestModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="rombergQuitTestModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergQuitTestModalTitle"
  aria-hidden="true"
  #rombergQuitTestModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergQuitTestModalModalTitle">
          {{ 'app.walk.impossible.quit.title' | translate }}
        </h5>
        <button #btnClosrombergQuitTestModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        {{ 'app.walk.impossible.quit.message' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Ok' | translate }}</button>
      </div>
    </div>
  </div>
</div>

<button #btnQuitBeforeSave data-target="#rombergQuitBeforeSaveModal" data-toggle="modal" [hidden]="true"></button>
<div
  class="modal fade"
  id="rombergQuitBeforeSaveModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="rombergQuitBeforeSaveModalTitle"
  aria-hidden="true"
  #rombergQuitBeforeSaveModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="rombergQuitBeforeSaveModalModalTitle">
          {{ 'app.quit.before.save.title' | translate }}
        </h5>
        <button #btnClosRombergQuitBeforeSaveModal type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p [innerHTML]="'app.romberg.impossible.quit.before.save.message' | translate"></p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" (click)="goTo()" data-dismiss="modal">
          {{ 'app.quit.before.save.button.yes' | translate }}
        </button>
        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ 'Non' | translate }}</button>
      </div>
    </div>
  </div>
</div>
