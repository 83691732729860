import { Component, OnDestroy, OnInit } from '@angular/core';
import { PractitionerDataService } from 'src/app/modules/abilycare-data-access/api/practitioner-data.service';
import { FileDownloadControllerService, Practitioner } from '@abilycare/dal-client';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { saveAs } from 'file-saver';
import { LocalDatePipe } from 'src/app/modules/time/pipes/localdate.pipe';
import { Router } from '@angular/router';
import { EventTrackingService } from 'src/app/services/event-tracking.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public practitionerMe: Practitioner;
  private subscriptions: Subscription[] = [];
  public files: string[] = [];
  private fileName: string = '';
  public showFiles: boolean = false;
  private errorLogged = false;

  constructor(
    private practitionerDataService: PractitionerDataService,
    private fileTransfertService: FileDownloadControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
    private localDatePipe: LocalDatePipe,
    private router: Router,
  ) {}

  downloadExport() {
    const exportDate = this.localDatePipe.parse(new Date(), 'dd_MM_yyyy_HH:mm:ss');
    const fileNametoSave = 'export_abilycare_' + exportDate + '.zip';
    this.fileTransfertService.fileDownloadControllerDownloadFile(this.fileName).subscribe((data) => {
      saveAs(data, fileNametoSave);
      this.showFiles = false;
      this.goTo('/Notifications');
    });
  }

  ngOnInit() {
    this.eventTrackingService.init();
    this.practitionerDataService.getPractitionerMe().subscribe(
      (practitioner) => {
        this.practitionerMe = practitioner;
        this.fileName = 'exp-' + this.practitionerMe.id + '.zip';
        this.getFile(this.fileName);
      },
      (error) => {
        console.error('NotificationsComponent : Err-Practitionner-01 ', error);
        const errMsg = this.translateService.instant('Err-Practitionner-01');
        this.snackBarService.show(errMsg, 'danger');
        if (!this.errorLogged) {
          this.eventTrackingService.logError('Err-Practitionner-01', error);
          this.errorLogged = true;
        }
      },
    );
  }

  getFile(fileName: string) {
    this.fileTransfertService.fileDownloadControllerListFiles().subscribe(
      (res) => {
        const exportFiles = res.filter((file) => file.includes(fileName));
        if (exportFiles.length > 0) {
          this.files.push(exportFiles[0]);
          this.showFiles = true;
        }
      },
      (error) => {
        console.error('NotificationsComponent: Err-Exp-04 ', error);
        const errMsg = this.translateService.instant('Err-Exp-04');
        this.snackBarService.show(errMsg, 'danger');
        if (!this.errorLogged) {
          this.eventTrackingService.logError('Err-Exp-04', error);
          this.errorLogged = true;
        }
      },
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  goTo(page) {
    this.router.navigate([page]);
  }
}
