<div class="shoes-selector">
  <div class="row">
    <div class="col-lg-12">
      <div class="page-sub-title">{{ 'app.romberg.shoes.type' | translate }}</div>
      <select [ngModel]="value.shoesType" (ngModelChange)="setShoes($event)">
        <option *ngFor="let shoes of footWears" [ngValue]="shoes.id">{{ shoes.translate }}</option>
      </select>
    </div>
  </div>
  <!--
  <div class="row">
    <div class="col-lg-12">
      <div class="page-sub-title">{{ 'app.romberg.help.stability' | translate }}</div>
      <select [ngModel]="value.helpStability" (ngModelChange)="setHelp($event)">
        <option *ngFor="let help of helpStability" [ngValue]="help.translate">{{ help.translate }}</option>
      </select>
    </div>
  </div> -->
  <div class="row">
    <div class="col-lg-12">
      <span class="page-sub-title">{{ 'app.romberg.help.stability' | translate }}</span>
      <div class="row">
        <div class="col-lg-6">
          <div *ngFor="let help of helpStability; let i = index" [hidden]="i % 2 !== 0" class="checkbox-item">
            <input
              type="checkbox"
              [value]="help.id"
              [checked]="value.helpStability && value.helpStability.includes(help.id)"
              (change)="onCheckboxChange($event, help.id)"
            />
            &nbsp;
            <label>{{ help.translate }}</label>
          </div>
        </div>
        <div class="col-lg-6">
          <div *ngFor="let help of helpStability; let i = index" [hidden]="i % 2 === 0" class="checkbox-item">
            <input
              type="checkbox"
              [value]="help.id"
              [checked]="value.helpStability && value.helpStability.includes(help.id)"
              (change)="onCheckboxChange($event, help.id)"
            />
            &nbsp;
            <label>{{ help.translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
