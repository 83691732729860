import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { ShoesSelectorValues } from '../../force-platform';
import { AnalysisResults } from '../../romberg/models/analysis-results.model';
import { FootwearControllerService, WalkingAidControllerService } from '@abilycare/dal-client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rapport-romberg-part',
  templateUrl: './rapport-romberg-part.component.html',
  styleUrls: ['../rapport.common.scss', './rapport-romberg-part.component.scss'],
})
export class RapportRombergPartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  public shoes: ShoesSelectorValues;

  @Input()
  public analysisResult: AnalysisResults;

  public scoreYO: number;
  public scoreYF: number;

  public surfaceYO: number;
  public surfaceYF: number;

  public swayDensityYO: number;
  public swayDensityYF: number;

  @Input()
  public openedEyesLeftSupport: number;
  @Input()
  public closedEyesLeftSupport: number;

  @Input()
  public typePlate: string;

  public symetrieOpenValues: string;
  public symetrieCloseValues: string;

  public shoesType: string = '';
  public helpStability: string = '';

  constructor(
    private walkingAidService: WalkingAidControllerService,
    private footWearService: FootwearControllerService,
    private translateService: TranslateService,
  ) {}

  public symetryoptions = {
    hasNeedle: true,
    outerNeedle: true,
    needleColor: 'rgb(250, 16, 119)',
    rangeLabel: ['G', 'D'],
    centralLabel: '2',
    rangeLabelFontSize: 32,
    arcColors: ['rgb(107, 137, 159)', 'rgb(182, 213, 230)', 'rgb(0,0,0)', 'rgb(182, 213, 230)', 'rgb(107, 137, 159)'],
    arcDelimiters: [25, 49.5, 50.5, 75],
  };

  @ViewChild('graphSurfaceYO', { static: false }) public graphSurfaceYO: ElementRef<HTMLCanvasElement>;
  @ViewChild('graphSurfaceYF', { static: false }) public graphSurfaceYF: ElementRef<HTMLCanvasElement>;

  public ngOnInit() {
    this.footWearService
      .footwearControllerFindById(this.shoes.shoesType, this.translateService.currentLang)
      .subscribe((res) => {
        this.shoesType = res.translate;
      });
    this.walkingAidService
      .walkingAidControllerFindByIds(this.shoes.helpStability + '', this.translateService.currentLang)
      .subscribe((res) => {
        const walkingAids = res.map((walkingAid) => walkingAid.translate);
        this.helpStability = walkingAids.join(' - ');
      });
  }

  ngAfterViewInit(): void {
    if (this.analysisResult) {
      this.updateSurfaceGraphs();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if not all the needed value here, return;
    if (!this.analysisResult) {
      return;
    }

    this.scoreYO = Math.floor(this.analysisResult.ScoreYO * 100) / 100;
    this.scoreYF = Math.floor(this.analysisResult.ScoreYF * 100) / 100;

    this.surfaceYO = Math.floor(this.analysisResult.SurfaceYO * 100) / 100;
    this.surfaceYF = Math.floor(this.analysisResult.SurfaceYF * 100) / 100;

    this.swayDensityYO = Math.floor(this.analysisResult.SwayDensityYO * 100) / 100;
    this.swayDensityYF = Math.floor(this.analysisResult.SwayDensityYF * 100) / 100;

    this.openedEyesLeftSupport = 100 - this.openedEyesLeftSupport;
    this.closedEyesLeftSupport = 100 - this.closedEyesLeftSupport;

    this.symetrieOpenValues =
      (100 - this.openedEyesLeftSupport).toFixed(0) + '%' + ' | ' + this.openedEyesLeftSupport.toFixed(0) + '%';
    this.symetrieCloseValues =
      (100 - this.closedEyesLeftSupport).toFixed(0) + '%' + ' | ' + this.closedEyesLeftSupport.toFixed(0) + '%';

    this.updateSurfaceGraphs();
  }

  public updateSurfaceGraphs() {
    const surfaceMax = 100;
    const graphsurfaceYOValue = Math.min(...[100, (this.surfaceYO * 100) / surfaceMax]);
    const graphsurfaceYFValue = Math.min(...[100, (this.surfaceYF * 100) / surfaceMax]);
    if (this.graphSurfaceYO && this.graphSurfaceYF) {
      this.drawCircle(graphsurfaceYOValue, this.graphSurfaceYO);
      this.drawCircle(graphsurfaceYFValue, this.graphSurfaceYF);
    }
  }

  // Circle surface YO et YF dans be a custom component.
  private drawCircle(radius: number, canvaRef: ElementRef) {
    const canvasCircle = canvaRef.nativeElement as HTMLCanvasElement;
    const context = canvasCircle.getContext('2d');
    const centerX = canvasCircle.width / 2;
    const centerY = canvasCircle.height / 2;

    context.beginPath();
    // context.arc(centerX, centerY, 100 * 1.13, 0, 2 * Math.PI, false);
    context.arc(centerX, centerY, 64, 0, 2 * Math.PI, false);
    context.lineWidth = 2;
    context.strokeStyle = '#003300';
    context.stroke();

    context.beginPath();
    // context.arc(centerX, centerY, radius * 1.13, 0, 2 * Math.PI, false);
    context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    context.fillStyle = '#FB607F';
    context.fill();
    context.lineWidth = 2;
    context.strokeStyle = '#FB607F';
    context.stroke();
  }
}
