import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { WiiBoardService } from 'src/app/modules/force-platform';
import { WiiBoardState } from 'src/app/modules/force-platform/models/wiiboard-state.model';
import { map } from 'rxjs/operators';
import * as bluetoothConst from 'src/app/tools/bluetooth.device.constants';
import { EtatYeux } from 'src/app/modules/abilycare-data-access/model/romberg-test.model';
import { KinventData } from 'src/app/modules/force-platform/models/kinvent-data.model';
import { PlateState } from '../../modules/force-platform/models/plate-state.model';
import { TranslateService } from '@ngx-translate/core';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-information-connexion',
  templateUrl: './information-connexion.component.html',
  styleUrls: ['./information-connexion.component.scss'],
})

/**
 * This component representes the informative div of the connexion with wiiBoard
 */
export class InformationConnexionComponent implements OnInit, OnDestroy {
  /* Variable for Wii Connection
   */
  public readonly wiiConnected: Observable<boolean>;
  public readonly wiiState: Observable<WiiBoardState>;

  public wiiBoardAvailable: boolean;

  public loadingConnect: boolean;
  public wiiConnect: boolean;

  wiiboardService: WiiBoardService;

  /**
   * Variables for plate connection
   */

  @Input() config: boolean;
  public KinventConnected: boolean;
  public synchActivate: boolean;

  public plateStateLeft: PlateState;
  public plateStateRight: PlateState;
  public stopscanning: boolean;
  public synchroMessageReceived: boolean;
  public testOnProgress: boolean;

  subject$ = new Subject();
  public measures = this.subject$ as Observable<any>;
  public request: string;
  public lastCheckout: Date;

  public timerValue: number = 0;
  interval: any;

  constructor(
    wiiboardService: WiiBoardService,
    private translateService: TranslateService,
    private snackBarService: SnackBarService,
  ) {
    this.wiiboardService = wiiboardService;
    this.wiiState = wiiboardService.wiiState.pipe(/*tap((v) => console.log('WIIST', v))*/);
    this.wiiConnected = this.wiiState.pipe(map((state) => state && state.wiiBoardConnected));

    //create plateStateObject
    this.plateStateLeft = {
      orientation: 'LEFT',
      plateDevice: undefined,
      plateAvailable: false,
      plateConnected: false,
      plateCalibrated: false,
      baseline: 0,
      baselineArray: [],
      baseLineArrayDetail: [],
      acquisitionActive: false,
      serviceBattery: undefined,
      characteristicBattery: undefined,
      characteristicKeepAlive: undefined,
      serviceData: undefined,
      characteristicData: undefined,
      characteristicStartSampling: undefined,
      characteristicStopSampling: undefined,
      serviceSync: undefined,
      characteristicReadSync: undefined,
      characteristicWriteSync: undefined,
      characteristicActiveSync: undefined,
      characteristicResponseSync: undefined,
      batteryLevel: 0,
      dataRecordedArray: [],
    };

    this.plateStateRight = {
      orientation: 'RIGHT',
      plateDevice: undefined,
      plateAvailable: false,
      plateConnected: false,
      plateCalibrated: false,
      baseline: 0,
      baselineArray: [],
      baseLineArrayDetail: [],
      acquisitionActive: false,
      serviceBattery: undefined,
      characteristicBattery: undefined,
      characteristicKeepAlive: undefined,
      serviceData: undefined,
      characteristicData: undefined,
      characteristicStartSampling: undefined,
      characteristicStopSampling: undefined,
      serviceSync: undefined,
      characteristicReadSync: undefined,
      characteristicWriteSync: undefined,
      characteristicActiveSync: undefined,
      characteristicResponseSync: undefined,
      batteryLevel: 0,
      dataRecordedArray: [],
    };
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.timerValue++;
    }, 1000); // Augmente timerValue toutes les secondes
  }

  stopTimer() {
    this.timerValue = 0;
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  ngOnInit() {
    if (!this.config) {
      this.scanBlutoothPlatform('RIGHT');
      this.scanBlutoothPlatform('LEFT');
    }
  }

  ngOnDestroy() {
    this.stopscanning = true;
    this.stopTimer();
    try {
      if (this.plateStateLeft.plateDevice) this.plateStateLeft.plateDevice.gatt.disconnect();
      if (this.plateStateRight.plateDevice) this.plateStateRight.plateDevice.gatt.disconnect();
      console.log(`kinventLeftDevice and kinventRightDevice gatt disconnected`);
    } catch (e) {
      console.log(`disconnect. gatt disconnect`, e);
    } finally {
      this.plateStateLeft.plateDevice = undefined;
      this.plateStateRight.plateDevice = undefined;
    }
  }

  async connectKForceDevice(orientation: string) {
    console.log('start connection device');
    try {
      const plateDevice = await navigator.bluetooth
        .requestDevice({
          filters: [
            {
              namePrefix: orientation == 'LEFT' ? bluetoothConst.PlateLPrefixe : bluetoothConst.PlateRPrefixe,
            },
          ],
          optionalServices: [
            bluetoothConst.PlateUIServiceBattery,
            bluetoothConst.PlateUIServiceData,
            bluetoothConst.PlateServiceSync,
          ],
        })
        .then((device) => {
          console.log('Connecting to GATT Server...');
          if (orientation == 'LEFT') {
            this.plateStateLeft.plateDevice = device;
            return this.connectToBluetoothPlate(this.plateStateLeft, false, false);
          } else {
            this.plateStateRight.plateDevice = device;
            return this.connectToBluetoothPlate(this.plateStateRight, false, false);
          }
        });
    } catch (err) {
      console.error('InformationConnexionComponent : Err-BLE-01 ', err);
      const errMsg = this.translateService.instant('Err-BLE-01');
      this.snackBarService.show(errMsg, 'danger');
    }
  }

  getSupportedProperties(characteristic) {
    let supportedProperties = [];
    for (const p in characteristic.properties) {
      if (characteristic.properties[p] === true) {
        supportedProperties.push(p.toUpperCase());
      }
    }
    return '[' + supportedProperties.join(', ') + ']';
  }

  async scanBlutoothPlatform(orientation: string) {
    console.log('Requesting any Bluetooth Device...');
    navigator.bluetooth
      .getDevices()
      .then(async (devices) => {
        console.log('devices list is ', devices);
        for (const device of devices) {
          if (orientation == 'RIGHT' && device.name.includes(bluetoothConst.PlateRPrefixe)) {
            this.startTimer();
            this.plateStateRight.plateAvailable = true;
            this.plateStateRight.plateDevice = await device;
            await this.plateStateRight.plateDevice.watchAdvertisements().then(() => {
              this.connectToBluetoothPlate(this.plateStateRight, true, true);
            });
          } else if (orientation == 'LEFT' && device.name.includes(bluetoothConst.PlateLPrefixe)) {
            this.startTimer();
            this.plateStateLeft.plateAvailable = true;
            this.plateStateLeft.plateDevice = await device;
            await this.plateStateLeft.plateDevice.watchAdvertisements().then(() => {
              this.connectToBluetoothPlate(this.plateStateLeft, true, true);
            });
          }
        }
      })
      .then(() => {
        if (!this.plateStateLeft.plateAvailable && !this.plateStateRight.plateAvailable) {
          console.log('InformationConnexionComponent: Err-BLE-03');
          const errMsg = this.translateService.instant('Err-BLE-03');
          this.snackBarService.show(errMsg, 'danger');
        }
      })
      .catch((error) => {
        this.sleep(5000);
        console.log('Error in scanBlutoothPlatform! ' + error);
        if (!this.stopscanning) this.scanBlutoothPlatform(orientation);
      });
    shareReplay(1);
  }
  onForgetBluetoothDeviceButtonClick(orientation: string) {
    navigator.bluetooth
      .getDevices()
      .then((devices) => {
        for (const device of devices) {
          if (
            (orientation == 'LEFT' && device.name.includes(bluetoothConst.PlateLPrefixe)) ||
            (orientation == 'RIGHT' && device.name.includes(bluetoothConst.PlateRPrefixe))
          ) {
            if (!device) {
              throw new Error('No Bluetooth device to forget');
            }
            console.log('Forgetting ' + device.name + 'Bluetooth device...');
            return device.forget();
          }
        }
      })
      .then(() => {
        console.log('  > Bluetooth device has been forgotten.');
      })
      .catch((error) => {
        console.log('InformationConnexionComponent: Err-BLE-02', error);
        const errMsg = this.translateService.instant('Err-BLE-02');
        this.snackBarService.show(errMsg, 'danger');
      });
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async connectToBluetoothPlate(plateState: PlateState, activeSync: boolean, firstConnection: boolean) {
    console.log('call connectToBluetoothPlate - device ', plateState);
    await plateState.plateDevice.gatt
      .connect()
      .then((server) => {
        //console.log('Getting V3 : server...', server);
        return server.getPrimaryService(bluetoothConst.PlateUIServiceBattery);
      })
      .then((service) => {
        //console.log('Getting Battery Level Characteristic...');
        plateState.serviceBattery = service;
        // plateState.plateDevice.addEventListener('gattserverdisconnected', this.verifConnectionDevice.bind(this));
        return service.getCharacteristic(bluetoothConst.PlateCharacteristicBattery);
      })
      .then((characteristic) => {
        //console.log('Reading Battery Level...');
        plateState.characteristicBattery = characteristic;
        return characteristic.readValue();
      })
      .then((value) => {
        plateState.batteryLevel = value.getUint8(0);
        plateState.plateConnected = true;
      })
      .then(() => {
        if (activeSync) this.setOtherCharacetristic(plateState);
      })
      .catch((error) => {
        if (this.timerValue > 5) {
          console.log('InformationConnexionComponent: Err-BLE-08 ', error);
          const errMsg = this.translateService.instant('Err-BLE-08');
          this.snackBarService.show(errMsg, 'danger');
        }
      })
      .finally(() => {
        firstConnection = false;
        if (!plateState.plateConnected) this.connectToBluetoothPlate(plateState, activeSync, false);
      });
  }

  recalibrate() {
    this.synchActivate = false;
    this.launchSynchro();
  }

  async launchSynchro() {
    console.log('start launch synchro');
    this.KinventConnected = this.plateStateLeft.plateConnected && this.plateStateLeft.plateConnected;
    if (this.plateStateLeft.plateConnected && this.plateStateLeft.plateConnected)
      this.activateSynchro(this.plateStateLeft, this.plateStateRight);
    console.log('end launch synchro');
  }

  async startMeasure(plateState: PlateState) {
    console.log('start measure by getting data ', plateState);
    await plateState.characteristicData.addEventListener('characteristicvaluechanged', (event) => {
      this.handleValueChanged(event, plateState);
    });
    // ('characteristicvaluechanged', this.handleValueChanged.bind(null, event, plateState),false);
    //console.log('add event listner on characteristic');
    await this.sleep(500);
    //console.log('start start notifications of characteristic Data');
    await plateState.characteristicData.startNotifications();
    //console.log('end start notifications of characteristic Data');
    await this.startBaseline(plateState);
  }

  async startBaseline(plateState: PlateState) {
    console.log('getBaseline for ');
    this.request = 'Get_Baseline';
    await this.keepAlive(plateState);
    await this.sleep(1000);
    if (plateState.characteristicData) {
      //await console.log('getBaseline: start sampling ', orientation);
      await this.startSampling(plateState);
      await this.sleep(2000);
    }
  }

  async getAverage(dataArray: number[]) {
    return dataArray.reduce((p, c) => p + c, 0) / dataArray.length;
  }

  async getAverageBaseLine(plateState) {
    plateState.baseline = plateState.baselineArray.reduce((p, c) => p + c, 0) / plateState.baselineArray.length;
  }

  async getBaseline(value, plateState: PlateState) {
    //console.log('getBaseLine for plate ', plateState.orientation);
    //let dateTime = new Date();
    //const nbMSecMeasure = bluetoothConst.PLATE_TIME_BASELINE * 1000;
    //console.log("dateTime ", dateTime, " lastCheckout ", this.lastCheckout);
    // if (dateTime.getTime() - this.lastCheckout.getTime() <= nbMSecMeasure) {
    this.calculateBaseLine(value, plateState).then(() => {
      this.getAverageBaseLine(plateState);
      this.stopSampling(plateState);
      this.request = '';
      plateState.plateCalibrated = true;
      this.sleep(4000);
      this.synchActivate = this.plateStateLeft.plateCalibrated && this.plateStateRight.plateCalibrated;
      //console.log(" i'm in plate ", plateState.orientation, ' this.synchActivate is ', this.synchActivate);
    });
    /*
    }else {
      this.plateStateLeft.baseline = await this.getAverage(this.plateStateLeft.baselineArray);
      this.plateStateRight.baseline = await this.getAverage(this.plateStateRight.baselineArray);
      this.synchActivate = await true;
      await this.stopSampling(plateState);
      this.request = await '';
      this.plateStateLeft.plateCalibrated = await true;
      this.plateStateRight.plateCalibrated = await true;
    };*/
  }

  verifConnectionDevice(event) {
    const device = event.target.value;
    if (!device) {
      /*
      this.plateStateLeft.plateConnected = false;
      this.plateStateRight.plateConnected = false;
      this.KinventConnected = false;*/
      this.scanBlutoothPlatform('RIGHT');
      this.scanBlutoothPlatform('LEFT');
    }
  }

  async startSampling(plateState: PlateState) {
    //console.log('call startSampling for plate ', plateState);
    var bytestoWrite = new Uint8Array(8);
    let i: number;
    for (i = 0; i < 7; i++) bytestoWrite[i] = 0;
    plateState.characteristicStartSampling.writeValue(bytestoWrite);
  }

  async stopSampling(plateState: PlateState) {
    //sleep 2s before stop Sampling
    //await this.sleep(2000);
    var bytestoWrite = new Uint8Array(8);
    let i: number;
    for (i = 0; i < 7; i++) bytestoWrite[i] = await 0;
    await this.plateStateRight.characteristicStopSampling.writeValue(bytestoWrite);
    await this.plateStateLeft.characteristicStopSampling.writeValue(bytestoWrite);
    //force also stop the second plate
    /*
    if (plateState.orientation == 'LEFT') await this.stopSampling(this.plateStateRight);
    else await this.stopSampling(this.plateStateLeft);*/
  }

  async getValue() {
    this.plateStateLeft.dataRecordedArray = [];
    this.plateStateRight.dataRecordedArray = [];
    this.request = 'Get_Measure';
    await this.keepAlive(this.plateStateLeft);
    await this.keepAlive(this.plateStateRight);
    await this.sleep(1000);
    await this.startSampling(this.plateStateLeft);
    await this.startSampling(this.plateStateRight);
    this.lastCheckout = await new Date();
  }

  public valueRight = 0;
  public valueLeft = 0;

  async getMeasure(value, plateState: PlateState) {
    let dateTime = new Date();
    const nbMSecMeasure = bluetoothConst.PLATE_TIME_MEASURE * 1000;
    /*
    console.log(
      'get measure : nbMSecMeasure ',
      nbMSecMeasure,
      ' last checkout is ',
      this.lastCheckout,
      ' new date is ',
      dateTime,
    );*/
    if (dateTime.getTime() - this.lastCheckout.getTime() <= nbMSecMeasure) {
      /*
      console.log(
        'get Measure for weight dateTime.getTime() - this.lastCheckout.getTime() : ',
        dateTime.getTime() - this.lastCheckout.getTime(),
      );*/
      let measuresInPacket = await this.calculateWeight(value, plateState);
      let measure = await this.getAverage(measuresInPacket);
      if (plateState.orientation == 'LEFT') this.valueLeft = await measure;
      else this.valueRight = await measure;
      if (this.valueRight != 0 && this.valueLeft != 0) {
        await this.subject$.next(this.valueLeft + this.valueRight);
        this.valueLeft = await 0;
        this.valueRight = await 0;
      }
      //await console.log("value measure from dataView is ", this.valueLeft + this.valueRight);
    } else {
      await this.stopSampling(plateState);
      await this.sleep(1000);
      await this.keepAlive(plateState);
      await this.sleep(1000);
      this.request = '';
      this.subject$.complete();
      console.log('end measure for weight');
      //create a new subject for the next measure
      this.subject$.unsubscribe();
      this.subject$ = new Subject();
      this.measures = this.subject$ as Observable<string>;
    }
  }

  buf2hex(buffer) {
    // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)].map((x) => x.toString(16).padStart(2, '0')).join('');
  }

  async record(yeux: EtatYeux) {
    this.plateStateLeft.dataRecordedArray = [];
    this.plateStateRight.dataRecordedArray = [];
    this.request = 'Record_Measure';
    //await this.resetTimestampParameter();
    await this.keepAlive(this.plateStateLeft);
    await this.keepAlive(this.plateStateRight);
    await this.sleep(1000);
    await this.startSampling(this.plateStateLeft);
    await this.startSampling(this.plateStateRight);
    this.lastCheckout = await new Date();
    console.log('Start Record for yeux : ', EtatYeux[yeux], ' lastchekout is ', this.lastCheckout);
  }

  async stopRecord() {
    this.subject$.unsubscribe();
    this.subject$ = new Subject();
    this.measures = this.subject$ as Observable<any>;
    this.request = '';
    await this.stopSampling(this.plateStateLeft);
    //await this.stopSampling(this.plateStateRight);
    await this.keepAlive(this.plateStateLeft);
    await this.keepAlive(this.plateStateRight);
    await this.sleep(1000);
  }

  async recordMeasure(value, plateState: PlateState) {
    let dateTime = new Date();
    const nbMSecMeasure = 30.99 * 1000;
    if (dateTime.getTime() - this.lastCheckout.getTime() <= nbMSecMeasure) {
      let dataRecorded: KinventData;
      let timestamp = await (value.getUint8(3) * 256 * 256 * 256 +
        value.getUint8(2) * 256 * 256 +
        value.getUint8(1) * 256 +
        value.getUint8(0));
      //await console.log('recordMeasure timestamp is ', timestamp);
      const buffer1 = new Uint8Array([value.getUint8(3), value.getUint8(2), , value.getUint8(1), value.getUint8(0)])
        .buffer;
      //timestamp = await timestamp / 1000;
      //await console.log("timestamp / 1000 ", timestamp);
      let i = 0;
      for (i = 0; i < 109; i = i + 12) {
        const rear_left =
          (await value.getUint8(6 + i)) * 256 * 256 + value.getUint8(5 + i) * 256 + value.getUint8(4 + i);
        const rear_right =
          (await value.getUint8(9 + i)) * 256 * 256 + value.getUint8(8 + i) * 256 + value.getUint8(7 + i);
        const front_right =
          (await value.getUint8(12 + i)) * 256 * 256 + value.getUint8(11 + i) * 256 + value.getUint8(10 + i);
        const front_left =
          (await value.getUint8(15 + i)) * 256 * 256 + value.getUint8(14 + i) * 256 + value.getUint8(13 + i);
        let measure = await (rear_left + rear_right + front_right + front_left);
        measure = (await (measure - plateState.baseline)) / 10000;
        dataRecorded = await {
          plate: plateState.orientation,
          originalTimeSTampHexa: this.buf2hex(buffer1),
          originalTimeSTamp: timestamp,
          timestamp: parseFloat((timestamp / 10000).toFixed(5)),
          rearLeft: (rear_left - plateState.baseLineArrayDetail[0]) / 10000,
          rearRight: (rear_right - plateState.baseLineArrayDetail[1]) / 10000,
          frontLeft: (front_left - plateState.baseLineArrayDetail[2]) / 10000,
          frontRight: (front_right - plateState.baseLineArrayDetail[3]) / 10000,
        };
        await this.subject$.next(dataRecorded);
        await plateState.dataRecordedArray.push(dataRecorded);
        timestamp = (await timestamp) + (1 / 75) * 10000;
      }
    } else {
      await this.subject$.complete();
      this.request = await '';
      await this.stopSampling(plateState);
      await this.subject$.unsubscribe();
      this.subject$ = await new Subject();
      this.measures = (await this.subject$) as Observable<any>;
    }
  }

  async calculateBaseLine(value: DataView, plateState: PlateState) {
    let i = 0;
    for (i = 0; i < 109; i = i + 12) {
      const rear_left = (await value.getUint8(6 + i)) * 256 * 256 + value.getUint8(5 + i) * 256 + value.getUint8(4 + i);
      const rear_right =
        (await value.getUint8(9 + i)) * 256 * 256 + value.getUint8(8 + i) * 256 + value.getUint8(7 + i);
      const front_right =
        (await value.getUint8(12 + i)) * 256 * 256 + value.getUint8(11 + i) * 256 + value.getUint8(10 + i);
      const front_left =
        (await value.getUint8(15 + i)) * 256 * 256 + value.getUint8(14 + i) * 256 + value.getUint8(13 + i);
      let baseline = await (rear_left + rear_right + front_right + front_left);
      plateState.baseLineArrayDetail = [rear_left, rear_right, front_left, front_right];
      plateState.baselineArray.push(baseline);
    }
    return plateState.baselineArray;
  }

  async calculateWeight(value: DataView, plateState: PlateState) {
    let measuresInPacket: number[] = [];
    const timestamp =
      (await (value.getUint8(3) * 256 * 256 * 256 +
        value.getUint8(2) * 256 * 256 +
        value.getUint8(1) * 256 +
        value.getUint8(0))) / 10000;
    //measuresInPacket.push(timestamp);
    let i = 0;
    for (i = 0; i < 109; i = i + 12) {
      const rear_left = (await value.getUint8(6 + i)) * 256 * 256 + value.getUint8(5 + i) * 256 + value.getUint8(4 + i);
      const rear_right =
        (await value.getUint8(9 + i)) * 256 * 256 + value.getUint8(8 + i) * 256 + value.getUint8(7 + i);
      const front_right =
        (await value.getUint8(12 + i)) * 256 * 256 + value.getUint8(11 + i) * 256 + value.getUint8(10 + i);
      const front_left =
        (await value.getUint8(15 + i)) * 256 * 256 + value.getUint8(14 + i) * 256 + value.getUint8(13 + i);
      let measure = await (rear_left + rear_right + front_right + front_left);
      measure = (await (measure - plateState.baseline)) / 10000;
      await measuresInPacket.push(measure);
    }
    //console.log("meseaue sur un packet : plate ", plateState.orientation);
    //console.log("meseaue sur un packet ", measuresInPacket);
    return await measuresInPacket;
  }

  handleValueChanged = (event, plateState: PlateState) => {
    const value: DataView = event.target.value;
    //console.log("i'm in handleValueChanged value is ", value, ' from plate ', plateState.orientation);
    if (value.byteLength > 124) {
      //console.log("firstpacket with 136 don't do anything, just wait");
    } else {
      if (this.request == 'Get_Baseline') {
        this.getBaseline(value, this.plateStateLeft);
        this.getBaseline(value, this.plateStateRight);
      } else if (this.request == 'Get_Measure') {
        this.getMeasure(value, plateState);
      } else if (this.request == 'Record_Measure') {
        this.recordMeasure(value, plateState);
      } else if (this.request == 'Start_Sync') {
        //wait to get synchro message
        this.sleep(600);
        this.synchroMessageReceived = value != undefined;
      }
    }
  };

  async setOtherCharacetristic(plateState: PlateState) {
    //console.log('start setOtherCharacetristic ', new Date().getTime());
    //characteristic Sync
    plateState.serviceSync = await plateState.plateDevice.gatt.getPrimaryService(bluetoothConst.PlateServiceSync);
    await this.sleep(600);
    plateState.characteristicReadSync = await plateState.serviceSync.getCharacteristic(
      bluetoothConst.PlateCharacteristicReadSync,
    );
    await this.sleep(600);
    plateState.characteristicWriteSync = await plateState.serviceSync.getCharacteristic(
      bluetoothConst.PlateCharacteristicWriteSync,
    );
    await this.sleep(600);
    plateState.characteristicActiveSync = await plateState.serviceSync.getCharacteristic(
      bluetoothConst.PlateCharacteristicStartSync,
    );
    await this.sleep(600);
    plateState.characteristicResponseSync = await plateState.serviceSync.getCharacteristic(
      bluetoothConst.PlateCharacteristicResponseSync,
    );
    await this.sleep(600);
    //characteristic battery and keep Alive
    plateState.characteristicKeepAlive = await plateState.serviceBattery.getCharacteristic(
      bluetoothConst.PlateCharacteristicKeepAlive,
    );
    //characteristic Data
    plateState.serviceData = await plateState.plateDevice.gatt.getPrimaryService(bluetoothConst.PlateUIServiceData);
    await this.sleep(600);
    plateState.characteristicData = await plateState.serviceData.getCharacteristic(
      bluetoothConst.PlateCharacteristicData,
    );
    await this.sleep(600);
    plateState.characteristicStartSampling = await plateState.serviceData.getCharacteristic(
      bluetoothConst.PlateCharacteristicStartDataMeasure,
    );
    await this.sleep(600);
    plateState.characteristicStopSampling = await plateState.serviceData.getCharacteristic(
      bluetoothConst.PlateCharacteristicStopDataMeasure,
    );

    // console.log('end setOtherCharacetristic ', new Date().getTime());

    await this.launchSynchro();

    // await console.log("setSyncServiceAndCharacteristic plateState is ", plateState);
  }

  keepAlive(plateState: PlateState) {
    var bytestoWrite = new Uint8Array(1);
    bytestoWrite[0] = 0;
    //console.log('Keep Alive value is ', bytestoWrite[0]);
    plateState.characteristicKeepAlive.writeValue(bytestoWrite);
  }
  public notificationStartedForSync: boolean = false;
  //process to activate sync
  async activateSynchro(plateStateLeft: PlateState, plateStateRight: PlateState) {
    //config for master and get adress
    try {
      //console.log('start add listner on characteristicResponseSync');
      await plateStateRight.characteristicResponseSync.addEventListener('characteristicvaluechanged', (event) => {
        this.handleResponseSynchro(event);
        //console.log('end add listner on characteristicResponseSync');
      });
    } catch (Error) {
      console.log('InformationConnexionComponent: Err-BLE-04 ', Error);
      const errMsg = this.translateService.instant('Err-BLE-04');
      this.snackBarService.show(errMsg, 'danger');
    }
    let masterAdress = await this.getSynchroAdress(plateStateLeft);
    //console.log("activateSynchro : masterAdress ", masterAdress);
    await this.sleep(200);
    await this.setSyncMode(plateStateLeft, 1);
    await this.sleep(200);
    await this.startSync(plateStateLeft);
    await this.sleep(200);
    await this.setSlaveAdress(plateStateRight, masterAdress);
    await this.sleep(200);
    if (!this.notificationStartedForSync) {
      //console.log('start notifications for characteristicResponseSync');
      this.request = await 'Start_Sync';
      await plateStateRight.characteristicResponseSync.startNotifications();
      this.notificationStartedForSync = true;
      //console.log('end notifications for characteristicResponseSync ', this.notificationStartedForSync);
    } else {
      console.log('notifications are already done for characteristicResponseSync');
    }
    this.sleep(600);

    //} catch (Error) {
    //  console.log('error starting notification in characteristicResponseSync');
    //}
    await this.setSyncMode(plateStateRight, 0);
    await this.sleep(200);
    await this.startSync(plateStateRight);
    await this.sleep(5000);
    await this.startMeasureForPlate();
  }

  startMeasureForPlate() {
    this.startMeasure(this.plateStateLeft);
    this.startMeasure(this.plateStateRight);
  }

  handleResponseSynchro(event) {
    const value: DataView = event.target.value;
    //console.log('listner en plate pour la synchro ', value);
  }

  getSynchroAdress(plateState: PlateState) {
    //console.log('start stopSampling ');
    //console.log("plateState.characteristicReadSync ", plateState.characteristicReadSync);
    return plateState.characteristicReadSync.readValue();
  }

  setSyncMode(plateState: PlateState, syncMode: number) {
    var bytestoWrite = new Uint8Array(1);
    bytestoWrite[0] = syncMode;
    plateState.characteristicWriteSync.writeValue(bytestoWrite);
  }

  setSlaveAdress(plateState: PlateState, masterAdresse: any) {
    masterAdresse.setInt8(4, 1);
    const slaveAdresse = masterAdresse;
    //console.log('slave adresse ', slaveAdresse);
    plateState.characteristicReadSync.writeValue(slaveAdresse);
  }

  startSync(plateState: PlateState) {
    var bytestoWrite = new Uint8Array(1);
    bytestoWrite[0] = 1;
    plateState.characteristicActiveSync.writeValue(bytestoWrite);
  }

  /**
   * Test with wiiBoadService if board is available.
   */
  public isWiiBoardAvailable(): void {
    const that = this;
    this.loadingConnect = true;
    this.wiiboardService.wiiBoardConnect().then(
      (resp) => {
        that.wiiBoardAvailable = resp;
        this.loadingConnect = false;
        if (!that.wiiBoardAvailable) {
          // this.errorMessageEvent.emit('wiiBoardSyncError');
        } else {
          this.wiiConnect = resp;
          // this.wiiConnectedEvent.emit(this.wiiConnect);
        }
      },
      (err) => {
        that.wiiBoardAvailable = err;
        this.loadingConnect = false;
        // this.errorMessageEvent.emit('wiiBoardSyncError');
      },
    );
  }
}
