export class AnalysisParameters {
  // python3 db_manager.py –db_import 1.6 10 100 68 Test 2016-11-25_11-44-10 M 68 23.4 D sandale 0 1

  public taille: number;
  public distance: number;
  public frequence: number;
  public age: number;
  public login: string;
  public date: Date;
  public sexe: string;
  public poids: number;
  public imc: number;
  public lateralisation: string;
  public chaussure: number;
  public aide: number;
  public typebilan: number;
  public duree: number;
}
