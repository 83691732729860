<div class="section-2" *ngIf="analysisResult">
  <div class="row type-h">
    <div class="col-md-4 col-xs-12 d-flex">
      <div id="runner" class="col-md-2 col-xs-12 icon-container">
        <img src="assets/images/images/runner2x.png" alt="" />
      </div>
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'Rapport.TypeDeChaussure' | translate }}</div>
        <div class="info-2">{{ shoesType }}</div>
      </div>
    </div>

    <div class="col-md-4 col-xs-12 d-flex">
      <div id="runner" class="col-md-2 col-xs-12 icon-container">
        <img src="assets/images/images/runner2x.png" alt="" />
      </div>
      <div class="col-md-10 col-xs-12" *ngIf="shoes">
        <div class="info-1">{{ 'stabilityhelp' | translate }}</div>
        <div class="info-2">{{ helpStability }}</div>
      </div>
    </div>
  </div>

  <hr />

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/Score2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.Score' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.Score.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div>
        <ngx-gauge
          class="full-circle"
          [value]="scoreYO"
          type="full"
          append=""
          max="100"
          size="150"
          thick="10"
          cap="round"
          foregroundColor="rgba(250,16,119,1)"
          backgroundColor="rgba(182,213,230,1)"
        >
        </ngx-gauge>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div>
        <ngx-gauge
          class="full-circle"
          [value]="scoreYF"
          type="full"
          append=""
          max="100"
          size="150"
          thick="10"
          cap="round"
          foregroundColor="rgba(250,16,119,1)"
          backgroundColor="rgba(182,213,230,1)"
        >
        </ngx-gauge>
      </div>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/Surface2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.Surface' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.Surface.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 ">
      <div id="SurfaceYO" class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div class="surfaceGraph"><canvas #graphSurfaceYO width="150" height="130"></canvas></div>
      <div class="labelSurface">
        <label id="labelSurfaceYOValue" class="value">{{ surfaceYO | float: 1 }}</label> <label class="unit">cm²</label>
      </div>
    </div>
    <div class="col-md-3 col-xs-12">
      <div id="SurfaceYF" class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div class="surfaceGraph"><canvas #graphSurfaceYF width="150" height="130"></canvas></div>
      <div class="labelSurface">
        <label id="labelSurfaceYFValue" class="value">{{ surfaceYF | float: 1 }}</label> <label class="unit">cm²</label>
      </div>
    </div>
  </div>

  <div class="row mini-section" *ngIf="typePlate == 'Kinvent' && openedEyesLeftSupport != 100">
    <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/Symetrie2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Rapport.PrcSupport' | translate }}</div>
      <div class="titre-2">{{ 'Rapport.PrcSupport.Description' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div id="SurfaceYO" class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div>
        <app-gauge-chart
          canvasWidth="200"
          [needleValue]="openedEyesLeftSupport"
          [options]="symetryoptions"
          [bottomLabel]="symetrieOpenValues"
        ></app-gauge-chart>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 scoregraph">
      <div id="SurfaceYF" class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div>
        <app-gauge-chart
          canvasWidth="200"
          [needleValue]="closedEyesLeftSupport"
          [options]="symetryoptions"
          [bottomLabel]="symetrieCloseValues"
        ></app-gauge-chart>
      </div>
    </div>
  </div>

  <div class="row mini-section">
    <div class="col-md-2 col-xs-12 icon-container"><img src="assets/images/images/Sway Density2x.png" alt="" /></div>
    <div class="col-md-4 col-xs-12">
      <div class="titre-1">{{ 'Checkup.walk.SwayDensity' | translate }}</div>
      <div class="titre-2">{{ 'RapportPraticien.SwayDensity.Desc' | translate }}</div>
    </div>
    <div class="col-md-3 col-xs-12 swaygraph">
      <div class="titre-c">{{ 'Romberg.EyesOpen' | translate }}</div>
      <div>
        <ngx-gauge
          class="semi-circle"
          [value]="swayDensityYO"
          type="semi"
          append="s."
          max="30"
          size="150"
          thick="10"
          cap="round"
          foregroundColor="rgba(250,16,119,1)"
          backgroundColor="rgba(182,213,230,1)"
        >
          <ngx-gauge-value>{{ swayDensityYO | float: 1 }}</ngx-gauge-value>
        </ngx-gauge>
      </div>
    </div>
    <div class="col-md-3 col-xs-12 swaygraph">
      <div class="titre-c">{{ 'Romberg.EyesClosed' | translate }}</div>
      <div>
        <ngx-gauge
          class="semi-circle"
          [value]="swayDensityYF"
          type="semi"
          append="s."
          max="30"
          size="150"
          thick="10"
          cap="round"
          foregroundColor="rgba(250,16,119,1)"
          backgroundColor="rgba(182,213,230,1)"
        >
          <ngx-gauge-value>{{ swayDensityYF | float: 1 }}</ngx-gauge-value>
        </ngx-gauge>
      </div>
    </div>
  </div>
  <div class="row mini-section disclaimer">
    <div id="disclaimer" class="col-md-12 col-xs-12">{{ 'Rapport.disclaimer' | translate }}</div>
  </div>
</div>
