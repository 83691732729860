<app-custom-navbar [patient]="patient" [returnTitle]="returnTitle"></app-custom-navbar>
<app-history-navbar [patient]="patient"></app-history-navbar>
<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-3 text-center mb-3">
        <label>{{ 'Patient.DebutPeriod' | translate }}</label>
        <input
          class="form-control py-2 border-left-0 border bg-grey"
          type="date"
          value=""
          id="input-search"
          [(ngModel)]="startDate"
          (ngModelChange)="onStartDateChanged($event)"
        />
      </div>
      <div class="col-md-3 text-center mb-3">
        <label>{{ 'Patient.finPeriod' | translate }}</label>
        <input
          class="form-control py-2 border-left-0 border bg-grey"
          type="date"
          value=""
          id="input-search"
          [(ngModel)]="endDate"
          (ngModelChange)="onEndDateChanged($event)"
        />
      </div>
      <div class="col-md-6 text-center mb-5">
        <button
          *ngIf="!hasFilterApplicated"
          class="btn-tertiary text-uppercase"
          type="button"
          data-toggle="modal"
          data-target="#modalTestFilter"
          data-backdrop="static"
          data-keyboard="false"
        >
          <img src="assets/images/icons/filter.svg" width="22" height="33" />
          {{ 'Filtrer' | translate }}
        </button>
        <button
          *ngIf="hasFilterApplicated"
          class="btn-tertiary text-uppercase"
          type="button"
          data-toggle="modal"
          data-target="#modalTestFilter"
          data-backdrop="static"
          data-keyboard="false"
        >
          <img src="assets/images/icons/filter_applicated.svg" width="22" height="33" />
          {{ 'Filtrer' | translate }}
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 text-center mb-5">
        <mat-paginator
          #paginator
          [length]="listCourses.length"
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 50]"
          (page)="onChangePage($event)"
          class="btn-tertiary text-uppercase"
        >
        </mat-paginator>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-wrap">
          <table class="table">
            <thead class="thead-primary">
              <tr>
                <th>{{ 'Nav.Title.Bilan' | translate }}</th>
                <th>{{ 'Date' | translate }}</th>
                <th>{{ 'Scores' | translate }}</th>
                <th>{{ 'Course.id' | translate }}</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <!--  | filterDateRange: startDate:endDate-->
              <ng-container
                *ngFor="
                  let courseObject of listCourses
                    | slice: paginator.pageIndex * paginator.pageSize:(paginator.pageIndex + 1) * paginator.pageSize
                "
              >
                <tr class="alert" role="alert">
                  <td>{{ courseObject.name }}</td>
                  <td>{{ courseObject.date | localdate: 'datetime' }}</td>
                  <td>{{ courseObject.scores }}</td>
                  <td *ngIf="courseObject.courseId && courseObject.courseId > 0">
                    {{ 'Patient.Profil.course_fragility' | translate }}
                  </td>
                  <td *ngIf="!courseObject.courseId || courseObject.courseId < 0">
                    &nbsp;
                  </td>
                  <td>
                    <button
                      *ngIf="rolesCheck.hasManagePatient && courseObject.type != 4 && courseObject.type != 5"
                      class="btn btn-tertiary"
                      data-toggle="modal"
                      data-target="#dataNotAvailableModal"
                      data-backdrop="static"
                      data-keyboard="false"
                      (click)="printCourse(courseObject)"
                    >
                      {{ 'print' | translate }}
                    </button>
                    <label
                      *ngIf="dataNotAvailable && courseToPrint.id == courseObject.id"
                      for="DataError"
                      class="col-md-12 alert alert-danger"
                      >{{ 'app.test.data.error' | translate }}</label
                    >
                  </td>
                  <td>
                    <button
                      *ngIf="rolesCheck.hasManagePatient | async"
                      class="btn btn-tertiary"
                      data-toggle="modal"
                      (click)="viewCourseObject(courseObject)"
                    >
                      {{ 'app.bilan.voir' | translate }}
                    </button>
                  </td>
                  <td>
                    <button
                      *ngIf="rolesCheck.hasManagePatient && rolesCheck.hasDeleteData | async"
                      class="btn btn-tertiary"
                      data-target="#deleteCourseModal"
                      data-toggle="modal"
                      (click)="onClickDeleteCourse(courseObject)"
                    >
                      <i class="trash fa fa-trash"></i>
                      {{ 'Patient.Delete' | translate }}
                    </button>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Modal data not available
<div
  class="modal fade"
  id="dataNotAvailableModal"
  tabindex="-1"
  role="dialog"
  *ngIf="dataNotAvailable"
  aria-labelledby="dataNotAvailableModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dataNotAvailableModal">&nbsp;</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      
        {{ 'app.test.data.error' | translate }}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-tertiary" data-dismiss="modal" (click)="closeModal()">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>-->

<!-- Modal filter patient -->
<div
  class="modal fade"
  id="modalTestFilter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalTestFilterTitle"
  aria-hidden="true"
  #basicModalFilter
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTestFilterTitle">
          {{ 'Filter.resultats' | translate }}
        </h5>
        <button
          #btnCloseFilter
          type="button"
          class="close"
          (click)="closeModalFilter()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <mat-list>
            <mat-list-item *ngFor="let item of checkboxList">
              <mat-checkbox
                name="{{ item.id }}"
                [checked]="item.checked"
                [(ngModel)]="item.checked"
                [disabled]="item.disabled"
                [labelPosition]="item.labelPosition"
                (change)="listChange()"
                class="dropdown-item"
              >
                {{ item.name | translate }}
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
          <div class="flex-row div-find-filter modal-footer">
            <mat-checkbox
              class="btn-tertiary "
              name="selectChange"
              [(ngModel)]="allCheckboxChecked"
              [(indeterminate)]="allCheckboxIndeterminate"
              (change)="allCheckboxChange()"
              mat-button
            >
              <ng-container *ngIf="!allCheckboxChecked">{{ 'select.all' | translate }}</ng-container>
              <ng-container *ngIf="allCheckboxChecked">{{ 'deselect.all' | translate }}</ng-container>
            </mat-checkbox>
            <button class="btn btn-primary col-sm-4" (click)="onSubmit()" type="submit">
              {{ 'button.validation' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Modal delete course -->
<div
  class="modal fade"
  id="deleteCourseModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteCourseModalTitle"
  aria-hidden="true"
  #deleteCourseModal
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteCourseModalTitle">
          {{ 'app.test.delete.title' | translate }}
        </h5>
        <button #btnCloseDeleteCourse type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="courseToDelete">
        <div class="confirm-text">
          {{ 'app.test.delete.content' | translate }}
        </div>
        <div class="confirm-text" *ngIf="courseCanBeUpdated">
          {{ 'app.test.delete.test.inparcours.content' | translate }}
          {{ TestParcoursToDelete.enddate | localdate: 'datetime' }}
        </div>
      </div>
      <div class="flex-row justify-content-around modal-footer">
        <button class="btn btn-tertiary" (click)="deleteCourse()">{{ 'OUI' | translate }}</button>
        <button #btnNoDelete class="btn btn-primary" (click)="closeModalDeleteCourse()" autofocus>
          {{ 'Non' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
