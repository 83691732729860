import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EtatYeux } from 'src/app/modules/abilycare-data-access/model/romberg-test.model';
import { AppConfigurationService } from 'src/app/app-configuration.service';
import { InterceptorHttpParams } from '../../core/services/http/interceptor-http-params';
import { LocalDatePipe } from '../../time/pipes/localdate.pipe';
import { AcquisitionFile } from '../models/acquisition-file.model';
import { getActiveOffset } from '@angular/material/datepicker/typings/multi-year-view';

@Injectable()
export class KInventPlateService {
  private domesure: string = '/domesure?guid={guid}&date={date}&yeux={yeux}&duration={duration}';
  private pushPathMeasure: string = '/pushmesure?guid={guid}&date={date}&yeux={yeux}';
  private stopMesureSOS: string = '/stopMesure';
  private rombergProxyBaseUri: string = this.appConfigurationService.rombergProxyBaseUri;

  constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService,
    private localDatePipe: LocalDatePipe,
  ) {}

  public buildTempFolder(currentRecordingSessionId: string): Observable<any> {
    let url: string;
    url = this.rombergProxyBaseUri + '/rawFiles/SetNewAcquisition';
    url += '/' + currentRecordingSessionId;
    const body: any = {};
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post(url, null, { params });
  }

  e = 69;
  h = 146.5;
  l = 26.8;

  eWii = 220;
  hWii = 130;

  getWeight(data) {
    const weight = data.rearLeft + data.rearRight + data.frontLeft + data.frontRight;
    return weight;
  }

  getX(data) {
    const weight = data.rearLeft + data.rearRight + data.frontLeft + data.frontRight;
    const x = (this.e / weight) * (data.frontRight + data.rearRight - (data.rearLeft + data.frontLeft));
    return x;
  }

  getY(data) {
    const weight = data.rearLeft + data.rearRight + data.frontLeft + data.frontRight;
    const y = (this.h / weight) * (data.frontLeft + data.frontRight - (data.rearLeft + data.rearRight));
    return y;
  }

  getXBaryCenter(dataLeft, dataRight) {
    const xCenter =
      (1 / this.getTotalWeight(dataLeft, dataRight)) *
      ((this.getX(dataLeft) - this.l - this.e) * this.getWeight(dataLeft) +
        (this.l + this.e + this.getX(dataRight)) * this.getWeight(dataRight));
    return xCenter;
  }

  getYBaryCenter(dataLeft, dataRight) {
    const yCenter =
      (1 / this.getTotalWeight(dataLeft, dataRight)) *
      (this.getY(dataLeft) * this.getWeight(dataLeft) + this.getY(dataRight) * this.getWeight(dataRight));
    return yCenter;
  }

  public getTotalWeight(dataLeft, dataRight) {
    const totalWeight = this.getWeight(dataLeft) + this.getWeight(dataRight);
    return totalWeight;
  }

  public getValues(dataLeft, dataRight) {
    let newData = [];
    /*'BottomLeftCalcul_SensorsKG',
      'BottomRightCalcul_SensorsKG',
      'TopLeftCalcul_SensorsKG',
      'TopRightCalcul_SensorsKG',
    */
    if (
      this.hWii * this.getXBaryCenter(dataLeft, dataRight) - this.eWii * this.getYBaryCenter(dataLeft, dataRight) <=
      0
    ) {
      newData[0] = dataRight.timestamp;
      newData[1] =
        0 * this.getXBaryCenter(dataLeft, dataRight) +
        (-this.getTotalWeight(dataLeft, dataRight) / (2 * this.hWii)) * this.getYBaryCenter(dataLeft, dataRight) +
        (1 / 2) * this.getTotalWeight(dataLeft, dataRight);
      newData[2] = 0;
      newData[3] =
        (-this.getTotalWeight(dataLeft, dataRight) / (2 * this.eWii)) * this.getXBaryCenter(dataLeft, dataRight) +
        (this.getTotalWeight(dataLeft, dataRight) / (2 * this.hWii)) * this.getYBaryCenter(dataLeft, dataRight) +
        0 * this.getTotalWeight(dataLeft, dataRight);
      newData[4] =
        (this.getTotalWeight(dataLeft, dataRight) / (2 * this.eWii)) * this.getXBaryCenter(dataLeft, dataRight) +
        0 * this.getYBaryCenter(dataLeft, dataRight) +
        (1 / 2) * this.getTotalWeight(dataLeft, dataRight);
      return newData;
    } else {
      /*'BottomLeftCalcul_SensorsKG',
      'BottomRightCalcul_SensorsKG',
      'TopLeftCalcul_SensorsKG',
      'TopRightCalcul_SensorsKG',
    */
      newData[0] = dataRight.timestamp;
      newData[1] =
        (-this.getTotalWeight(dataLeft, dataRight) / (2 * this.eWii)) * this.getXBaryCenter(dataLeft, dataRight) +
        0 * this.getYBaryCenter(dataLeft, dataRight) +
        (1 / 2) * this.getTotalWeight(dataLeft, dataRight);
      newData[2] =
        (this.getTotalWeight(dataLeft, dataRight) / (2 * this.eWii)) * this.getXBaryCenter(dataLeft, dataRight) +
        (-this.getTotalWeight(dataLeft, dataRight) / (2 * this.hWii)) * this.getYBaryCenter(dataLeft, dataRight) +
        0 * this.getTotalWeight(dataLeft, dataRight);
      newData[3] = 0;
      newData[4] =
        0 * this.getXBaryCenter(dataLeft, dataRight) +
        (this.getTotalWeight(dataLeft, dataRight) / (2 * this.hWii)) * this.getYBaryCenter(dataLeft, dataRight) +
        (1 / 2) * this.getTotalWeight(dataLeft, dataRight);
      return newData;
    }
  }

  public prepareData(datas: any[]) {
    let datasPrepared: any[];
    datasPrepared = [];
    let i = 0;
    //first order data by timeStamp (position 2)
    datas = datas.sort((a, b) => a.timestamp - b.timestamp);
    for (i = 0; i < datas.length; i++) {
      const dataLine = datas[i];
      /*
      const newData = [
        dataLine.timestamp,
        dataLine.rearLeft,
        dataLine.rearRight,
        dataLine.frontLeft,
        dataLine.frontRight,
      ];
      datasPrepared.push(newData);*/
      if (i + 1 < datas.length) {
        const dataNextLine = datas[i + 1];
        if (
          dataLine.plate != dataNextLine.plate && //) {
          dataLine.timestamp == dataNextLine.timestamp
        ) {
          let newData: any;
          if (dataLine.plate == 'LEFT') newData = this.getValues(dataLine, dataNextLine);
          else newData = this.getValues(dataNextLine, dataLine);
          i++;
          datasPrepared.push(newData);
        }
      }
    }
    //console.log('datasPrepared is ', datasPrepared);
    return datasPrepared;
  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public pushMeasure(currentRecordingSessionId: string, date: string, yeux: EtatYeux, datas: any): Observable<any> {
    //créate temp folder
    console.log('push measure in kinvent service ');
    console.log('currentRecordingSessionId ', currentRecordingSessionId);
    console.log('date ', date);
    console.log('yeux ', yeux);
    //console.log('datas ', datas);
    let fileName = date + '_' + EtatYeux[yeux];
    const entete = [
      'TIMESTAMP',
      'BottomLeftCalcul_SensorsKG',
      'BottomRightCalcul_SensorsKG',
      'TopLeftCalcul_SensorsKG',
      'TopRightCalcul_SensorsKG',
    ];
    let arrayData = [];
    arrayData.push(entete.join('\t'));
    this.prepareData(datas).map((element) => {
      arrayData.push(element.join('\t'));
    });
    const content = arrayData.join('\n');
    //console.log('content is ', content);
    const blob = new Blob([content], { type: 'application/csv' });
    //don't need type informations
    const acquisitionFile: AcquisitionFile = {
      Blob: blob,
      Name: fileName,
    };
    //console.log('Acquisition file is content ', content);
    //save file to static url
    //this.sleep(5000);
    console.log('start send content file');
    let url: string;
    url = this.rombergProxyBaseUri + '/rawFiles/UploadRawFiles';
    url += '?guid=' + currentRecordingSessionId;
    url += '&filename=' + acquisitionFile.Name;
    const body: any = blob;

    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post(url, blob, { params });
  }

  //save original file for each eyes
  public SaveOriginalDataByPlate(
    currentRecordingSessionId: string,
    date: string,
    yeux: number,
    datas: any,
  ): Observable<any> {
    //créate temp folder
    let fileContent: string = '';
    let columHearders = [
      'Plate',
      'original hexa',
      'original TIMESTAMP',
      'TIMESTAMP',
      'BottomLeftCalcul_SensorsKG',
      'BottomRightCalcul_SensorsKG',
      'TopLeftCalcul_SensorsKG',
      'TopRightCalcul_SensorsKG',
    ];
    //console.log('save original data length ', datas.length);
    fileContent = columHearders.join('\t') + '\n';
    //tri par timestamp avant création du fichier
    //datas = datas.sort((a, b) => a.timestamp - b.timestamp);
    datas.forEach((dataLine) => {
      const newData = [
        dataLine.plate,
        dataLine.originalTimeSTampHexa,
        dataLine.originalTimeSTamp,
        dataLine.timestamp,
        dataLine.rearLeft,
        dataLine.rearRight,
        dataLine.frontLeft,
        dataLine.frontRight,
      ];
      fileContent += newData.join('\t') + '\n';
    });
    const todayDate = this.localDatePipe.parse(date, 'dd_MM_yyyyHH:mm:ss');
    const filename = 'original_data_' + yeux;
    const blob = new Blob([fileContent], { type: 'application/csv' });
    //save file to static url
    let url: string;
    url = this.rombergProxyBaseUri + '/rawFiles/UploadRawFiles';
    url += '?guid=' + currentRecordingSessionId;
    url += '&filename=' + filename;
    //url += '&comp=gz';
    const body: any = blob;
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    return this.http.post(url, blob, { params });
  }

  public async stopRecordingSOS(): Promise<boolean> {
    console.log(this.constructor.name + ': stop recording static SOS: ');
    const url = ''; //this.windowsServiceUrl + this.stopMesureSOS;
    // Disable notification of error, handled in gui
    const params = new InterceptorHttpParams({ noNotificationOnError: true });
    try {
      const resp = await this.http.post(url, null, { params }).toPromise();
      console.log(resp);
      return true;
    } catch (error) {
      return false;
    }
  }
}
