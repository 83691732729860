import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html', // Fichier HTML séparé
  styleUrls: ['./app-dialog.component.scss'], // Fichier SCSS séparé
})
export class DialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; message: string; yesLabel: string; noLabel: string },
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false); // Retourne "false" pour Non
  }

  onYesClick(): void {
    this.dialogRef.close(true); // Retourne "true" pour Oui
  }
}
