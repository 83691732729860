import { PatientPractitionerRelation, Practitioner, PractitionerControllerService } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { EventTrackingService } from 'src/app/services/event-tracking.service';

@Component({
  selector: 'app-patient-delegate-item',
  templateUrl: './patient-delegate-item.component.html',
  styleUrls: ['./patient-delegate-item.component.scss'],
})
export class PatientDelegateItemComponent implements OnInit, OnDestroy {
  @Output() selectPractitionner: EventEmitter<{ list: Array<any>; type: string }> = new EventEmitter<{
    list: Array<any>;
    type: string;
  }>();
  @Output() deletePractitionner: EventEmitter<{ list: Array<any>; type: string }> = new EventEmitter<{
    list: Array<any>;
    type: string;
  }>();

  @Input() typeDelegation: string;
  @Input() practitionnersList: Array<Practitioner>;
  @Input() patientIdList: Array<number>;
  @Input() patientDelegationList: Array<PatientPractitionerRelation>;

  private subscriptions: Subscription[] = [];
  public allCheckboxChecked: boolean = false;
  public allCheckboxIndeterminate: boolean = false;

  public checkboxListDelegation: Array<any> = new Array<any>();

  public selectedPractitionnersList: Array<any> = new Array<any>();
  public deletedPractitionnerList: Array<any> = new Array<any>();
  public searchText;
  private errorLogged = false;

  constructor(
    private practitionerControllerService: PractitionerControllerService,
    private snackBarService: SnackBarService,
    private eventTrackingService: EventTrackingService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.eventTrackingService.init();
    this.selectPractitionner.emit({ list: [], type: this.typeDelegation });
    this.deletePractitionner.emit({ list: [], type: this.typeDelegation });

    this.subscriptions.push(
      this.practitionerControllerService.practitionerControllerMe().subscribe(
        (me) => {
          this.practitionnersList.forEach((practitionner) => {
            if (me.id !== practitionner.id) {
              this.checkboxListDelegation.push({
                id: practitionner.id,
                name: practitionner.name,
                checked: false,
                labelPosition: 'after',
                isValidated: false,
                indeterminate: false,
                isAldreadyIndeterminate: false,
              });
            }
          });
          this.getPatientsDelegation();
        },
        (error) => {
          console.error('PatientDelegateItemComponent : Err-Practitionner-01 ', error);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          if (!this.errorLogged) {
            this.eventTrackingService.logError('Err-Practitionner-01', error);
            this.errorLogged = true;
          }
        },
      ),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.practitionnersList = null;
    this.selectedPractitionnersList = null;
    this.checkboxListDelegation = null;
  }

  public attributeCheckValue(checkbox: any, typeDelegation: string): void {
    // Try to find if patient have practitionner
    const found = this.patientDelegationList.filter((patient) => {
      return patient.practitionerId === checkbox.id && patient.type === typeDelegation;
    });

    // Unqiue patient
    if (this.patientIdList.length === 1) {
      if (found.length > 0) {
        checkbox.checked = true;
        this.selectedPractitionnersList.push(checkbox);
      }
      // Multiple patient
    } else if (this.patientIdList.length > 1) {
      // If we find practitionners && all patients have the same practitionners the checkbox is check
      if (found.length > 0 && found.length === this.patientIdList.length) {
        checkbox.checked = true;
        this.selectedPractitionnersList.push(checkbox);
        // Else if the checkbox will be indeterminate
      } else if (found.length > 0 && found.length < this.patientIdList.length) {
        checkbox.checked = true;
        checkbox.indeterminate = true;
        checkbox.isAldreadyIndeterminate = true;
      }
    }

    if (found.length === this.patientIdList.length) {
      this.allCheckboxChecked = true;
    }
  }

  public getPatientsDelegation(): void {
    this.checkboxListDelegation.forEach((checkbox) => {
      this.attributeCheckValue(checkbox, this.typeDelegation);
    });
  }

  public allCheckboxChange(): void {
    this.selectedPractitionnersList = new Array<any>();

    for (const value of Object.values(this.checkboxListDelegation)) {
      value.checked = this.allCheckboxChecked;
      if (value.checked) {
        this.addPractionners(value);
      } else {
        this.removePractionners(value);
      }
    }
  }

  private addPractionners(checkboxSelected): void {
    // On ajoute l'id du praticien dans la liste à ajouter
    this.selectedPractitionnersList.push(checkboxSelected);

    // On verifie que le praticien n'est plus dans la liste des suppressions
    this.deletedPractitionnerList.forEach((practitionner, index) => {
      if (checkboxSelected.id === practitionner.id) {
        this.deletedPractitionnerList.splice(index, 1);
      }
    });
    const objToAdd: { list: Array<any>; type: string } = {
      list: this.selectedPractitionnersList,
      type: this.typeDelegation,
    };
    const objToDelete: { list: Array<any>; type: string } = {
      list: this.deletedPractitionnerList,
      type: this.typeDelegation,
    };
    this.selectPractitionner.emit(objToAdd);
    this.deletePractitionner.emit(objToDelete);
  }

  private removePractionners(checkboxSelected): void {
    // On ajoute l'id du praticien dans la liste à supprimer
    this.deletedPractitionnerList.push(checkboxSelected);
    // On verifie que le praticien n'est plus dans la liste des ajouts
    this.selectedPractitionnersList.forEach((practitionner, index) => {
      if (checkboxSelected.id === practitionner.id) {
        this.selectedPractitionnersList.splice(index, 1);
      }
    });

    const objToAdd: { list: Array<any>; type: string } = {
      list: this.selectedPractitionnersList,
      type: this.typeDelegation,
    };
    const objToDelete: { list: Array<any>; type: string } = {
      list: this.deletedPractitionnerList,
      type: this.typeDelegation,
    };
    this.selectPractitionner.emit(objToAdd);
    this.deletePractitionner.emit(objToDelete);
  }

  public changeState(checkboxSelected): void {
    // Changer les états des checkbox
    if (checkboxSelected.isAldreadyIndeterminate && checkboxSelected.indeterminate) {
      // Si la checkbox est grisé alors on enlève le check
      checkboxSelected.checked = false;
      checkboxSelected.indeterminate = false;
      this.removePractionners(checkboxSelected);
    } else if (checkboxSelected.checked && checkboxSelected.isAldreadyIndeterminate) {
      // Si la checkbox est check et qu'il y a plusieurs patients selectionnés
      // alors, on grise la checkbox
      checkboxSelected.checked = true;
      checkboxSelected.indeterminate = true;

      // Supprimer le praticien de la liste lorsque l'on grise la coche
      this.selectedPractitionnersList.forEach((practitionner, index) => {
        if (checkboxSelected.id === practitionner.id) {
          this.selectedPractitionnersList.splice(index, 1);
        }
      });
    } else if (!checkboxSelected.checked && checkboxSelected.isAldreadyIndeterminate) {
      // Si la checkbox n'est pas check et qu'il y a plusieurs patients selectionnés
      // alors on met le check
      checkboxSelected.checked = true;
      this.addPractionners(checkboxSelected);
    } else if (!checkboxSelected.checked && !checkboxSelected.isAldreadyIndeterminate) {
      // Si la checkbox n'est pas check et qu'il n'y a qu'une seul patients selectionné
      // alors on met le check
      checkboxSelected.checked = true;
      this.addPractionners(checkboxSelected);
    } else if (checkboxSelected.checked && !checkboxSelected.isAldreadyIndeterminate) {
      // Si la checkbox est check et qu'il n'y a qu'une seul patients selectionné
      // alors on enleve le check
      checkboxSelected.checked = false;
      this.removePractionners(checkboxSelected);
    }
  }

  public removeChips(practititoner): void {
    const checkboxSelected = this.checkboxListDelegation.find((checkbox) => checkbox.id === practititoner.id);

    if (checkboxSelected) {
      checkboxSelected.checked = false;
      this.removePractionners(checkboxSelected);
    }
  }
}
