import {
  Course,
  PatientControllerService,
  PatientDetail,
  PractitionerControllerService,
  QuestionnaireControllerService,
  QuestionnaireDetailWithRelations,
  QuestionnaireReply,
  QuestionnaireReplyControllerService,
} from '@abilycare/dal-client';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { Location } from '@angular/common';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'rapport-questionnary',
  templateUrl: './rapportQuestionnary.component.html',
  styleUrls: ['./rapportQuestionnary.component.scss'],
})
export class RapportQuestionnaryComponent implements OnInit, OnDestroy {
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();
  @Input() public questionnaryDetails: QuestionnaireDetailWithRelations;

  @ViewChild('btnBack', { static: false }) btnBack: ElementRef;
  @ViewChild('btnNext', { static: false }) btnNext: ElementRef;
  @ViewChild('btnOpenQuit', { static: true }) btnOpenQuit: ElementRef;

  public scores: Array<number> = new Array<number>();
  public patient: PatientDetail;
  public questionnaryReply: QuestionnaireReply;

  private subscriptions: Subscription[] = [];

  public scoreDefinition: Array<{ name: any; score: any }> = new Array();

  public testData: any;
  public testName: string;

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');

  public response: Array<any>;

  public newPage: string = '';
  public fromBilan: boolean;
  public fromCourse: boolean;
  public course: Course;
  public currentPageCourse: string;
  public dateConsultation: Date;
  public currentEstablishmentLogo: string;
  public currentEstablishmentName: string;
  public titleQuest: string;

  constructor(
    private questionnaryReplyService: QuestionnaireReplyControllerService,
    private dataService: DataProviderService,
    private location: Location,
    private questionnaryService: QuestionnaireControllerService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private patientService: PatientControllerService,
    private router: Router,
    private practitionnerService: PractitionerControllerService,
    private snackBarService: SnackBarService,
  ) {}

  ngOnInit() {
    this.patient = this.dataService.retrievePatient();
    this.route.queryParams.subscribe((params) => {
      this.fromCourse = params.fromCourse == 1;
    });
    this.route.queryParams.subscribe((params) => {
      this.fromBilan = params.fromBilan == 1;
    });
    const objState: any = this.location.getState();
    let obj: any;
    if (this.fromCourse) {
      this.course = objState[0];
      obj = objState[1];
      if (obj.questionnaireId == 12) this.currentPageCourse = 'questionnary12';
      else if (obj.questionnaireId == 11) this.currentPageCourse = 'questionnary11';
      this.returnTitle = i18nKey('Checkup.navbar.patient.back');
    } else {
      obj = objState;
    }
    this.testData = obj;
    this.dateConsultation = obj.date;
    this.patientService.patientControllerFindById(obj.patientId).subscribe((p) => {
      this.patient = p;
    });
    this.subscriptions.push(
      this.practitionnerService.practitionerControllerMe().subscribe(
        (practitionnerMe) => {
          if (practitionnerMe && practitionnerMe.establishment && practitionnerMe.establishment.logo) {
            this.currentEstablishmentLogo = practitionnerMe.establishment.logo;
            this.currentEstablishmentName = practitionnerMe.establishment.name;
          }
        },
        (err) => {
          console.error('RapportPraticienComponent : Err-Practitionner-01 ', err);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          console.log('Error getPractitionerMe()', err);
        },
      ),
    );
    //getTitle
    this.questionnaryService
      .questionnaireControllerFindById(this.testData.questionnaireId, this.translateService.currentLang)
      .subscribe((quest) => {
        this.testName = quest.name;
        this.titleQuest = this.translateService.instant('Rapport.titre.Questionnary') + ' : ' + quest.name;
      });
    //get Answers
    this.response = obj.answers;
    this.getQuestionnary(obj.questionnaireId);
    this.scores = obj.scores;
  }

  public getQuestionnary(idQuestionnary: number): void {
    const lang = this.translateService.currentLang;
    this.subscriptions.push(
      this.questionnaryService.questionnaireControllerFindById(idQuestionnary, lang).subscribe(
        (questionnaryDetails) => {
          this.questionnaryDetails = questionnaryDetails;
        },
        (error) => {
          console.log('Error questionnaireControllerFindById', error);
        },
      ),
    );
  }

  // get values for the current answsers
  public getAnsewrsValues(questIndex: number) {
    const questionType = this.questionnaryDetails.questions[questIndex].type;
    const key = Object.keys(this.response[questIndex])[0];
    if (key === 'numberValue') {
      return this.response[questIndex].numberValue;
    } else if (key === 'dateValue') {
      return this.response[questIndex].dateValue;
    } else if (key === 'textValue') {
      return this.response[questIndex].textValue;
    } else if (key === 'choiceValues' && questionType === 'singlechoice') {
      const ansewerIndex = this.response[questIndex].choiceValues[0];
      if (this.questionnaryDetails.questions[questIndex].answers[ansewerIndex])
        return this.questionnaryDetails.questions[questIndex].answers[ansewerIndex].text;
      else return '';
    } else if (key === 'choiceValues' && questionType === 'multiplechoice') {
      const responseArray = new Array();
      let answersListIndex = new Array();
      const ansewers = this.questionnaryDetails.questions[questIndex].answers;
      if (ansewers.length > 0) {
        ansewers.forEach((answer, indexAnswer) => {
          this.response[questIndex].choiceValues.forEach((choice) => {
            if (choice === indexAnswer && !answersListIndex.includes(indexAnswer)) {
              responseArray.push(answer.text + ' ');
              answersListIndex.push(indexAnswer);
            }
          });
        });
        return responseArray;
      } else {
        return '';
      }
    }
  }

  //see if we can display question dependant on other
  public displyQuestion(questIndex) {
    let question = this.questionnaryDetails.questions[questIndex];
    //console.log("nextQuestion is ", nextQuestion);
    const indexParent = question.questionParent;
    const criteriaAnswer = question.criteriaAnswer;
    if (indexParent) {
      //get the anwsers for the questionParent
      const indexAnswer = question.answerParent;
      //console.log("currentStepIndex is ", index);
      if (indexAnswer && !this.answerMatchCriteria(indexParent, criteriaAnswer, indexAnswer)) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  public getAnswerValues(index) {
    let AnswerValues = [];
    const questionType = this.questionnaryDetails.questions[index].type;
    if (this.response[index]) {
      if (questionType === 'integer' || questionType === 'float') {
        AnswerValues.push(this.response[index].numberValue);
      } else if (questionType === 'date') {
        AnswerValues.push(this.response[index].dateValue);
      } else if (questionType === 'text') {
        AnswerValues.push(this.response[index].textValue);
      } else if (questionType === 'singlechoice') {
        AnswerValues = this.response[index].choiceValues;
      } else if (questionType === 'multiplechoice') {
        AnswerValues = this.response[index].choiceValues;
      }
    }
    return AnswerValues;
  }

  public answerMatchCriteria(index, criteriaAnswer, indexAnswer) {
    if (criteriaAnswer && criteriaAnswer == 'answerValues') {
      return this.getAnswerValues(index) && this.getAnswerValues(index).some((r) => indexAnswer.includes(r));
    } else if (criteriaAnswer && criteriaAnswer == 'answerNumber') {
      return this.getAnswerValues(index) && this.getAnswerValues(index).length >= indexAnswer[0];
    } else {
      return true;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
    this.scoreDefinition = null;
  }

  public onSelectPage(newPage: string) {
    this.newPage = newPage;
  }

  public returnToHistory(): void {
    this.router.navigateByUrl('/PatientHistoryCourses/' + this.patient.id);
  }

  public printQuestReport(): void {
    window.print();
  }
}
