import { DoubleTask, DoubleTaskControllerService, Footwear } from '@abilycare/dal-client';
import { FootwearControllerService } from '@abilycare/dal-client';
import { WalkingAid } from '@abilycare/dal-client';
import { WalkingAidControllerService } from '@abilycare/dal-client';
import { PatientDetail } from '@abilycare/dal-client';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { FootWearDataService } from 'src/app/modules/abilycare-data-access';
import { ShoesSelectorValues } from 'src/app/modules/force-platform';
import { AnalysisResults } from 'src/app/modules/walk/models/analysis-results.model';

@Component({
  selector: 'app-fried-custom-navbar',
  templateUrl: './fried-custom-navbar.component.html',
  styleUrls: ['./fried-custom-navbar.component.scss'],
  providers: [FootWearDataService],
})
export class FriedCustomNavbarComponent implements OnInit {
  @Input() patient: PatientDetail;
  @Input() returnTitle: string;
  @Output() selectPage: EventEmitter<string> = new EventEmitter<string>();

  @Input() testName: string;

  @Input()
  public shoes: ShoesSelectorValues;

  @Input()
  public analysisResult: AnalysisResults;

  @Input()
  public testData: any;

  @Input()
  public walkToCompare: any;

  @Input()
  public resultsWalkToCompare: any;

  @Input()
  public displayDoubleTask: boolean;

  shoesType: string;
  shoesTypeToCompare: string;
  stabilityAid: string = '';
  stabilityAidtoCompare: string = '';
  doubleTask: string;
  doubleTaskToCompare: string;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private footWearService: FootwearControllerService,
    private stabilityAidService: WalkingAidControllerService,
    private doubleTaskService: DoubleTaskControllerService,
  ) {}

  async ngOnInit() {
    if (this.testData.doubleTask) {
      const res = await this.doubleTaskService
        .doubleTaskControllerFindById(this.testData.doubleTask, this.translateService.currentLang)
        .toPromise();
      this.doubleTask = res.label;
    }

    if (this.testData.stabilityAid) {
      const res = await this.stabilityAidService
        .walkingAidControllerFindByIds(this.testData.stabilityAid, this.translateService.currentLang)
        .toPromise();
      const walkingAids = res.map((walkingAid) => walkingAid.translate);
      this.stabilityAid = walkingAids.join(' - ');
    }

    if (this.testData.shoesType) {
      const res = await this.footWearService
        .footwearControllerFindById(this.testData.shoesType, this.translateService.currentLang)
        .toPromise();
      this.shoesType = res.translate;
    }

    if (this.walkToCompare) {
      if (this.walkToCompare.doubleTask) {
        const doubleTaskRes = await this.doubleTaskService
          .doubleTaskControllerFindById(this.walkToCompare.doubleTask, this.translateService.currentLang)
          .toPromise();
        this.doubleTaskToCompare = doubleTaskRes.label;
      }
      const stabilityAidRes = await this.stabilityAidService
        .walkingAidControllerFindByIds(this.walkToCompare.stabilityAid, this.translateService.currentLang)
        .toPromise();
      const walkingAids = stabilityAidRes.map((walkingAid) => walkingAid.translate);
      this.stabilityAidtoCompare = walkingAids.join(' - ');

      const shoesTypeRes = await this.footWearService
        .footwearControllerFindById(this.walkToCompare.shoesType, this.translateService.currentLang)
        .toPromise();
      this.shoesTypeToCompare = shoesTypeRes.translate;
    }
  }
}
