import {
  DoubleTaskControllerService,
  Footwear,
  FootwearControllerService,
  PatientDetail,
  PractitionerControllerService,
  RombergControllerService,
  RombergWithRelations,
  WalkingAidControllerService,
  WalkingControllerService,
  WalkingWithRelations,
} from '@abilycare/dal-client';
import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DataProviderService } from 'src/app/services/data-provider.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Compute } from 'src/app/tools/compute';
import { ShoesSelectorValues } from '../../force-platform';
import { AnalysisResults as AnalysisResultsRomberg } from '../../romberg/models/analysis-results.model';
import { AssessmentRomberg } from '../../romberg/models/assessment.model';
import { LongitudinalGraph as LongitudinalGraphRomberg } from '../../romberg/models/longitudinal.model';
import { AnalysisResultsDetailByAge } from '../../walk/models/analysis-results-detailByAge.model';
import { AnalysisResults as AnalysisResultsWalk } from '../../walk/models/analysis-results.model';
import { Assessment } from '../../walk/models/assessment.model';
import { LongitudinalGraph as LongitudinalGraphWalk } from '../../walk/models/longitudinal.model';
import { marker as i18nKey } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-rapport-praticien',
  templateUrl: './rapportPraticien.component.html',
  // providers: [AnalysisResultsRomberg, RemoteInteractionWalk, RemoteInteractionRomberg],
  styleUrls: ['./rapportPraticien.component.scss'],
})
export class RapportPraticienComponent implements OnInit, OnDestroy {
  public radarChartLabels = [
    this.translateService.instant('Rapport.AnalyseDetailMarche.regularite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.fluidite'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.vigueur'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Rythme'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.Synchronisation'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.symetrie'),
    this.translateService.instant('Rapport.AnalyseDetailMarche.stabilite'),
  ];
  public colorsOverride: any[] = [
    {
      backgroundColor: 'transparent',
      borderColor: 'black',
      pointBackgroundColor: 'black',
      pointBorderColor: 'black',
      pointHoverBackgroundColor: 'black',
    },
    {
      backgroundColor: 'transparent',
      borderColor: '#FD3F92',
      pointBackgroundColor: '#FD3F92',
      pointBorderColor: '#FD3F92',
      pointHoverBackgroundColor: '#FD3F92',
    },
  ];
  public radarChartData: any;
  public optionsRadarChart = {
    responsive: true,
    legend: {
      display: false,
      labels: {
        display: false,
      },
    },
    animation: { animateScale: true, animateRotate: true },
    scale: {
      ticks: {
        min: -4,
        max: 4,
      },
    },
  };

  // -------------------Locomotion & Romberg variables------------------------------------------
  public typeRapport: string;

  public dateConsultation: string;
  public patient: PatientDetail;
  public shoesType: string = '';
  public helpStability: string = '';
  public walkingAid: string = '';
  public shoesStability: boolean;
  public walkingHelpNeed: boolean;
  public shoesSelectorValues: ShoesSelectorValues;
  public agePatient: number;
  public walkAnalysisResult: AnalysisResultsWalk;
  public rombergAnalysisResult: AnalysisResultsRomberg;
  public imageSemio: string;
  public typePlate: string;
  public doubleTask: string;
  public openedEyesLeftSupport: number = 0;
  public closedEyesLeftSupport: number = 0;

  public fromHistory: boolean = false;

  // Longitudinal Bilan to graphe
  public graphs: LongitudinalGraphWalk[] = [];
  public graphsRomb: LongitudinalGraphRomberg[];
  public graphsRombYF: LongitudinalGraphRomberg[];
  // Assessment
  public assessments: Assessment[];
  public assessmentsRomberg: AssessmentRomberg[];
  public currentEstablishmentLogo: string;

  public titleWalk: string = null;
  public titleRomberg: string = null;
  private subscriptions: Subscription[] = [];

  public returnTitle: string = i18nKey('Checkup.navbar.history.back');

  constructor(
    private location: Location,
    private walkingService: WalkingControllerService,
    private rombergService: RombergControllerService,
    private walkingAidService: WalkingAidControllerService,
    private footWearService: FootwearControllerService,
    private practitionnerService: PractitionerControllerService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private dataService: DataProviderService,
    private router: Router,
  ) {}

  public ngOnDestroy() {
    // notify to unsubscribe observable register with takeUntil(this.unsubscribe)
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.subscriptions = null;
  }

  public async ngOnInit(): Promise<void> {
    const obj: any = this.location.getState();

    this.walkAnalysisResult = obj.walkAnalysisResult;
    this.rombergAnalysisResult = obj.rombergAnalysisResult;
    this.shoesSelectorValues = obj.shoesSelectorValues;
    this.typeRapport = obj.typeRapport;
    this.assessments = obj.walkAssessments;
    this.assessmentsRomberg = obj.rombergAssessments;
    this.dateConsultation = obj.dateConsultation;
    this.typePlate = obj.typePlate;
    this.doubleTask = obj.doubleTask;
    this.openedEyesLeftSupport = obj.openedEyesLeftSupport;
    this.closedEyesLeftSupport = obj.closedEyesLeftSupport;

    console.log('assessments is ', this.assessments);

    if (obj.fromHistory) this.fromHistory = obj.fromHistory;

    this.patient = this.dataService.retrievePatient();

    if (!this.walkAnalysisResult && !this.rombergAnalysisResult) {
      this.router.navigateByUrl('/Patient/' + this.patient.id);
    }

    this.subscriptions.push(
      this.practitionnerService.practitionerControllerMe().subscribe(
        (practitionnerMe) => {
          if (practitionnerMe && practitionnerMe.establishment && practitionnerMe.establishment.logo) {
            this.currentEstablishmentLogo = practitionnerMe.establishment.logo;
          }
        },
        (err) => {
          console.error('RapportPraticienComponent : Err-Practitionner-01 ', err);
          const errMsg = this.translateService.instant('Err-Practitionner-01');
          this.snackBarService.show(errMsg, 'danger');
          console.log('Error getPractitionerMe()', err);
        },
      ),
    );

    this.agePatient = Compute.age(new Date(this.patient.birthDate));

    console.log('walkAnalysisResult ', this.walkAnalysisResult);

    this.titleWalk = this.translateService.instant('Rapport.titre.Marche');
    this.titleRomberg = this.translateService.instant('Rapport.titre.Romberg');

    if (this.typeRapport === 'Walk') {
      this.displayBilanWalk();
    }

    if (this.typeRapport === 'Romberg') {
      this.displayBilanRomberg();
    }
  }

  public printPractitionnerReport(): void {
    window.print();
  }

  public returnChoiceReport(): void {
    const obj = {
      walkAnalysisResult: this.walkAnalysisResult,
      rombergAnalysisResult: this.rombergAnalysisResult,
      shoesSelectorValues: this.shoesSelectorValues,
      typeRapport: this.typeRapport,
      walkAssessments: this.assessments,
      rombergAssessments: this.assessmentsRomberg,
      dateConsultation: this.dateConsultation,
      fromHistory: this.fromHistory,
      doubleTask: this.doubleTask,
      patientId: this.patient.id,
      typePlate: this.typePlate,
      openedEyesLeftSupport: this.openedEyesLeftSupport,
      closedEyesLeftSupport: this.closedEyesLeftSupport,
    };
    this.router.navigateByUrl('/rapport', { state: obj });
  }

  private async displayBilanRomberg(): Promise<void> {
    // ----------------Construction BilanToGraphRomberg------------------------------------------
    this.subscriptions.push(
      this.rombergService.rombergControllerFindAllRombergByPatient(this.patient.id).subscribe(
        (res: any) => {
          this.transformBilanToGraphRomberg(res);
        },
        (err) => {
          console.error('RapportPraticienComponent : Err-Romberg-08 ', err);
          const errMsg = this.translateService.instant('Err-Romberg-08');
          this.snackBarService.show(errMsg, 'danger');
          console.log('Error displayBilanRomberg()', err);
        },
      ),
    );

    this.footWearService
      .footwearControllerFindById(this.shoesSelectorValues.shoesType, this.translateService.currentLang)
      .subscribe((res) => {
        this.shoesType = res.translate;
      });
    this.walkingAidService
      .walkingAidControllerFindByIds(this.shoesSelectorValues.helpStability + '', this.translateService.currentLang)
      .subscribe((res) => {
        const walkingAids = res.map((walkingAid) => walkingAid.translate);
        this.helpStability = walkingAids.join(' - ');
      });
  }

  private displayBilanWalk(): void {
    const detailByAge = this.walkAnalysisResult.graphes.semioInfo.detailByAge;
    const semioDataForAge = this.detailByAgeSemio(detailByAge, this.agePatient);
    let imageName = 'semio' + semioDataForAge.ageMin + '_';
    imageName += semioDataForAge.ageMax > 99 ? '200' : semioDataForAge.ageMax;
    this.imageSemio = this.walkAnalysisResult.graphes[imageName];

    this.subscriptions.push(
      this.walkingService.walkingControllerFindAllWalkingByPatient(this.patient.id).subscribe(
        (res: any) => {
          this.transformBilanToGraph(res);
        },
        (err) => {
          console.error('RapportPraticienComponent : Err-Walking-01 ', err);
          const errMsg = this.translateService.instant('Err-Walking-01');
          this.snackBarService.show(errMsg, 'danger');
          console.log('Error displayBilanWalk()', err);
        },
      ),
    );

    this.radarChartLabels = this.radarChartLabels.map((label) => this.translateService.instant(label));

    this.radarChartData = [
      { data: [0, 0, 0, 0, 0, 0, 0], label: 'Moyenne' },
      {
        data: [
          semioDataForAge.regularity,
          semioDataForAge.fluidity,
          semioDataForAge.vigor,
          semioDataForAge.pace,
          semioDataForAge.synchronization,
          semioDataForAge.symmetry,
          semioDataForAge.stability,
        ],
        label: 'Patient',
      },
    ];

    this.footWearService
      .footwearControllerFindById(this.shoesSelectorValues.shoesType, this.translateService.currentLang)
      .subscribe((res) => {
        this.shoesType = res.translate;
      });
    this.walkingAidService
      .walkingAidControllerFindByIds(this.shoesSelectorValues.walkingAid + '', this.translateService.currentLang)
      .subscribe((res) => {
        const walkingAids = res.map((walkingAid) => walkingAid.translate);
        this.walkingAid = walkingAids.join(' - ');
      });
  }

  // Retrieve the data of the semio by age of the patient
  private detailByAgeSemio(detailByAge: AnalysisResultsDetailByAge[], agePatient: number): AnalysisResultsDetailByAge {
    for (const element of detailByAge) {
      const ageMax = element.ageMax;
      const ageMin = element.ageMin;

      if (ageMin === 0 && ageMax === 200) {
        // skip catch all values
        continue;
      }

      if (agePatient >= ageMin && agePatient <= ageMax) {
        console.log("je suis dans la tranche d'age ", element);
        return element;
      }
    }
    return new AnalysisResultsDetailByAge();
  }

  private transformBilanToGraph(bilans: WalkingWithRelations[]) {
    //console.log('transformBilanToGraph');
    this.graphs = new Array<LongitudinalGraphWalk>(6);
    this.graphs[0] = new LongitudinalGraphWalk(new Array<{ x: Date; y: number }>(), 'Checkup.walk.averageSpeed');
    this.graphs[1] = new LongitudinalGraphWalk(new Array<{ x: Date; y: number }>(), 'Checkup.walk.meanStepDuration');
    this.graphs[2] = new LongitudinalGraphWalk(
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.swingMeanStrideDuration',
    );
    this.graphs[3] = new LongitudinalGraphWalk(new Array<{ x: Date; y: number }>(), 'Checkup.walk.doubleStance');
    this.graphs[4] = new LongitudinalGraphWalk(new Array<{ x: Date; y: number }>(), 'Checkup.walk.cycleVariability');
    this.graphs[5] = new LongitudinalGraphWalk(new Array<{ x: Date; y: number }>(), 'Checkup.walk.roliTronc');
    for (const bilan of bilans) {
      this.graphs[0].graph.push({ x: bilan.date, y: bilan.averageSpeed });
      this.graphs[1].graph.push({ x: bilan.date, y: bilan.averageStepDuration });
      this.graphs[2].graph.push({ x: bilan.date, y: bilan.walkingSymmetry });
      this.graphs[3].graph.push({ x: bilan.date, y: bilan.doublePressTime * 100 });
      this.graphs[4].graph.push({ x: bilan.date, y: bilan.cycleLengthVariability });
      this.graphs[5].graph.push({ x: bilan.date, y: bilan.pelvisBearing });
    }
  }

  private transformBilanToGraphRomberg(bilans: RombergWithRelations[]) {
    this.graphsRomb = new Array<LongitudinalGraphRomberg>(5);
    this.graphsRombYF = new Array<LongitudinalGraphRomberg>(5);
    this.graphsRomb[0] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.romberg.varianceLaterale',
    );
    this.graphsRomb[1] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Surface',
    );
    this.graphsRomb[2] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SwayDensity',
    );
    this.graphsRomb[3] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SpeedMoy',
    );
    this.graphsRomb[4] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Score',
    );
    this.graphsRombYF[0] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.romberg.varianceLaterale',
    );
    this.graphsRombYF[1] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Surface',
    );
    this.graphsRombYF[2] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SwayDensity',
    );
    this.graphsRombYF[3] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.SpeedMoy',
    );
    this.graphsRombYF[4] = new LongitudinalGraphRomberg(
      new Array<{ x: Date; y: number }>(),
      new Array<{ x: Date; y: number }>(),
      'Checkup.walk.Score',
    );
    for (const bilan of bilans) {
      //console.log('graphs romberg data ', bilan);
      this.graphsRomb[0].graphYO.push({ x: bilan.date, y: bilan.openedEyesLateralVariance });
      this.graphsRomb[0].graphYF.push({ x: bilan.date, y: bilan.closedEyesLateralVariance });
      this.graphsRomb[1].graphYF.push({ x: bilan.date, y: bilan.closedEyesArea });
      this.graphsRomb[1].graphYO.push({ x: bilan.date, y: bilan.openedEyesArea });
      this.graphsRomb[2].graphYF.push({ x: bilan.date, y: bilan.closedEyesSwayDensity });
      this.graphsRomb[2].graphYO.push({ x: bilan.date, y: bilan.openedEyesSwayDensity });
      this.graphsRomb[3].graphYF.push({ x: bilan.date, y: bilan.closedEyesAverageSpeed });
      this.graphsRomb[3].graphYO.push({ x: bilan.date, y: bilan.openedEyesAverageSpeed });
      this.graphsRomb[4].graphYF.push({ x: bilan.date, y: bilan.closedEyesScore });
      this.graphsRomb[4].graphYO.push({ x: bilan.date, y: bilan.openedEyesScore });

      //set YF
      this.graphsRombYF[0].graphYO.push({ x: bilan.date, y: bilan.openedEyesLateralVariance });
      this.graphsRombYF[0].graphYF.push({ x: bilan.date, y: bilan.closedEyesLateralVariance });
      this.graphsRombYF[0].graph = this.graphsRombYF[0].graphYF;
      this.graphsRombYF[1].graphYF.push({ x: bilan.date, y: bilan.closedEyesArea });
      this.graphsRombYF[1].graphYO.push({ x: bilan.date, y: bilan.openedEyesArea });
      this.graphsRombYF[1].graph = this.graphsRombYF[1].graphYF;
      this.graphsRombYF[2].graphYF.push({ x: bilan.date, y: bilan.closedEyesSwayDensity });
      this.graphsRombYF[2].graphYO.push({ x: bilan.date, y: bilan.openedEyesSwayDensity });
      this.graphsRombYF[2].graph = this.graphsRombYF[2].graphYF;
      this.graphsRombYF[3].graphYF.push({ x: bilan.date, y: bilan.closedEyesAverageSpeed });
      this.graphsRombYF[3].graphYO.push({ x: bilan.date, y: bilan.openedEyesAverageSpeed });
      this.graphsRombYF[3].graph = this.graphsRombYF[3].graphYF;
      this.graphsRombYF[4].graphYF.push({ x: bilan.date, y: bilan.closedEyesScore });
      this.graphsRombYF[4].graphYO.push({ x: bilan.date, y: bilan.openedEyesScore });
      this.graphsRombYF[4].graph = this.graphsRombYF[4].graphYF;
    }
  }
}
