<!-- questionnary PATIENT REPORT -->
<div *ngIf="patient">
  <div class="container-fluid">
    <div id="print-section-Walk">
      <app-rapport-header
        [dateConsultation]="dateConsultation"
        [patient]="patient"
        [establishmentLogo]="currentEstablishmentLogo"
        [establishmentName]="currentEstablishmentName"
        [title]="titleQuest"
      ></app-rapport-header>
      <hr />
      <div id="print-section-inner">
        <div>
          <div class="container-fluid">
            <div id="print-section-quest">
              <div class="section-2">
                <div class="row type-h">
                  <div class="col-md-4 col-xs-12 d-flex">
                    <div class="col-md-10 col-xs-12">
                      <div class="info-1">
                        <p>
                          <ng-container *ngIf="scores && scores.length > 1; else noScores">
                            <ng-container *ngFor="let score of scores; let scoreIndex = index">
                              {{ 'Score' | translate }} {{ scoreIndex + 1 }} : {{ score ? score : 'N/A' }}
                              {{ scores.length > scoreIndex + 1 ? '-' : '' }}
                            </ng-container>
                          </ng-container>
                          <ng-template #noScores>
                            <span class="no-scores">
                              {{ 'Score' | translate }} :
                              {{ scores.length == 1 ? scores[0] : 'N/A' }}
                            </span>
                          </ng-template>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row type-h"></div>
                </div>
                <hr />
                <div class="row mini-section">
                  <div class="col-md-9 main-content">
                    <div class="row">
                      <div class="col-md-12 col-xs-12">
                        <ng-container *ngFor="let question of questionnaryDetails.questions; let questionIndex = index">
                          <div>&nbsp;</div>
                          <div class="question-container titre-1" *ngIf="displyQuestion(questionIndex)">
                            <h5
                              class="question-family"
                              *ngIf="
                                questionnaryDetails.questionFamilies &&
                                questionnaryDetails.questionFamilies.length > 0 &&
                                (questionIndex == 0 ||
                                  questionnaryDetails.questionFamilies[question.familyIndex] !=
                                    questionnaryDetails.questionFamilies[
                                      questionnaryDetails.questions[questionIndex - 1].familyIndex
                                    ])
                              "
                            >
                              {{ questionnaryDetails.questionFamilies[question.familyIndex] }}
                            </h5>
                            <div class="question">{{ question.text }}</div>
                          </div>
                          <div
                            class="answers-container flex-column justify-content-between titre-2"
                            *ngIf="displyQuestion(questionIndex)"
                          >
                            <div>
                              <div class="answer">
                                {{ getAnsewrsValues(questionIndex) }}
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div>&nbsp;</div>
                  <div id="disclaimer" class="row mini-section disclaimer">
                    <div class="col-md-12 col-xs-12 warning">{{ 'Rapport.disclaimer' | translate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="cleareanceformenu"></div>
</div>
<div class="col-md-12 footer-content-rapport">
  <div class="col-md-12 footer-content-component">
    <button class="btn btn-primary" (click)="printQuestReport()">
      {{ 'print' | translate }}
    </button>
    &nbsp;
    <button class="btn btn-primary" (click)="returnToHistory()">
      {{ 'Checkup.navbar.history.back' | translate }}
    </button>
  </div>
</div>
