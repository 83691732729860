<section class="ftco-section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-3 text-center mb-3">
        <label>{{ 'Patient.DebutPeriod' | translate }}</label>
        <input
          class="form-control py-2 border-left-0 border bg-grey"
          type="date"
          value=""
          id="input-search"
          [(ngModel)]="startDate"
          (ngModelChange)="onStartDateChanged($event)"
        />
      </div>
      <div class="col-md-3 text-center mb-3">
        <label>{{ 'Patient.finPeriod' | translate }}</label>
        <input
          class="form-control py-2 border-left-0 border bg-grey"
          type="date"
          value=""
          id="input-search"
          [(ngModel)]="endDate"
          (ngModelChange)="onEndDateChanged($event)"
        />
      </div>
      <div class="col-md-3 text-center mb-3">
        <label>&nbsp;</label>
        <input
          matInput
          (keyup)="applyFilter($event.target.value)"
          style="border:none"
          placeholder="{{ 'Recherche' | translate }}"
          [(ngModel)]="filterValue"
        />
      </div>
      <div class="col-md-6 text-center mb-5">
        <button
          *ngIf="!hasFilterApplicated"
          class="btn-tertiary text-uppercase"
          type="button"
          data-toggle="modal"
          data-target="#modalTestFilter"
          data-backdrop="static"
          data-keyboard="false"
        >
          <img src="assets/images/icons/filter.svg" width="22" height="33" />
          {{ 'Filtrer' | translate }}
        </button>
        <button
          *ngIf="hasFilterApplicated"
          class="btn-tertiary text-uppercase"
          type="button"
          data-toggle="modal"
          data-target="#modalTestFilter"
          data-backdrop="static"
          data-keyboard="false"
        >
          <img src="assets/images/icons/filter_applicated.svg" width="22" height="33" />
          {{ 'Filtrer' | translate }}
        </button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-12 text-center mb-5">
        <mat-paginator
          #paginator
          [length]="listEvents.length"
          [pageIndex]="0"
          [pageSize]="10"
          [pageSizeOptions]="[5, 10, 25, 50]"
          (page)="onChangePage($event)"
          class="btn-tertiary text-uppercase"
        >
        </mat-paginator>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="table-wrap">
          <table class="table">
            <thead class="thead-primary">
              <tr>
                <th>{{ 'eventType' | translate }}</th>
                <th>{{ 'Patient' | translate }}</th>
                <th>{{ 'eventDate' | translate }}</th>
                <th>{{ 'eventDetails' | translate }}</th>
                <!-- <th>{{ 'metadata' | translate }}</th>-->
              </tr>
            </thead>
            <tbody>
              <!--  | filterDateRange: startDate:endDate-->
              <ng-container
                *ngFor="
                  let eventObject of listEvents
                    | slice: paginator.pageIndex * paginator.pageSize:(paginator.pageIndex + 1) * paginator.pageSize
                "
              >
                <tr class="alert" role="alert">
                  <td>
                    {{ eventObject.eventType | translate }}
                  </td>
                  <td>
                    <p class="patient-info name" *ngIf="eventObject.patient">
                      {{ eventObject.patient.firstName }} {{ eventObject.patient.lastName }}
                    </p>
                  </td>
                  <td>{{ eventObject.eventDate | localdate: 'datetime' }}</td>
                  <td *ngIf="eventObject.eventType.includes('ERROR')">
                    {{ eventObject.details | translate }}
                  </td>
                  <td *ngIf="eventObject.eventType.includes('DATA_EXPORT')">
                    {{ eventObject.details | translate }}
                  </td>
                  <td *ngIf="eventObject.eventType.includes('DATA') && !eventObject.eventType.includes('EXPORT')">
                    {{ eventObject.eventType | translate }} : {{ eventObject.entityType | translate }} ({{
                      eventObject.entityId
                    }})
                  </td>
                </tr>
                <!--
                <tr>
                  <td></td>
                  <td colspan="2">
                      {{ eventObject.metadata }}                   
                  </td>
                </tr>-->
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Modal filter patient -->
<div
  class="modal fade"
  id="modalTestFilter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="modalTestFilterTitle"
  aria-hidden="true"
  #basicModalFilter
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modalTestFilterTitle">
          {{ 'Filter.resultats' | translate }}
        </h5>
        <button
          #btnCloseFilter
          type="button"
          class="close"
          (click)="closeModalFilter()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <mat-list>
            <mat-list-item *ngFor="let item of checkboxList">
              <mat-checkbox
                name="{{ item.id }}"
                [checked]="item.checked"
                [(ngModel)]="item.checked"
                [disabled]="item.disabled"
                [labelPosition]="item.labelPosition"
                (change)="listChange()"
                class="dropdown-item"
              >
                {{ item.name | translate }}
              </mat-checkbox>
            </mat-list-item>
          </mat-list>
          <div class="flex-row div-find-filter modal-footer">
            <mat-checkbox
              class="btn-tertiary "
              name="selectChange"
              [(ngModel)]="allCheckboxChecked"
              [(indeterminate)]="allCheckboxIndeterminate"
              (change)="allCheckboxChange()"
              mat-button
            >
              <ng-container *ngIf="!allCheckboxChecked">{{ 'select.all' | translate }}</ng-container>
              <ng-container *ngIf="allCheckboxChecked">{{ 'deselect.all' | translate }}</ng-container>
            </mat-checkbox>
            <button class="btn btn-primary col-sm-4" (click)="onSubmit()" type="submit">
              {{ 'button.validation' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
